// utilities
import { getPhoneData } from ".";

// constants
import { MfaSessionStorageKeys } from "../../common/constants";

// types
import { PhoneData } from "../../common/types";

/**
 * @description Saves MFA phone data into session storage.
 *
 * @param phoneData - Set of information needed for Voice or SMS MFA enrollment
 */

export const savePhoneData = (phoneData: Partial<PhoneData>) => {
  const storedData = getPhoneData();

  sessionStorage.setItem(
    MfaSessionStorageKeys.PHONE_DATA,
    JSON.stringify({ ...storedData, ...phoneData })
  );
};

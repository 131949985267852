export const UnverifiedSecurityIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 76 69" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M32 60V4M6.978 32h50.044" stroke="#FA6400" />
        <path
          d="M32 4c-8 6-20 10-28 10 0 18.666 9.334 38 28 46 18.666-8 28-27.334 28-46-8 0-20-4-28-10z"
          stroke="#404040"
        />
      </g>
      <g>
        <circle
          cx="59"
          cy="52"
          fill="#FFF"
          r="14.25"
          stroke="#FFF"
          strokeWidth="4.5"
        />
        <circle cx="59" cy="52" r="13" stroke="#666" strokeWidth="2" />
      </g>
      <path
        d="M54.875 52.19l2.647 2.773 6.016-6.3"
        stroke="#666"
        strokeLinecap="round"
        strokeWidth="1.575"
      />
    </g>
  </svg>
);

// libraries
import { useEffect } from "react";
import { useSelector } from "react-redux";

// utilities
import { getNumberOfFactorEnrollments } from "../../utilities";
import { useNavigation } from "..";

// constants
import {
  TwoStepVerificationCustomErrorCodes,
  mfaFactorEnrollmentLimitMap,
} from "../../common/constants";

// types
import { MfaFactorTypes } from "../../common/constants";
import { ReducerState as FactorsReducerState } from "../../redux/mfa";
import { State } from "../../redux";

/**
 * @description Displays dialog to prevent user from MFA factor enrollment.
 * @param factorType - MFA factor type being used.
 * @returns An object containing Dialog component with generic error message and a boolean value indicating if the enrolled factors number is exceeded.
 */
export const usePreventMfaFactorsEnrollment = (factorType: MfaFactorTypes) => {
  const { enrolledFactors } = useSelector<State, FactorsReducerState>(
    (state) => state.getFactors
  );
  const { paths, redirect } = useNavigation();

  const isEnrolledFactorsNumberExceeded =
    getNumberOfFactorEnrollments(enrolledFactors, factorType) >=
    mfaFactorEnrollmentLimitMap[factorType];

  useEffect(() => {
    if (enrolledFactors.length === 0) {
      return;
    }

    if (isEnrolledFactorsNumberExceeded) {
      redirect(paths.customError, {
        errorCode: TwoStepVerificationCustomErrorCodes.MF0012,
      });

      return;
    }
  }, [
    enrolledFactors,
    factorType,
    isEnrolledFactorsNumberExceeded,
    paths.customError,
    redirect,
  ]);

  return {
    isEnrolledFactorsNumberExceeded,
  };
};

// libraries
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

// utilities
import { failedToGetAccessToken } from "../analytics";
import { useNavigation } from ".";

// constants
import { GeneralConfigurationErrorCodes } from "../common/constants";

// types
import { Dispatch } from "../redux";

/**
 * @description Gets access token for the user from Auth0.
 * @returns `getAccessToken` function.
 * @handlesError Redirect to Generic Error page.
 */
export const useGetAccessToken = () => {
  const dispatch = useDispatch<Dispatch>();
  const { getAccessTokenSilently } = useAuth0();
  const { paths, redirect } = useNavigation();

  const getAccessToken = useCallback(async () => {
    let token = "";

    try {
      token = await getAccessTokenSilently();
    } catch {
      dispatch(failedToGetAccessToken());
      redirect(paths.customError, {
        errorCode: GeneralConfigurationErrorCodes.GC0002,
      });
    }

    return token;
  }, [dispatch, getAccessTokenSilently, paths.customError, redirect]);

  return { getAccessToken };
};

// libraries
import { forwardRef } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { ChevronDown_1_Icon as ChevronDownIcon } from "../../../common/icons";
import { ChevronUpIcon } from "../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { ButtonTypes, RoleTypes } from "../../../common/constants";
import { COUNTRY_SELECTOR_HEADER_DISPLAY_NAME } from "./constants";

// types
import { CountrySelectorHeaderProps } from "./types";

const getClassName = getClassNameFactory(COUNTRY_SELECTOR_HEADER_DISPLAY_NAME);

export const CountrySelectorHeader = forwardRef<
  HTMLButtonElement,
  CountrySelectorHeaderProps
>(({ icon, isExpanded, toggleExpand, expandButtonId }, ref): JSX.Element => {
  const translate = useTranslate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    toggleExpand();
  };

  return (
    <button
      aria-expanded={isExpanded}
      aria-haspopup={RoleTypes.LIST_BOX}
      aria-label={translate("CountrySelectorHeader.Label")}
      className={getClassName("button")}
      id={expandButtonId}
      onClick={handleClick}
      ref={ref}
      type={ButtonTypes.BUTTON}
    >
      {icon}
      <span
        className={getClassName("toggleIcon")}
        role={RoleTypes.PRESENTATION}
      >
        {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </span>
    </button>
  );
});

CountrySelectorHeader.displayName = COUNTRY_SELECTOR_HEADER_DISPLAY_NAME;

// libraries
import { useEffect } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { createMetaTags } from "./AboutPageUtilities";

export const useMetaTags = () => {
  const translate = useTranslate();

  const metaTags = createMetaTags(translate);

  metaTags.forEach((element) => document.head.append(element));

  useEffect(() => {
    return () =>
      metaTags.forEach((element) => document.head.removeChild(element));
  });
};

// libraries
import {
  WithAuthenticationRequiredOptions,
  useAuth0,
} from "@auth0/auth0-react";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@dcl/tools";

// components
import { IdleTimerContainer } from "../IdleTimerContainer";
import { Loading } from "../Loading";
import { ReCaptchaProvider } from "../../providers/ReСaptchaProvider";
import { UserProvider } from "../../providers/UserProvider";

// utilities
import {
  getStorageDataFromSearchParameters,
  isTranslateReady,
  saveToStorage,
} from "./utilities";
import { useDocumentTitle } from "./hooks";

// constants
import {
  MINUTES_BEFORE_WARNING,
  MINUTES_LEFT_BEFORE_LOGOUT,
} from "../IdleTimerContainer";

// types
import { WithRoute, WithStorage } from "./types";

export const withAuth0Authentication =
  ({ returnTo, loginOptions = {} }: WithAuthenticationRequiredOptions = {}) =>
  (Component: React.FC): React.FC =>
  () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
      if (isAuthenticated) {
        return;
      }

      const login = async () => {
        await loginWithRedirect({
          ...loginOptions,
          appState: {
            ...loginOptions.appState,
            returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
          },
        });
      };

      login();
    }, [isAuthenticated, loginWithRedirect]);

    return isAuthenticated ? (
      <UserProvider>
        <IdleTimerContainer
          timeBeforeWarning={MINUTES_BEFORE_WARNING}
          timeLeftBeforeLogOut={MINUTES_LEFT_BEFORE_LOGOUT}
        />
        <Component />
      </UserProvider>
    ) : (
      <Loading />
    );
  };

export const withHash =
  ({ storageMap, type }: WithStorage) =>
  (Component: React.FC): React.FC =>
  () => {
    const { hash } = useLocation();
    const [, hashString] = hash.split(`#`);

    const hashParameters = new URLSearchParams(hashString);

    const storageData = getStorageDataFromSearchParameters(
      storageMap,
      hashParameters
    );

    if (!isEmpty(storageData)) {
      saveToStorage(storageData, type);
    }

    return <Component />;
  };

export const withStorage =
  ({ storageMap, type }: WithStorage) =>
  (Component: React.FC): React.FC =>
  () => {
    const urlParameters = new URLSearchParams(window.location.search);
    const storageData = getStorageDataFromSearchParameters(
      storageMap,
      urlParameters
    );

    if (!isEmpty(storageData)) {
      saveToStorage(storageData, type);
    }

    return <Component />;
  };

export const withReCaptchaProvider =
  (Component: React.FC): React.FC =>
  () =>
    (
      <ReCaptchaProvider>
        <Component />
      </ReCaptchaProvider>
    );

export const withRoute =
  ({ documentTitleKey }: WithRoute) =>
  (Component: React.FC): React.FC =>
  () => {
    const translate = useTranslate();

    useDocumentTitle(documentTitleKey);

    return isTranslateReady(translate) ? <Component /> : <Loading />;
  };

// libraries
import { useCallback, useMemo, useState } from "react";

// components
import { RecoveryCodeDialog } from "./RecoveryCodeDialog";

// utilities
import RecoveryCodeDialogContext from "./RecoveryCodeDialogContext";

const RecoveryCodeDialogProvider: React.FC = ({ children }) => {
  const [actionOnClose, setActionOnClose] = useState<null | VoidFunction>(null);
  const [actionOnSubmit, setActionOnSubmit] = useState<null | VoidFunction>(
    null
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);

    if (actionOnClose) {
      actionOnClose();
      setActionOnClose(null);
    }
  };

  const handleSubmit = () => {
    setIsDialogOpen(false);

    if (actionOnSubmit) {
      actionOnSubmit();
      setActionOnSubmit(null);
    }
  };

  const addActionOnClose = useCallback((onClose: () => void) => {
    setActionOnClose(() => onClose);
  }, []);

  const addActionOnSubmit = useCallback((onClose: () => void) => {
    setActionOnSubmit(() => onClose);
  }, []);

  const open = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const controls = useMemo(
    () => ({ addActionOnClose, addActionOnSubmit, open }),
    [addActionOnClose, addActionOnSubmit, open]
  );

  return (
    <RecoveryCodeDialogContext.Provider value={controls}>
      {children}
      <RecoveryCodeDialog
        isOpen={isDialogOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </RecoveryCodeDialogContext.Provider>
  );
};

export const withRecoveryCodeDialogProvider =
  <T,>(Component: React.FC<T>) =>
  (props: T) =>
    (
      <RecoveryCodeDialogProvider>
        <Component {...props} />
      </RecoveryCodeDialogProvider>
    );

// libraries
import { useCallback, useEffect, useState } from "react";

// utilities
import {
  getRegistrationKeys,
  sendCreateRegistrationKeyRequest,
  sendDeleteRegistrationKeyRequest,
  sendUpdateRegistrationKeyRequest,
} from "../../utilities";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { useGetAccessToken, useUser } from "../";

// constants
import { LoadingStates } from "../../common/constants";

// types
import { RegistrationKey, UpdateRegKey } from "../../common/types";

/**
 * Custom React hook to get, create, update, and delete registration keys.
 *
 * @returns An object containing:
 * - `createRegistrationKey`: A function to create a new registration key.
 * - `deleteRegistrationKey`: A function to delete a registration key.
 * - `isRegistrationKeysListLoading`: The loading state of the registration keys list.
 * - `registrationKeysList`: The list of registration keys.
 */
export const useRegistrationKeys = () => {
  const { getAccessToken } = useGetAccessToken();
  const { euId } = useUser();
  const {
    ciam: { basePath: apiBasePath },
  } = useConfiguration();

  const [registrationKeysList, setRegistrationKeysList] = useState<
    RegistrationKey[] | []
  >([]);
  const [isLoading, setIsLoading] = useState(LoadingStates.IDLE);

  const fetchRegistrationKeys = useCallback(async () => {
    setIsLoading(LoadingStates.LOADING);
    try {
      const response = await getRegistrationKeys({
        accessToken: await getAccessToken(),
        apiBasePath,
        euId,
      });

      setRegistrationKeysList(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(LoadingStates.COMPLETED);
    }
  }, [apiBasePath, euId, getAccessToken]);

  useEffect(() => {
    fetchRegistrationKeys();
  }, [fetchRegistrationKeys]);

  const createRegistrationKey = async (newKey: RegistrationKey) => {
    const accessToken = await getAccessToken();

    try {
      await sendCreateRegistrationKeyRequest({
        accessToken,
        apiBasePath,
        euId,
        registrationKey: newKey,
      });
    } catch (error) {
      throw error;
    }
  };

  const deleteRegistrationKey = async (keyToDelete: RegistrationKey) => {
    const { productCode, registrationKey } = keyToDelete;

    const accessToken = await getAccessToken();

    try {
      await sendDeleteRegistrationKeyRequest({
        accessToken,
        apiBasePath,
        euId,
        productCode,
        registrationKey,
      });
    } catch (error) {
      throw error;
    }
  };

  const updateRegistrationKey = async (
    oldKey: RegistrationKey,
    newKey: UpdateRegKey
  ) => {
    const { productCode, registrationKey } = oldKey;

    const accessToken = await getAccessToken();

    try {
      await sendUpdateRegistrationKeyRequest({
        accessToken,
        apiBasePath,
        euId,
        newKey,
        productCode,
        registrationKey,
      });
    } catch (error) {
      throw error;
    }
  };

  return {
    createRegistrationKey,
    deleteRegistrationKey,
    isRegistrationKeysListLoading: isLoading,
    registrationKeysList,
    updateRegistrationKey,
  };
};

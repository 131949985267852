// libraries
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useEffect } from "react";

// utilities
import { useConfiguration } from "../ConfigurationProvider";

// constants
import { RECAPTCHA_BADGE_SELECTOR } from "./ReCaptchaConstants";

/**
 * @description Provides the Google ReCAPTCHA functionality.
 * Every functionality that requires reCAPTCHA should be wrapped in the provider.
 */

export const ReCaptchaProvider = ({ children }) => {
  const {
    reCaptcha: { siteKey },
  } = useConfiguration();

  useEffect(() => {
    return () => {
      const badges = document.querySelectorAll(RECAPTCHA_BADGE_SELECTOR);

      badges.forEach((badge) => {
        if (badge.parentNode) {
          document.body.removeChild(badge.parentNode);
        }
      });
    };
  });

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={siteKey}
      scriptProps={{
        appendTo: "body",
        defer: true,
      }}
      useEnterprise={true}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

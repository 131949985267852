export const SearchIcon: React.FC = () => (
  <svg
    focusable="false"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="currentColor" strokeLinecap="square" strokeWidth="2">
      <path d="M22 22l-5.6-5.6" />
      <path d="M10 1a9 9 0 1 0 0 18 9 9 0 1 0 0-18z" />
    </g>
  </svg>
);

// libraries
import { createContext, useCallback, useEffect, useState } from "react";

// utilities
import {
  getLocaleFromUrl,
  isSupportedLocale,
  replaceLocaleInUrl,
} from "../../utilities";
import { useNavigation } from "../../hooks";

// constants
import { DEFAULT_LOCALE } from "../../common/constants";

export const LocalizationContext = createContext({
  locale: "",
  updateLocale: (locale: string) => {},
});

/**
 * @description Provides the locale configuration for the application.
 * Every functionality that requires locale value should be wrapped in the provider.
 * Redirects user to default locale, if locale in url is not supported.
 * Provider updateLocale function, that can be used in children components to update locale and change the url.
 */

export const LocalizationProvider: React.FC = ({ children }) => {
  const { replace } = useNavigation();
  const [locale, setLocale] = useState("");

  const { country, language } = getLocaleFromUrl();

  const defaultLocale = DEFAULT_LOCALE.toLowerCase();
  const urlLocale = `${language}-${country}`;
  const urlLocaleInLowercase = urlLocale.toLowerCase();

  const updateLocale = useCallback(
    (newLocale: string) => {
      setLocale(newLocale);
      replaceLocaleInUrl(newLocale, replace);
    },
    [replace]
  );

  useEffect(() => {
    if (!isSupportedLocale(urlLocaleInLowercase)) {
      updateLocale(defaultLocale);

      return;
    }

    setLocale(urlLocaleInLowercase);
  }, [defaultLocale, replace, updateLocale, urlLocaleInLowercase]);

  return (
    <LocalizationContext.Provider value={{ locale, updateLocale }}>
      {children}
    </LocalizationContext.Provider>
  );
};

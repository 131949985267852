// constants
import {
  RegistrationKeysErrorAction,
  RegistrationKeysErrorActionTypes,
} from "../../common/constants";

// types
import { DialogConfiguration } from "./types";

export const registrationKeysErrorReducer = (
  configuration: DialogConfiguration,
  action: RegistrationKeysErrorAction
): DialogConfiguration => {
  switch (action.type) {
    case RegistrationKeysErrorActionTypes.OPEN_ERROR_DIALOG:
      return {
        ...configuration,
        isOpen: true,
      };
    case RegistrationKeysErrorActionTypes.OPEN_CUSTOM_ERROR_DIALOG:
      return {
        ...configuration,
        body: action.payload,
        isOpen: true,
      };
    case RegistrationKeysErrorActionTypes.CLOSE_ERROR_DIALOG:
      return {
        ...configuration,
        isOpen: false,
      };
  }
};

// constants
import { REGISTRATION_DATA_STORAGE_KEY } from "../../common/constants";

// types
import { RegistrationData } from "../../common/types";

/**
 * @description Gets and parses registration data from session storage.
 *
 * @returns Object with registration data from storage.
 */

export const getStoredRegistrationData = (): RegistrationData =>
  JSON.parse(sessionStorage.getItem(REGISTRATION_DATA_STORAGE_KEY) ?? "{}");

// libraries
import classNames from "classnames";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

// components
import { InlineErrorMessage } from "../../..";

// utilities
import { getClassNameFactory, getErrorId } from "../../../../utilities";

// constants
import {
  DEFAULT_ACTIVE_INDEX,
  DEFAULT_INPUT_VALUE_PATTERN,
  DISPLAY_NAME,
} from "./CodeInputConstants";
import {
  InputTypes,
  KeyboardKeys,
  RoleTypes,
} from "../../../../common/constants";

// types
import { CodeInputProps, CodeInputRef } from "./CodeInputTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

/* 1
 The input type "tel" is used instead of the input type "number" to enter a number because the input type "number" has 2 disadvantages:
     1. attribute maxLength doesn't work https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input#attr-maxlength
     2. allows enter the letter "e"
*/

export const CodeInput = forwardRef<CodeInputRef, CodeInputProps>(
  (
    {
      ariaLabelledBy,
      code,
      errorMessage,
      id,
      isEnterprise,
      label,
      onBlur,
      onChange,
      pattern = DEFAULT_INPUT_VALUE_PATTERN,
      type = InputTypes.TEL /* 1 */,
    },
    ref
  ) => {
    const [activeInputIndex, setActiveInputIndex] =
      useState(DEFAULT_ACTIVE_INDEX);
    const inputsRefs = useRef<Array<HTMLInputElement>>([]);

    useImperativeHandle(ref, () => ({
      focus: focusActiveInput,
    }));

    const focusActiveInput = () => {
      inputsRefs.current[activeInputIndex].focus();
    };

    const focusNextInput = () => {
      const nextInputIndex = activeInputIndex + 1;

      if (nextInputIndex > code.length - 1) {
        return;
      }

      inputsRefs.current[nextInputIndex].focus();
      inputsRefs.current[nextInputIndex].select();
    };

    const focusPreviousInput = () => {
      const previousInputIndex = activeInputIndex - 1;

      if (previousInputIndex < 0) {
        return;
      }

      inputsRefs.current[previousInputIndex].focus();
      inputsRefs.current[previousInputIndex].select();
    };

    const getClipboardData = ({
      clipboardData,
    }: React.ClipboardEvent<HTMLInputElement>) => {
      const invalidInputValuePattern = new RegExp(`[^${pattern.source}]`);

      const rawData = clipboardData.getData("Text");
      const croppedRawData = rawData.slice(0, code.length - activeInputIndex);
      const [croppedValidRawData] = croppedRawData.split(
        invalidInputValuePattern
      );

      return croppedValidRawData.split("");
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const focusedElement = event.relatedTarget;

      const hasInputFocus = inputsRefs.current.some(
        (input) => input === focusedElement
      );

      if (!hasInputFocus && onBlur) {
        onBlur();
      }
    };

    const handleChange = ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (value.match(pattern)) {
        const newCode = [...code];

        newCode[activeInputIndex] = value;
        onChange(newCode);

        focusNextInput();
      }
    };

    const handleFocus = (inputIndex: number) => {
      setActiveInputIndex(inputIndex);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case KeyboardKeys.BACKSPACE: {
          event.preventDefault();

          const isEmptyInput = code[activeInputIndex] === "";

          if (isEmptyInput) {
            onChange(code);

            focusPreviousInput();

            return;
          }

          const newCode = [...code];

          newCode[activeInputIndex] = "";

          onChange(newCode);

          return;
        }

        case KeyboardKeys.LEFT_ARROW: {
          event.preventDefault();

          focusPreviousInput();

          return;
        }

        case KeyboardKeys.RIGHT_ARROW: {
          event.preventDefault();

          focusNextInput();

          return;
        }
      }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();

      const clipboardData = getClipboardData(event);
      const newCode = [...code];

      let indexToInsertValue = activeInputIndex;

      for (let character of clipboardData) {
        newCode[indexToInsertValue] = character;
        indexToInsertValue += 1;
      }

      onChange(newCode);
    };

    return (
      <div
        aria-labelledby={ariaLabelledBy}
        className={getClassName()}
        role={RoleTypes.GROUP}
      >
        {label && <div className={getClassName("labelWrapper")}>{label}</div>}
        <div className={getClassName("inputsWrapper")}>
          {code.map((value, index) => (
            <input
              aria-describedby={getErrorId(id)}
              aria-label={`${index + 1} field out of ${code.length}`}
              className={getClassName({
                descendantName: "input",
                modifiers: classNames(errorMessage && "isInvalid"),
              })}
              key={index}
              maxLength={1}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={() => handleFocus(index)}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              ref={(node: HTMLInputElement) =>
                (inputsRefs.current[index] = node)
              }
              type={type}
              value={value}
            />
          ))}
          {errorMessage && (
            <InlineErrorMessage
              className={getClassName("error")}
              errorMessage={errorMessage}
              id={getErrorId(id)}
              isEnterprise={isEnterprise}
            />
          )}
        </div>
      </div>
    );
  }
);

CodeInput.displayName = DISPLAY_NAME;

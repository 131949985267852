// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// constants
import { Auth0Endpoints } from "../../../../api";
import { ConfirmationErrors } from "./confirmAuthGuardianEnrollmentConstants";
import { ErrorMessages, HttpMethods } from "../../../../common/constants";

// types
import { ThunkArguments, ThunkConfiguration } from "./confirmEnrollmentTypes";
import { handleConfirmAuthGuardianEnrollmentError } from "./confirmAuthGuardianEnrollmentUtils";

export const confirmAuthGuardianEnrollment = createAsyncThunk<
  void,
  ThunkArguments,
  ThunkConfiguration
>(
  "authGuardian/confirmEnrollment",
  async ({ abortSignal, apiBasePath, requestPayload }, { rejectWithValue }) => {
    try {
      const url = `${apiBasePath}/${Auth0Endpoints.VERIFY_FACTOR}`;
      const options = {
        body: new URLSearchParams(requestPayload),
        method: HttpMethods.POST,
        signal: abortSignal,
      };

      let response = await fetch(url, options);

      if (!response.ok) {
        const data = await response.json();

        handleConfirmAuthGuardianEnrollmentError(data);
        throw data.error;
      }

      return;
    } catch (error) {
      let errorMessage: ErrorMessages | ConfirmationErrors =
        ErrorMessages.VERIFY_FACTOR;

      if (error instanceof Error) {
        if (error.message === ConfirmationErrors.AUTHORIZATION_PENDING) {
          errorMessage = ErrorMessages.AUTHORIZATION_PENDING;
        }

        if (error.name === ConfirmationErrors.ABORT_REQUEST) {
          errorMessage = ErrorMessages.ABORT_REQUEST;
        }

        if (error.message === ErrorMessages.MALFORMED_MFA_TOKEN) {
          errorMessage = ErrorMessages.MALFORMED_MFA_TOKEN;
        } else if (error.message === ErrorMessages.MALFORMED_OBB_CODE) {
          errorMessage = ErrorMessages.MALFORMED_OBB_CODE;
        } else if (error.message === ConfirmationErrors.ACCESS_DENIED) {
          errorMessage = ConfirmationErrors.ACCESS_DENIED;
        }
      }

      return rejectWithValue({
        message: errorMessage,
      });
    }
  }
);

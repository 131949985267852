// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { deleteFactor } from "./deleteFactorThunk";

// types
import { ReducerState } from "./deleteFactorTypes";

const initialState: ReducerState = {
  error: null,
};

export const deleteFactorSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(deleteFactor.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
  initialState,
  name: "deleteMfaFactor",
  reducers: {},
});

// utilities
import {
  isCommonPasswordError,
  isPasswordContainsUserInformationError,
  isPasswordInHistoryError,
} from "./isError";

// constants
import { passwordRequirements } from "../common/constants";

// types
import { ThunkError } from "../common/types";

/**
 * @description This function is used to get the error message based on the type of error.
 * @param error - The error object that needs to be checked.
 * @returns Returns an object with key and the value as the translation key of the error message.
 * If the error is not from the list, it will return null.
 */
export const getPasswordErrorMessage = (error: ThunkError) => {
  if (isCommonPasswordError(error)) {
    return passwordRequirements.errors.commonPassword;
  }

  if (isPasswordInHistoryError(error)) {
    return passwordRequirements.errors.passwordInHistory;
  }

  if (isPasswordContainsUserInformationError(error)) {
    return passwordRequirements.errors.passwordContainsUserInformation;
  }

  /* When adding a new error message, please remember to also add the
   * corresponding error message in the passwordUpdateUtilities.ts file which is used by the thunk
   * to return the correct error message from the API response. */

  return null;
};

// libraries
import {
  SafButton,
  SafIcon,
  SafProgressRing,
} from "@saffron/core-components/react";
import { useReducer } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { changeStateWithDelay, reducer } from "./CopyCodeUtilities";
import { getClassNameFactory } from "../../../../utilities";
import { useNavigation } from "../../../../hooks";

// constants
import {
  CopyCodeButtonText,
  DISPLAY_NAME,
  initialState,
} from "./CopyCodeButtonConstants";
import { TwoStepVerificationCustomErrorCodes } from "../../../../common/constants";

// types
import { ActionTypes } from "./CopyCodeButtonTypes";
import { CopyCodeButtonProps } from "./CopyCodeButtonTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const CopyCodeButton: React.FC<CopyCodeButtonProps> = ({
  code,
  onCopy,
}) => {
  const translate = useTranslate();
  const { paths, redirect } = useNavigation();
  const [{ text, iconName, isLoading }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const copyCode = async () => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(code);
    } else {
      window["clipboardData"].setData("Text", code);
    }
  };

  const showLoading = () => dispatch({ type: ActionTypes.LOADING });

  const showDone = () =>
    changeStateWithDelay(() => dispatch({ type: ActionTypes.COPIED_CODE }));

  const resetState = () =>
    changeStateWithDelay(() => dispatch({ type: ActionTypes.COPY_CODE }));

  const handleCopy = async () => {
    if (text === CopyCodeButtonText.COPY_CODE) {
      showLoading();
      try {
        await copyCode();
        await showDone();
        await resetState();

        if (onCopy) {
          onCopy();
        }
      } catch (error) {
        redirect(paths.customError, {
          errorCode: TwoStepVerificationCustomErrorCodes.MF0008,
        });
      }
    }
  };

  return (
    <SafButton
      appearance="tertiary"
      className={getClassName()}
      onClick={handleCopy}
    >
      {isLoading ? (
        <SafProgressRing progressSize="small" />
      ) : (
        <>
          <SafIcon iconName={iconName} slot="start"></SafIcon>
          {translate(text)}
        </>
      )}
    </SafButton>
  );
};

CopyCodeButton.displayName = DISPLAY_NAME;

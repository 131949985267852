// constants
import { Auth0Errors } from "./AppConstants";
import {
  CustomErrorCodes,
  ErrorMessages,
  GeneralConfigurationErrorCodes,
  VerifySecurityQuestionCustomErrorCodes,
} from "../../common/constants";

/**
 * @description Checks if the error is auth0 service error.
 * @param error - Error object from Auth0 hook.
 * @returns True if it is a auth0 service error, false otherwise.
 */

const isAuth0ServiceError = (error: unknown) => {
  if (error instanceof Error) {
    return error.message.includes(ErrorMessages.AUTH0_SERVICE);
  }

  return false;
};

/**
 * @description Gets the custom error code for the error message.
 * @param error - Error object from auth0 hook.
 * @returns Custom error code.
 */
export const getCustomErrorCode = (error: unknown): CustomErrorCodes => {
  if (isAuth0ServiceError(error)) {
    return GeneralConfigurationErrorCodes.GC0001;
  }

  if (
    error instanceof Error &&
    error.message === Auth0Errors.REMAINING_ATTEMPTS_ERROR
  ) {
    return VerifySecurityQuestionCustomErrorCodes.SQ0006;
  }

  return GeneralConfigurationErrorCodes.GC0000;
};

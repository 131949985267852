// libraries
import { Link as LinkTo } from "react-router-dom";

// utilities
import { getClassNameFactory, getPath } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./LinkConstants";

// types
import { LinkProps } from "./LinkTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Link: React.FC<LinkProps> = ({ to, ...props }) => {
  return <LinkTo className={getClassName()} to={getPath(to)} {...props} />;
};

Link.displayName = DISPLAY_NAME;

// constants
import {
  StorageTypes,
  UrlParameters,
  UrlParametersStorageKeys,
} from "../../common/constants";

export const getStorageDataFromSearchParameters = (
  storageMap: {
    [key in UrlParametersStorageKeys]?: UrlParameters;
  },
  searchParameters: URLSearchParams
) =>
  Object.entries(storageMap).reduce((result, [storageKey, searchParameter]) => {
    const value = searchParameters.get(searchParameter);

    return value ? { ...result, [storageKey]: value } : result;
  }, {});

export const isTranslateReady = (translate: (key: string) => string) =>
  translate("Page.CompanyName") !== "";

export const saveToStorage = (
  dataForStorage: {
    [key in UrlParametersStorageKeys]?: string;
  },
  storageType: StorageTypes
) => {
  for (const storageKey in dataForStorage) {
    const shouldSetToStorage = !window[storageType].getItem(storageKey);

    if (shouldSetToStorage) {
      window[storageType].setItem(storageKey, dataForStorage[storageKey]);
    }
  }
};

// libraries
import { useEffect } from "react";

//components
import { DefaultToast, EnterpriseToast } from "./components";

// constants
import {
  CLOSE_TIMEOUT,
  TOAST_DISPLAY_NAME,
  ToastTypes,
} from "./ToastConstants";

// types
import { ToastProps } from "./ToastTypes";

export const Toast: React.FC<ToastProps> = ({
  children,
  id,
  onClose,
  type,
}) => {
  const handleClose = () => onClose(id);

  useEffect(() => {
    const timerId = setTimeout(() => onClose(id), CLOSE_TIMEOUT);

    return () => clearTimeout(timerId);
  }, [id, onClose]);

  return type === ToastTypes.ENTERPRISE ? (
    <EnterpriseToast onClose={handleClose}>{children}</EnterpriseToast>
  ) : (
    <DefaultToast onClose={handleClose} type={type}>
      {children}
    </DefaultToast>
  );
};

Toast.displayName = TOAST_DISPLAY_NAME;

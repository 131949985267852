/**
 * @description Finds a label in the DOM using passed id value. Scrolls document to get this label into user view.
 *
 * @param id - Input's value of html id attribute.
 */

const scrollToField = (id: string) => {
  const label = document.querySelector<HTMLLabelElement>(`label[for=${id}]`);

  label?.scrollIntoView();
};

/**
 * @description Finds an input in the DOM by passed id value and sets focus on this input.
 *
 * @param id - Input's value of html id attribute.
 */

export const focusOnField = (id: string) => {
  const input = document.getElementById(id);

  input?.focus();
};

/**
 * @description Using id value focuses on input and scrolls to the input's label in the DOM.
 *
 * @param id - Input's value of html id attribute.
 */

export const focusOnAndScrollToField = (id: string) => {
  focusOnField(id);
  scrollToField(id);
};

/**
 * @description Using id value focuses on element and scrolls to the element in the DOM.
 *
 * @param focusId - Element's value of html id attribute.
 * @param scrollToId - Element's value of html id attribute.
 */

export const focusOnAndScrollTo = ({
  focusId,
  scrollToId,
}: {
  focusId: string;
  scrollToId?: string;
}) => {
  const elementToFocus = document.getElementById(focusId);
  const elementToScroll = document.getElementById(scrollToId || focusId);

  elementToFocus?.focus();
  elementToScroll?.scrollIntoView();
};

// libraries
import { useSelector } from "react-redux";

// types
import { ReducerState as FactorsReducerState } from "../../redux/mfa/factors/getFactors";
import { ReducerState as StatusReducerState } from "../../redux/mfa/getStatus/getStatusTypes";
import { State } from "../../redux";

/**
 * @description Retrieves MFA data from the Redux store.
 * @returns An object containing the following properties:
 * - `isFactorsLoading`: A boolean value indicating whether the factors are loading.
 * - `isMfaDataLoading`: A boolean value indicating whether both the factors and status are loading.
 * - `isMfaEnabled`: A boolean value indicating whether MFA is enabled.
 * - `isStatusLoading`: A boolean value indicating whether the status is loading.
 * - `mfaFactors`: An array of enrolled MFA factors.
 */
export const useMfaData = () => {
  const { enrolledFactors, isLoading: isFactorsLoading } = useSelector<
    State,
    FactorsReducerState
  >((state) => state.getFactors);

  const { isLoading: isStatusLoading, isEnabled } = useSelector<
    State,
    StatusReducerState
  >((state) => state.getStatus);

  return {
    isFactorsLoading,
    isMfaDataLoading: isStatusLoading || isFactorsLoading,
    isMfaEnabled: isEnabled,
    isStatusLoading,
    mfaFactors: enrolledFactors,
  };
};

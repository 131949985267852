// constants
import { ProfileManagementCustomErrorCodes } from "../../common/constants";

export class ProfileManagementError {
  errorCode: ProfileManagementCustomErrorCodes;
  message: string;

  constructor({ customErrorCode, message }) {
    this.errorCode = customErrorCode;
    this.message = message;
  }
}

export enum UpdateUserErrorCodes {
  ACCESS_DENIED = "access_denied",
  INEXISTENT_USER = "inexistent_user",
  INVALID_BODY = "invalid_body",
}

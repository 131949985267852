// constants
import { Locales, extendedDropdownLocaleItems } from "../../common/constants";

/**
 * @description The function uses the passed locale to look up the necessary label in the dropdownLocaleItems object.
 *
 * @param locale - Country and language pair that defines page translation.
 *
 * @returns found locale or default value. Default value is English (US).
 */

export const getLocaleLabel = (locale: Locales) =>
  extendedDropdownLocaleItems.find(({ value }) => value === locale)?.label ??
  extendedDropdownLocaleItems[3].label;

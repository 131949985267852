// libraries
import { Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useRoutes } from "react-router-dom";

// components
import { Loading } from "../";

// utilities
import {
  useAuth0ErrorHandling,
  useRedirect,
  useTransformPath,
} from "./AppHooks";
import { useDatadogRum } from "../../analytics/datadog-rum/hooks";
// constants
import { routesWithBase } from "../Router";

export const App = () => {
  const routes = useRoutes(routesWithBase);
  const { isLoading } = useAuth0();
  const { shouldHandleError } = useAuth0ErrorHandling();

  useDatadogRum();
  useRedirect();
  useTransformPath();

  return isLoading || shouldHandleError ? (
    <Loading />
  ) : (
    <Suspense fallback={<Loading />}>{routes}</Suspense>
  );
};

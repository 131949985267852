// libraries
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// constants
import { verificationCodeRequirements } from "../../components/VerificationCode/VerificationCodeConstants";

// types
import { ErrorMessage, ValidationRule } from "./validationHooksTypes";
import { getErrorMessageFactory } from ".";

/**
 * @description Provides validation for verification code input.
 * @param Object - An object containing the default input value and an array of validation rules which can be found in `validationHooksConstants`.
 * @returns An object containing the following properties:
 * - `change`: A function that updates the value of the input.
 * - `displayInvalidVerificationCodeError`: A function that displays an error message if the verification code is invalid.
 * - `errorMessage`: The current error message, if any.
 * - `validate`: A function that validates the value of the input using the provided validation rules.
 * - `value`: The current value of the input.
 */
export const useVerificationCodeInputValidation = ({
  defaultInputValue,
  validationRules,
}: {
  defaultInputValue: string[];
  validationRules: ValidationRule<string[]>[];
}) => {
  const translate = useTranslate();
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [value, setValue] = useState(defaultInputValue);

  const getErrorMessage = getErrorMessageFactory(validationRules);

  const translateErrorMessage = (message: ErrorMessage | null) =>
    message && translate(message.key, [message.replacements]);

  const change = (inputValue: string[]) => {
    setValue(inputValue);

    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  /**
   * @description Sets inline error message to expired registration code message
   */

  const displayExpiredVerificationCodeError = () => {
    const rawErrorMessage =
      verificationCodeRequirements.errors.getExpiredOtpCodeError();
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setErrorMessage(translatedErrorMessage);
  };

  /**
   * @description Sets inline error message to expired phone code message
   */
  const displayExpiredPhoneVerificationCodeError = () => {
    const rawErrorMessage =
      verificationCodeRequirements.errors.getExpiredPhoneOtpCodeError();
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setErrorMessage(translatedErrorMessage);
  };

  /**
   * @description Sets inline error message to invalid code message
   */

  const displayInvalidVerificationCodeError = () => {
    const rawErrorMessage =
      verificationCodeRequirements.errors.getInvalidOtpCodeError();
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setErrorMessage(translatedErrorMessage);
  };

  const reset = () => change(defaultInputValue);

  const validate = () => {
    const rawErrorMessage = getErrorMessage(value);
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setErrorMessage(translatedErrorMessage);

    return rawErrorMessage;
  };

  return {
    change,
    displayExpiredPhoneVerificationCodeError,
    displayExpiredVerificationCodeError,
    displayInvalidVerificationCodeError,
    errorMessage,
    reset,
    validate,
    value,
  };
};

// constants
import {
  Requirements,
  requirementToPredicateMap,
} from "./PasswordRequirementsConstants";

/**
 * Checks if the password user entered is strong enough.
 * Score is defined by letters / numbers / special characters user entered. Length is not included.
 * @param password - String with the entered password.
 * @returns Number of requirements met.
 */
export const getPasswordScore = (password: string) =>
  Object.values(Requirements)
    .filter((textKey) => textKey !== Requirements.LENGTH) // filter out length requirement
    .map((requirement) => requirementToPredicateMap[requirement](password))
    .filter((isMet) => isMet).length;

/**
 * Gets a list of met and unmet requirements for entered password.
 * @param password - String with the entered password.
 * @returns A List with requirements where:
 * textKey - dictionary key with the name of requirement;
 * isMet - boolean value if password meets the requirement.
 */
export const getRequirements = (password: string) =>
  Object.values(Requirements).map((requirement) => ({
    isMet: requirementToPredicateMap[requirement](password),
    textKey: requirement,
  }));

// libraries
import classNames from "classnames";
import { createPortal } from "react-dom";
import { useCallback, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

// components
import { Toast } from "./Toast";

// utilities
import ToastContext from "./ToastContext";
import { getClassNameFactory } from "../../utilities";
import { useFeatureToggle } from "../../providers/FeatureToggleProvider";

// constants
import { AriaLiveTypes } from "../../common/constants";
import { FeatureNames } from "../../providers/FeatureToggleProvider";
import { TOAST_WRAPPER_DISPLAY_NAME, ToastTypes } from "./ToastConstants";

// types
import { AddToastParameters, ToastWithId } from "./ToastTypes";

const getClassName = getClassNameFactory(TOAST_WRAPPER_DISPLAY_NAME);

const ToastProvider: React.FC = ({ children }) => {
  const isNewProfileFlowEnabled = useFeatureToggle(
    FeatureNames.PROFILE_PAGE_NEW_FLOW
  );
  const [toasts, setToasts] = useState<ToastWithId[]>([]);

  const add = useCallback(
    ({ text, type = ToastTypes.SUCCESS }: AddToastParameters) =>
      setToasts((toastsList) => [...toastsList, { id: uuid(), text, type }]),
    []
  );

  const remove = useCallback(
    (id: string) =>
      setToasts((toastsList) => toastsList.filter((toast) => toast.id !== id)),
    []
  );

  const controls = useMemo(() => ({ add, remove }), [add, remove]);

  return (
    <ToastContext.Provider value={controls}>
      {children}
      {createPortal(
        <div
          aria-live={AriaLiveTypes.ASSERTIVE}
          className={getClassName({
            modifiers: classNames({
              default: !isNewProfileFlowEnabled,
              enterprise: isNewProfileFlowEnabled,
            }),
          })}
        >
          {toasts.map(({ id, text, type }) => (
            <Toast id={id} key={id} onClose={remove} type={type}>
              {text}
            </Toast>
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};

export const withToastProvider =
  (Component: React.FC): React.FC =>
  () =>
    (
      <ToastProvider>
        <Component />
      </ToastProvider>
    );

// libraries
import { Provider } from "react-redux";

// utilities
import { store } from "../../redux";

/**
 * @description Provides redux store.
 * Every functionality that requires redux store values should be wrapped in the provider.
 */

export const StoreProvider: React.FC = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

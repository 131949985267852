export const REG_KEY_DOES_NOT_EXIST_ERROR = "Registration key does not exist";

export enum LoadingStates {
  COMPLETED = "completed",
  IDLE = "idle",
  LOADING = "loading",
}

export enum OrderingState {
  ASCENDING = "ascending order",
  DESCENDING = "descending order",
}

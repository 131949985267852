// utilities
import { useFeatureToggle } from "../providers/FeatureToggleProvider";

// constants
import { FeatureNames } from "../providers/FeatureToggleProvider";
import {
  dropdownLocaleItems,
  extendedDropdownLocaleItems,
} from "../common/constants";

/**
 * @description Gets a list of dropdown locale items based on the feature toggle.
 * @returns List of locales sorted alphabetically by label.
 */
export const useDropdownLocales = () => {
  const isLanguageSupportEnabled = useFeatureToggle(
    FeatureNames.LANGUAGE_SUPPORT
  );

  const dropdownLocales = isLanguageSupportEnabled
    ? extendedDropdownLocaleItems
    : dropdownLocaleItems;

  return dropdownLocales.sort((first, second) =>
    first.label.localeCompare(second.label)
  );
};

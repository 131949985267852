// constants
import { ButtonColors } from "../../../../components/Buttons/Button/ButtonConstants";
import { paths } from "../../../../common/constants";

// types
import { EpilogItem } from "./EpilogTypes";

export const DISPLAY_NAME = "Epilog";

export const epilogItems: EpilogItem[] = [
  {
    buttonColor: ButtonColors.PRIMARY,
    buttonText: "Epilog.SignInButton",
    description: "Epilog.Description1",
    path: paths.profile,
  },
  {
    buttonColor: ButtonColors.SECONDARY,
    buttonText: "Epilog.RegisterButton",
    description: "Epilog.Description2",
    path: paths.registration.index,
  },
];

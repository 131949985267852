export const AlertCircle_1_Icon: React.FC = () => (
  <svg
    focusable="false"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path
        d="M12 1a11 11 0 1 0 0 22 11 11 0 1 0 0-22z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
      />
      <path
        d="M12 7v6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
      />
      <path d="M12 16a1 1 0 1 0 0 2 1 1 0 1 0 0-2z" />
    </g>
  </svg>
);

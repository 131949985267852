export const VerifiedTextMessageIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 76 69" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path
          d="M2.528 16.513L2.212 59l16-13H58a4 4 0 004-4V14a4 4 0 00-4-4H9.018c-3.43 0-6.49 1.976-6.49 6.513z"
          stroke="#404040"
        />
        <g stroke="#FA6400" transform="translate(18 26)">
          <circle cx="2" cy="2" r="2" />
          <circle cx="14" cy="2" r="2" />
          <circle cx="26" cy="2" r="2" />
        </g>
      </g>
      <g transform="translate(47 40)">
        <circle
          cx="12"
          cy="12"
          fill="#FA6400"
          r="9.75"
          stroke="#FA6400"
          strokeWidth="4.5"
        />
        <circle cx="12" cy="12" r="14.25" stroke="#FFF" strokeWidth="4.5" />
        <circle cx="12" cy="12" r="12" stroke="#FA6400" />
      </g>
      <path
        d="M54.875 52.19l2.647 2.773 6.016-6.301"
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth="1.575"
      />
    </g>
  </svg>
);

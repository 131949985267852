// libraries
import classNames from "classnames";
import { useTranslate } from "@dcl/tools";

// components
import { ArrowLeftIcon } from "../../../common/icons";
import { TextButton } from "../..";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { DISPLAY_NAME } from "./BackButtonConstants";

// types
import { BackButtonProps } from "./BackButtonTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const BackButton: React.FC<BackButtonProps> = ({
  className,
  onClick,
  text,
}): JSX.Element => {
  const translate = useTranslate();

  return (
    <TextButton
      className={classNames(getClassName(), className)}
      onClick={onClick}
    >
      <ArrowLeftIcon />
      {text ?? translate("Button.Back")}
    </TextButton>
  );
};

BackButton.displayName = DISPLAY_NAME;

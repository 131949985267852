export const UnverifiedAuthGuardianIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 68 63" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fill="#404040" fillRule="nonzero">
        <path d="M43.05 0H25l5.578 17.679h18.05L34.025 28.22 39.605 46c9.4-6.964 12.471-17.503 9.025-28.321L43.05 0zM1.371 17.679h18.05L25 0H6.951l-5.58 17.679C-2.077 28.497.996 39.036 10.396 46l5.579-17.779L1.37 17.68z" />
        <path d="M11 45.586L25.5 56 40 45.586 25.5 35z" />
      </g>
      <g transform="translate(39 34)">
        <circle
          cx="12"
          cy="12"
          fill="#FFF"
          r="14.25"
          stroke="#FFF"
          strokeWidth="4.5"
        />
        <circle cx="12" cy="12" r="13" stroke="#666" strokeWidth="2" />
      </g>
      <path
        d="M46.875 46.19l2.647 2.773 6.015-6.3"
        stroke="#666"
        strokeLinecap="round"
        strokeWidth="1.575"
      />
    </g>
  </svg>
);

export const WatchCircle_2_Icon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1.25 1.25)"
    >
      <path
        d="M23.75 9.313L22.5 0H5L3.75 9.313M23.75 28.188L22.5 37.5H5l-1.25-9.313"
        stroke="#FA6400"
      />
      <circle cx="13.75" cy="18.75" r="13.75" stroke="#404040" />
      <path d="M13.75 12.5v6.25H20" stroke="#FA6400" />
    </g>
  </svg>
);

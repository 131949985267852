// constants
import { authenticationHintsConfiguration } from "./AuthorizationProviderConstants";

// types
import { AuthenticationConfiguration } from "./AuthorizationProviderTypes";

export const getAuthenticationConfiguration = () =>
  authenticationHintsConfiguration.find(({ path }) =>
    window.location.pathname.includes(path)
  ) ?? ({} as AuthenticationConfiguration);

// components
import { ChevronDown_1_Icon as ChevronDownIcon } from "../../../common/icons";
import { ChevronUpIcon } from "../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { ButtonTypes, RoleTypes } from "../../../common/constants";
import { DISPLAY_NAME } from "../DropdownConstants";

// types
import { IDropdownHeaderProps } from "../DropdownTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const DropdownHeader: React.FC<IDropdownHeaderProps> = ({
  buttonRef,
  isExpanded,
  expandButtonId,
  labeledBy,
  text,
  toggleExpand,
}) => (
  <button
    aria-expanded={isExpanded}
    aria-haspopup={RoleTypes.LIST_BOX}
    aria-labelledby={labeledBy}
    className={getClassName("header")}
    id={expandButtonId}
    onClick={toggleExpand}
    ref={buttonRef}
    type={ButtonTypes.BUTTON}
  >
    <span className={getClassName("headerText")}>{text}</span>
    <span className={getClassName("headerIcon")}>
      {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </span>
  </button>
);

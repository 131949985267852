// components
import { DefaultCodeLabel, EnterpriseCodeLabel } from "./components";

// constants
import { DISPLAY_NAME } from "./CodeLabelConstants";

// types
import { CodeLabelProps } from "./CodeLabelTypes";

export const CodeLabel: React.FC<CodeLabelProps> = ({
  codeLength,
  id,
  isEnterprise,
  secondaryLabelText,
  onResendCode,
  resendCodeButtonText,
}) => {
  return (
    <>
      {isEnterprise ? (
        <EnterpriseCodeLabel
          codeLength={codeLength}
          id={id}
          onResendCode={onResendCode}
          resendCodeButtonText={resendCodeButtonText}
          secondaryLabelText={secondaryLabelText}
        />
      ) : (
        <DefaultCodeLabel
          codeLength={codeLength}
          id={id}
          onResendCode={onResendCode}
          resendCodeButtonText={resendCodeButtonText}
          secondaryLabelText={secondaryLabelText}
        />
      )}
    </>
  );
};

CodeLabel.displayName = DISPLAY_NAME;

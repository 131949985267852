export const DELAY_TIME = 2000;
export const DISPLAY_NAME = "CopyCodeButton";

export enum CopyCodeButtonText {
  COPIED_CODE = "CopyCodeButton.CopiedCode",
  COPY_CODE = "CopyCodeButton.CopyCode",
  LOADING = "CopyCodeButton.Loading",
}

export const initialState = {
  iconName: "copy",
  isLoading: false,
  text: CopyCodeButtonText.COPY_CODE,
};

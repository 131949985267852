export enum ErrorMessages {
  ABORT_REQUEST = "The user aborted a request",
  ACCOUNTS_LINKING = "Failed to link accounts",
  ADD_PHONE_CALL = "Failed to call phone",
  ADD_TEXT_MESSAGE = "Failed to send text message",
  AUTH0_SERVICE = "Service not found",
  AUTHENTICATOR_NOT_FOUND = "The requested authenticator was not found. Check the authenticator id.",
  AUTHORIZATION_PENDING = "Repeat the request in a few seconds",
  COMMON_PASSWORD = "Password is too common",
  CONFIGURATION_VALIDATION = "Failed to load application configuration",
  DELETE_ALL_FACTORS = "Failed to delete  all factors",
  DELETE_FACTOR = "Failed to delete factor",
  EMAIL_EXISTS = "The specified new email already exists",
  EMAIL_IS_NOT_VERIFIED = "Email is not verified",
  ENROLLMENT_EMAIL_SENDING = "Failed to send enrollment email",
  EXPIRED_OTP_CODE_ERROR = "Expired verification code",
  EXPIRED_PHONE_OTP_CODE_ERROR = "oob_code is expired",
  FACTOR_NOT_FOUND = "Factor not found",
  GET_ACCESS_TOKEN = "Failed to get access token",
  GET_ENROLLED_MFA_FACTORS = "Failed to get enrolled MFA factors",
  GET_MFA_FACTORS = "Failed to get MFA factors list",
  GET_MFA_STATUS = "Failed to get MFA status",
  GET_PRODUCT_URL_INVALID_ID = "Client id provided is not found",
  GET_QR_CODE = "Failed to get QR code URI",
  GET_SECURITY_QUESTIONS_LIST = "Failed to get security questions list",
  GET_SELECTED_SECURITY_QUESTION = "Failed to get selected security question",
  GET_USER = "Failed to get user information",
  GET_VERIFICATION_OPTION_CONFIGURATION = "There is no configuration for that kind of verification option",
  INVALID_BINDING_CODE_ERROR = "Invalid binding_code.",
  INVALID_OTP_CODE_ERROR = "Invalid OTP",
  INVALID_PASSWORD = "Password is too weak",
  MALFORMED_MFA_TOKEN = "Malformed mfa_token",
  MALFORMED_OBB_CODE = "Malformed oob_code",
  PASSWORD_CONTAINS_USER_INFORMATION = "Password contains user information",
  PASSWORD_IN_HISTORY = "Password has previously been used",
  PASSWORD_UPDATE = "Failed to update password",
  RECAPTCHA_THRESHOLD = "Recaptcha score is below the threshold",
  REDIRECT_URL_DOES_NOT_EXISTS = "Redirect URL doesn't exist",
  REGENERATE_RECOVERY_CODE = "Failed to regenerate recovery code",
  SEND_CODE_PHONE_CALL = "Failed to call phone",
  SEND_CODE_TEXT_MESSAGE = "Failed to send text message",
  SEND_VERIFICATION_CODE = "Failed to send code",
  SEND_VERIFICATION_EMAIL = "Failed to send verification email",
  THRESHOLD_EXCEEDED = "Threshold Exceeded",
  UNSUPPORTED_OOB_CHANNEL = "Unsupported OOB Channel",
  UPDATE_EMAIL = "Failed to update email",
  UPDATE_USER = "Failed to update user",
  VERIFY_EMAIL = "Failed to verify email",
  VERIFY_FACTOR = "Failed to verify factor",
}

export enum RegistrationErrorMessages {
  ACCESS_DENIED = "The request requires higher privileges.",
  GENERIC = "Failed to register user",
  PAYLOAD_VALIDATION = "Payload validation error",
  SERVER = "Internal server error",
  USER_EXISTS = "Failed to register existing user",
}

export enum RegistrationKeysErrorMessages {
  ACCESS_DENIED = "The request requires higher privileges.",
  INEXISTENT_USER = "The user does not exist",
  INVALID_IDENTIFIER = "Parameter was invalid",
  INVALID_REG_KEY = "None of the users registrations keys can be validated.",
  INVALID_SETTINGS = "The Profile contains invalid settings",
  PAYLOAD_VALIDATION = "Payload validation error",
  SERVER = "Internal Server error",
}

export enum ProfileManagementErrorMessages {
  ACCESS_DENIED = "The request requires higher privileges.",
  INEXISTENT_USER = "The user does not exist",
  PAYLOAD_VALIDATION = "Payload validation error",
  SERVER = "Internal server error",
}

export enum GeneralConfigurationErrorCodes {
  GC0000 = "GC0000",
  GC0001 = "GC0001",
  GC0002 = "GC0002", // GC0002 is used temporarily. This code might change in the future after content revision. Update as necessary
  GC0003 = "GC0003", // GC0003 is used temporarily. This code might change in the future after content revision. Update as necessary
  GC0004 = "GC0004", // GC0004 is used temporarily. This code might change in the future after content revision. Update as necessary
}

export enum PersonaSelectionCustomErrorCodes {
  PS0001 = "PS0001",
  PS0002 = "PS0002",
  PS0003 = "PS0003",
  PS0004 = "PS0004",
  PS0005 = "PS0005",
}

export enum RegistrationCustomErrorCodes {
  CP0000 = "CP0000",
  CP0001 = "CP0001",
  CP0002 = "CP0002",
  CP0003 = "CP0003",
  CP0004 = "CP0004",
  CP0005 = "CP0005",
  CP0006 = "CP0006",
  CP0007 = "CP0007",
  CP0008 = "CP0008", // CP0008 is used temporarily. This code might change in the future after content revision. Update as necessary
  CP0009 = "CP0009", // CP0009 is used temporarily. This code might change in the future after content revision. Update as necessary
}

export enum RegistrationKeysCustomErrorCodes {
  RG0000 = "RG0000",
  RG0001 = "RG0001",
  RG0002 = "RG0002",
  RG0003 = "RG0003",
  RG0004 = "RG0004",
  RG0005 = "RG0005",
  RG0006 = "RG0006",
}

export enum ProfileManagementCustomErrorCodes {
  PM0001 = "PM0001",
  PM0002 = "PM0002",
  PM0003 = "PM0003",
  PM0004 = "PM0004",
  PM0005 = "PM0005",
  PM0006 = "PM0006",
}

export enum PasswordWarningCustomErrorCodes {
  PW0001 = "PW0001",
}

export enum TwoStepVerificationCustomErrorCodes {
  MF0000 = "MF0000",
  MF0001 = "MF0001", // MF0001 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0002 = "MF0002", // MF0002 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0003 = "MF0003", // MF0003 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0004 = "MF0004", // MF0004 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0005 = "MF0005", // MF0005 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0006 = "MF0006", // MF0006 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0007 = "MF0007", // MF0007 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0008 = "MF0008", // MF0008 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0009 = "MF0009", // MF0009 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0010 = "MF0010", // MF0010 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0011 = "MF0011", // MF0011 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0012 = "MF0012", // MF0012 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0013 = "MF0013", // MF0013 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0014 = "MF0014", // MF0014 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0015 = "MF0015", // MF0015 is used temporarily. This code might change in the future after content revision. Update as necessary
  MF0016 = "MF0016", // MF0016 is used temporarily. This code might change in the future after content revision. Update as necessary
}

export enum VerifySecurityQuestionCustomErrorCodes {
  SQ0000 = "SQ0000",
  SQ0001 = "SQ0001",
  SQ0002 = "SQ0002",
  SQ0003 = "SQ0003",
  SQ0004 = "SQ0004",
  SQ0005 = "SQ0005",
  SQ0006 = "SQ0006",
}

export enum VerifyUpdatedEmailCustomErrorCodes {
  UE0000 = "UE0000", // UE0000 is used temporarily. This code might change in the future after content revision. Update as necessary
}

export type CustomErrorCodes =
  | GeneralConfigurationErrorCodes
  | ProfileManagementCustomErrorCodes
  | PersonaSelectionCustomErrorCodes
  | PasswordWarningCustomErrorCodes
  | RegistrationCustomErrorCodes
  | TwoStepVerificationCustomErrorCodes
  | VerifySecurityQuestionCustomErrorCodes
  | VerifyUpdatedEmailCustomErrorCodes;

export const OpenInNewTabIcon: React.FC = () => (
  <svg
    focusable="false"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <defs>
        <path
          d="M14.03 4.01L2 4.01L2 22L20.01 22L20.01 10.01L22.01 10.01C22.01 17.21 22.01 21.2 22.01 22C22.01 23.1 21.11 24 20.01 24C20.01 24 20.01 24 20.01 24C9.21 24 3.2 24 2 24C0.89 24 0 23.1 0 22C0 22 0 22 0 22C0 11.2 0 5.21 0 4.01C0 2.91 0.89 2.01 2 2.01C2 2.01 2 2.01 2 2.01L14.03 2.01L14.03 4.01ZM24 8.01L22.04 8.01L22.04 3.35L12.38 13L11 11.62L20.65 1.96L16 1.96L16 0L24 0L24 8.01Z"
          id="open-in-New-tab"
        />
      </defs>
      <g>
        <g>
          <use
            fill="currentColor"
            fillOpacity="1"
            href="#open-in-New-tab"
            opacity="1"
          />
          <g>
            <use
              fillOpacity="0"
              href="#open-in-New-tab"
              opacity="1"
              stroke="currentColor"
              strokeOpacity="0"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

// libraries
import { Component } from "react";

// types
import { ErrorBoundaryProps, ErrorBoundaryState } from "./ErrorBoundaryTypes";
import { FallbackUI } from "./FallbackUI";

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    error: null,
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { error, hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <FallbackUI /> : children;
  }
}

// utilities
import { toRawUser } from "..";

// constants
import { ErrorMessages } from "../../common/constants";

// types
import {
  ProfileUser,
  RawProfileUser,
  RegistrationData,
} from "../../common/types";

/**
 * @description Prepares registration data and creates from them url search parameters.
 *
 * @param registrationData - Object containing data from registration form and url query parameters.
 *
 * @returns URL search parameters object.
 */

const getSearchParameters = (registrationData: RegistrationData) => {
  const rawRegistrationPayload = Object.fromEntries(
    new URLSearchParams(registrationData.rawRegistrationPayload)
  );
  const rawUserPayload = toRawUser<ProfileUser, RawProfileUser>(
    registrationData
  );

  return new URLSearchParams({
    ...rawRegistrationPayload,
    ...rawUserPayload,
    euId: registrationData.euId,
  });
};

/**
 * @description Constructs product url using registration data. Redirects user to this url.
 *
 * @param registrationData - Object containing data from registration form and url query parameters.
 *
 * @throws Error if there is no redirect url.
 */

export const redirectToProduct = (registrationData: RegistrationData) => {
  if (!registrationData.redirectUrl) {
    throw new Error(ErrorMessages.REDIRECT_URL_DOES_NOT_EXISTS);
  }

  const url = new URL(registrationData.redirectUrl);

  getSearchParameters(registrationData).forEach((value, key) =>
    url.searchParams.append(key, value)
  );

  window.location.replace(url);
};

export const RefreshIcon: React.FC = () => (
  <svg
    fill="none"
    fillRule="evenodd"
    focusable="false"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.03 7.34A7 7 0 0114.3 4.946m.35-3.421l-.354 3.409-3.17-.58M14.965 8.706a7 7 0 01-13.266 2.348m-.349 3.421l.353-3.409 3.17.58"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

// libraries
import classNames from "classnames";
import { useTranslate } from "@dcl/tools";

// components
import { Modal, PrimaryButton, SecondaryButton, TextButton } from "..";

// utilities
import { getClassNameFactory } from "../../utilities";
import { useDialogAnalytics } from "./DialogHooks";

// constants
import { Animation } from "../Modal/ModalConstants";
import { DISPLAY_NAME } from "./DialogConstants";
import { RoleTypes } from "../../common/constants";
import { TextButtonWeightTypes } from "../Buttons";

// types
import { DialogProps } from "./DialogTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Dialog: React.FC<DialogProps> = ({
  ariaLabel,
  body,
  buttonText,
  className,
  closeLabel,
  headerIcon,
  headerText,
  id,
  isOpen,
  isOverlayCloseable,
  linkText,
  onClickButton,
  onClickLink,
  onClickSecondaryButton,
  onClose,
  secondaryButtonText,
}) => {
  const translate = useTranslate();

  useDialogAnalytics(isOpen, id);

  const ariaLabelDefault = translate("Dialog.AriaLabelDefault");
  const isCloseButtonHidden = !!linkText || !!secondaryButtonText;
  const shouldShowButtons = !!buttonText || !!linkText || !!secondaryButtonText;

  return isOpen ? (
    <Modal
      animation={Animation.SLIDEUP}
      ariaLabel={ariaLabel || ariaLabelDefault}
      className={classNames(
        className,
        getClassName({
          modifiers: classNames(
            isOverlayCloseable && "preventedDialogClose",
            "bottom"
          ),
        })
      )}
      closeLabel={closeLabel}
      closeThisComponent={onClose}
      isCloseButtonHidden={isCloseButtonHidden}
      uncloseable={isOverlayCloseable}
    >
      {headerIcon && (
        <span
          className={getClassName({ descendantName: "headerIcon" })}
          role={RoleTypes.PRESENTATION}
        >
          {headerIcon}
        </span>
      )}
      <div className={getClassName({ descendantName: "content" })}>
        <h2 className={getClassName({ descendantName: "headerText" })}>
          {headerText}
        </h2>
        <div className={getClassName({ descendantName: "body" })}>{body}</div>
      </div>
      {shouldShowButtons && (
        <div
          className={classNames(
            getClassName({ descendantName: "buttons" }),
            "ie-button-fix"
          )}
        >
          {buttonText && onClickButton && (
            <PrimaryButton onClick={onClickButton}>{buttonText}</PrimaryButton>
          )}
          {linkText && onClickLink && (
            <TextButton
              onClick={onClickLink}
              weight={TextButtonWeightTypes.NORMAL}
            >
              {linkText}
            </TextButton>
          )}
          {secondaryButtonText && onClickSecondaryButton && (
            <SecondaryButton onClick={onClickSecondaryButton}>
              {secondaryButtonText}
            </SecondaryButton>
          )}
        </div>
      )}
    </Modal>
  ) : null;
};

Dialog.displayName = DISPLAY_NAME;

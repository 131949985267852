// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { updateUser } from "./updateUserThunk";

// types
import { ReducerState } from "./types";

const initialState: ReducerState = {
  error: null,
  isLoading: false,
  user: null,
};

export const userSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
  initialState,
  name: "user",
  reducers: {
    completeFetch(state) {
      state.isLoading = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

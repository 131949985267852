// libraries
import { ITranslationFunction } from "@dcl/tools";

// constants
import { metaTagsConfiguration } from "./AboutPageConstants";

export const createMetaTags = (translate: ITranslationFunction) =>
  metaTagsConfiguration.map(({ contentKey, name }) => {
    const metaTag = document.createElement("meta");

    metaTag.name = name;

    metaTag.content = translate(contentKey);

    return metaTag;
  });

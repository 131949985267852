export interface Action {
  type: ActionTypes;
}
export enum ActionTypes {
  COPIED_CODE = "copied code",
  COPY_CODE = "copy code",
  LOADING = "loading",
}
export interface CopyButtonConfiguration {
  iconName: string;
  isLoading: boolean;
  text: string;
}
export interface CopyCodeButtonProps {
  code: string;
  onCopy?: () => void;
}

// libraries
import { useCallback } from "react";
import { useDispatch } from "react-redux";

// utilities
import { getFactors, getFactorsSlice } from "../../redux";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { useGetMfaAccessToken, useNavigation } from "..";

// constants
import { TwoStepVerificationCustomErrorCodes } from "../../common/constants";

// types
import { Dispatch } from "../../redux";

/**
 * @description Gets MFA factors for a user from Auth0.
 * @returns A callback function to get MFA factors.
 * @handlesError Redirects to Generic Error page.
 */
export const useGetMfaFactors = () => {
  const dispatch = useDispatch<Dispatch>();
  const getMfaAccessToken = useGetMfaAccessToken();
  const {
    auth0: { domain: apiBasePath },
  } = useConfiguration();

  const { paths, redirect } = useNavigation();

  return useCallback(async () => {
    try {
      dispatch(getFactorsSlice.actions.setLoading());

      const mfaAccessToken = await getMfaAccessToken();

      await dispatch(getFactors({ apiBasePath, mfaAccessToken })).unwrap();
    } catch (error) {
      redirect(paths.customError, {
        errorCode: TwoStepVerificationCustomErrorCodes.MF0001,
      });
    }
  }, [apiBasePath, dispatch, getMfaAccessToken, paths.customError, redirect]);
};

// constants
import { FeatureNames, FeatureStatuses } from "./FeatureToggleConstants";

export const features = {
  [FeatureNames.ADD_OPT_APP_TOOLTIP]: FeatureStatuses.OFF,
  [FeatureNames.DATADOG_RUM]: FeatureStatuses.ON,
  [FeatureNames.DATADOG_RUM_SESSION_REPLAY]: FeatureStatuses.OFF,
  [FeatureNames.DISPLAY_AVATAR]: FeatureStatuses.ON,
  [FeatureNames.DISPLAY_CURRENT_PASSWORD_FIELD]: FeatureStatuses.OFF,
  [FeatureNames.ENABLE_PHONE_CALL]: FeatureStatuses.ON,
  [FeatureNames.LANGUAGE_SUPPORT]: FeatureStatuses.OFF,
  [FeatureNames.MANAGING_OTP_NAME]: FeatureStatuses.OFF,
  [FeatureNames.PROFILE_PAGE_NEW_FLOW]: FeatureStatuses.ON,
  [FeatureNames.REG_KEY_MANAGEMENT]: FeatureStatuses.OFF,
  [FeatureNames.SECURITY_QUESTION]: FeatureStatuses.ON,
};

// constants
import { MfaFactorTypes } from "../../common/constants";

// types
import { EnrolledMfaFactor } from "../../common/types";

/**
 * @description Gets number of enrollments of a certain MFA factor.
 *
 * @param enrolledFactors - List of enabled MFA factors consumable by frontend.
 *
 * @param type - Type of enrolled factor (e.g. email, otp).
 *
 * @returns Number of enrollments.
 */

export const getNumberOfFactorEnrollments = (
  enrolledFactors: EnrolledMfaFactor[],
  type: MfaFactorTypes
) =>
  enrolledFactors.filter((enrolledFactor) => enrolledFactor.type === type)
    .length;

// libraries
import { getClassName } from "react-suitcssify/dist/lib/utility";

// types
import { ClassNameOptions } from "./types";

/**
 * @description Creates getClassName function for provided component name.
 *
 * @param displayName - Display Name of the React component which is used for className creation.
 *
 * @returns Function that creates classNames in the scope of the component.
 */

export const getClassNameFactory = (displayName: string) => {
  return (argument?: string | Partial<ClassNameOptions>) => {
    const result =
      typeof argument === "string"
        ? { componentName: displayName, descendantName: argument }
        : { componentName: displayName, ...argument };

    return getClassName(result);
  };
};

export const CircleIcon: React.FC = () => (
  <svg
    focusable="false"
    height="65"
    viewBox="0 0 65 65"
    width="65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" strokeLinecap="round" strokeWidth="2">
      <path
        d="M32 42c-6.075 0-11-4.925-11-11v-3c0-6.075 4.925-11 11-11s11 4.925 11 11v3c0 6.075-4.925 11-11 11z"
        stroke="#FF6400"
      />
      <path
        d="M14.529 56.391C16.973 51.421 22.088 48 28 48h8c5.906 0 11.024 3.43 13.471 8.391"
        stroke="#FF6400"
      />
      <path d="M32 2a30 30 0 1 0 0 60 30 30 0 1 0 0-60z" stroke="#404040" />
    </g>
  </svg>
);

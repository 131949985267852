// constants
import {
  profileToRawMap,
  rawToProfileUserMap,
  rawToUpdatedUserMap,
} from "./mapObjectKeysConstants";

/**
 * @description Transforms object keys by a provided map.
 *
 * @param map - Object which values contain new keys naming (e.g., "first_name": "firstName").
 *
 * @returns New object with keys according to a map.
 */

export const mapObjectKeys =
  <M>(map: M) =>
  <U extends object, R>(object: U): R => {
    return Object.keys(object).reduce(
      (result, key) =>
        map[key] ? { ...result, [map[key]]: object[key].trim() } : result,
      {} as R
    );
  };

/**
 * @description Transforms raw user data from backend response to profile user data consumable by frontend.
 *
 * @returns Object with prepared user data.
 */

export const toUpdatedProfileUser = mapObjectKeys(rawToUpdatedUserMap);

/**
 * @description Transforms raw user data from backend response to profile user data consumable by frontend.
 *
 * @returns Object with prepared user data.
 */

export const toProfileUser = mapObjectKeys(rawToProfileUserMap);

/**
 * @description Transforms profile user data to raw user data consumable by backend.
 *
 * @returns Object with raw user data.
 */

export const toRawUser = mapObjectKeys(profileToRawMap);

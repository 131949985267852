// libraries
import {
  Country,
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input";

// constants
import {
  DEFAULT_COUNTRY,
  bannedCountries,
  countries,
  dictionaries,
} from "./PhoneNumberInputConstants";

const isAvailableCountry = (bannedCountry: string, country: string) =>
  country !== bannedCountry;

export const getAvailableCountriesList = () =>
  getCountries().filter(
    (country) =>
      !bannedCountries.some(
        (bannedCountry) => !isAvailableCountry(bannedCountry, country)
      )
  );

export const getCountryOptionLabel = (rawLabel: string, country?: Country) => {
  const countryCallingCode = country
    ? ` (+${getCountryCallingCode(country)})`
    : "";

  return rawLabel + countryCallingCode;
};

export const getDefaultCountry = (country: string) =>
  countries[country] ?? DEFAULT_COUNTRY;

export const getDictionary = (locale: string) => dictionaries[locale];

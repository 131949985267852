// constants
import { ErrorMessage } from ".";
import { MaximumLengths } from "../../common/constants";
import {
  currentPasswordValidationRules,
  newPasswordValidationRules,
  passwordConfirmationValidationRules,
} from "./validationHooksConstants";

// types
import {
  FieldValidationList,
  FieldValidity,
  ValidationRule,
} from "./validationHooksTypes";

export class FieldValidationError<T = string> {
  constructor(public fieldId: T, public errorMessages: ErrorMessage[]) {}
}

const checkValidityState = (validityState: FieldValidity) => {
  const { errorMessages, fieldId } = validityState;

  if (fieldId) {
    throw new FieldValidationError(fieldId, errorMessages);
  }
};

const getValidityState = <T>(
  validationList: FieldValidationList<T>
): FieldValidity =>
  validationList.reduce(
    (result: FieldValidity, { fieldValidation, id }) => {
      const errorMessage = fieldValidation.validate();

      return errorMessage
        ? {
            errorMessages: [...result.errorMessages, errorMessage],
            fieldId: result.fieldId ? result.fieldId : id,
          }
        : result;
    },
    { errorMessages: [], fieldId: "" }
  );

const isDomainLengthExceed = (domain: string) => {
  const domainParts = domain.split(".");

  return domainParts.some(
    (domainPart) => domainPart.length > MaximumLengths.EMAIL_DOMAIN_PART
  );
};

export const areEqual = (value: string, target: string) => value === target;

export const getErrorMessageFactory =
  <T>(rules: ValidationRule<T>[]) =>
  (input: T) => {
    for (let rule of rules) {
      const errorMessage = rule(input);

      if (errorMessage) {
        return errorMessage;
      }
    }

    return null;
  };

export const getCurrentPasswordErrorMessage = getErrorMessageFactory(
  currentPasswordValidationRules
);

export const getNewPasswordErrorMessage = getErrorMessageFactory(
  newPasswordValidationRules
);

export const getPasswordConfirmationErrorMessage = getErrorMessageFactory(
  passwordConfirmationValidationRules
);

export const isEmailLengthExceeded = (email: string) => {
  const [user, domain] = email.split("@");

  return (
    user.length > MaximumLengths.EMAIL_USER_PART ||
    isDomainLengthExceed(domain) ||
    email.length > MaximumLengths.EMAIL
  );
};

export const isEmpty = (value: string) => value.length === 0;

export const isPatternMismatched = (value: string, pattern: string) => {
  const regexObj = new RegExp(pattern, "u");

  return !value.match(regexObj);
};

export const isMaximumLengthExceeded = (value: string, length: number) =>
  value.length > length;

export const isMinimumLengthReached = (value: string, length: number) =>
  value.length >= length;

export const validateForm = <T>(validationList: FieldValidationList<T>) => {
  validationList.forEach(({ fieldValidation }) => fieldValidation.validate());

  checkValidityState(getValidityState(validationList));
};

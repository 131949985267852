// libraries
import { compose } from "redux";
import { lazy } from "react";

// components
import { AppContainer } from "../AppContainer";

// utilities
import {
  withAuth0Authentication,
  withHash,
  withReCaptchaProvider,
  withRoute,
  withStorage,
} from "./hocs";
import { withToastProvider } from "../Toast";

// constants
import {
  EmailVerificationStorageKeys,
  PasswordExpiredStorageKeys,
  PersonaSelectionStorageKeys,
  StorageTypes,
  UrlParameters,
  paths,
} from "../../common/constants";

// types
import { Route } from "./types";

const AboutPage = lazy(() =>
  import("../../pages/AboutPage").then(({ AboutPage }) => ({
    default: compose(withRoute({ documentTitleKey: "AboutPage.Title" }))(
      AboutPage
    ),
  }))
);

const AccountCreatedPage = lazy(() =>
  import("../../pages/AccountCreatedPage").then(({ AccountCreatedPage }) => ({
    default: compose(
      withRoute({ documentTitleKey: "AccountCreatedPage.Title" })
    )(AccountCreatedPage),
  }))
);

const CheckEmailPage = lazy(() =>
  import("../../pages/CheckEmailPage").then(({ CheckEmailPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.checkEmail }),
      withRoute({ documentTitleKey: "CheckEmailPage.Title" }),
      withToastProvider
    )(CheckEmailPage),
  }))
);

const CustomErrorPage = lazy(() =>
  import("../../pages/CustomErrorPage").then(({ CustomErrorPage }) => ({
    default: withRoute({ documentTitleKey: "CustomErrorPage.Title" })(
      CustomErrorPage
    ),
  }))
);

const DashboardPage = lazy(() =>
  import("../../pages/DashboardPage").then(({ DashboardPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.profile }),
      withRoute({ documentTitleKey: "DashboardPage.Title" }),
      withToastProvider
    )(DashboardPage),
  }))
);

const EmailVerificationPage = lazy(() =>
  import("../../pages/EmailVerificationPage").then(
    ({ EmailVerificationPage }) => ({
      default: compose<React.FC>(
        withStorage({
          storageMap: {
            [EmailVerificationStorageKeys.CLIENT_ID]: UrlParameters.CLIENT_ID,
            [EmailVerificationStorageKeys.STATE]: UrlParameters.STATE,
          },
          type: StorageTypes.SESSION,
        }),
        withAuth0Authentication({ returnTo: paths.verifyNewEmail }),
        withRoute({ documentTitleKey: "EmailVerificationPage.Title" })
      )(EmailVerificationPage),
    })
  )
);

const EmailVerifiedPage = lazy(() =>
  import("../../pages/EmailVerifiedPage").then(({ EmailVerifiedPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.emailVerified }),
      withRoute({ documentTitleKey: "EmailVerifiedPage.Title" })
    )(EmailVerifiedPage),
  }))
);

const EmailVerifiedByOtpPage = lazy(() =>
  import("../../pages/EmailVerifiedByOtpPage").then(
    ({ EmailVerifiedByOtpPage }) => ({
      default: compose(
        withAuth0Authentication({ returnTo: paths.emailVerifiedByOtp }),
        withRoute({ documentTitleKey: "EmailVerifiedByOtpPage.Title" })
      )(EmailVerifiedByOtpPage),
    })
  )
);

const EmergencyOtpPage = lazy(() =>
  import(
    /* webpackChunkName: "EmergencyOtpPage" */
    `../../pages/EmergencyOtpPage`
  ).then(({ EmergencyOtpPage }) => ({
    default: compose(withRoute({ documentTitleKey: "EmergencyOtp.Title" }))(
      EmergencyOtpPage
    ),
  }))
);

const GenericErrorPage = lazy(() =>
  import("../../pages/GenericErrorPage").then(({ GenericErrorPage }) => ({
    default: withRoute({ documentTitleKey: "GenericErrorPage.Title" })(
      GenericErrorPage
    ),
  }))
);

const ManageRegKeyPage = lazy(() =>
  import("../../pages/ManageRegKeyPage").then(({ ManageRegKeyPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.products.manageRegKey }),
      withRoute({ documentTitleKey: "ManageRegKeyPage.Title" })
    )(ManageRegKeyPage),
  }))
);

const MfaAddAuthGuardianPage = lazy(() =>
  import("../../pages/MfaAddAuthGuardianPage").then(
    ({ MfaAddAuthGuardianPage }) => ({
      default: compose(
        withAuth0Authentication({
          returnTo: paths.mfa.auth0Guardian.add,
        }),
        withRoute({ documentTitleKey: "MfaAddAuthGuardianPage.Title" })
      )(MfaAddAuthGuardianPage),
    })
  )
);

const MfaAddPhonePage = lazy(() =>
  import("../../pages/MfaAddPhonePage").then(({ MfaAddPhonePage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.mfa.phone.add }),
      withRoute({ documentTitleKey: "MfaAddPhonePage.Title" })
    )(MfaAddPhonePage),
  }))
);

const MfaAddOtpAppPage = lazy(() =>
  import("../../pages/MfaAddOtpAppPage").then(({ MfaAddOtpAppPage }) => ({
    default: compose(
      withAuth0Authentication({
        returnTo: paths.mfa.otpApp,
      }),
      withRoute({ documentTitleKey: "MfaAddOtpAppPage.Title" }),
      withToastProvider
    )(MfaAddOtpAppPage),
  }))
);

const MfaConfigurationPage = lazy(() =>
  import("../../pages/MfaConfigurationPage").then(
    ({ MfaConfigurationPage }) => ({
      default: compose(
        withAuth0Authentication({ returnTo: paths.mfa.index }),
        withRoute({ documentTitleKey: "MfaConfigurationPage.Title" }),
        withToastProvider
      )(MfaConfigurationPage),
    })
  )
);

const MfaRecoveryCodePage = lazy(() =>
  import("../../pages/MfaRecoveryCodePage").then(({ MfaRecoveryCodePage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.mfa.recoveryCode }),
      withRoute({ documentTitleKey: "MfaRecoveryCodePage.Title" }),
      withToastProvider
    )(MfaRecoveryCodePage),
  }))
);

const MfaVerifyAuthGuardianPage = lazy(() =>
  import("../../pages/MfaVerifyAuthGuardianPage").then(
    ({ MfaVerifyAuthGuardianPage }) => ({
      default: compose(
        withAuth0Authentication({
          returnTo: paths.mfa.auth0Guardian.verify,
        }),
        withRoute({ documentTitleKey: "MfaVerifyAuthGuardianPage.Title" })
      )(MfaVerifyAuthGuardianPage),
    })
  )
);

const MfaVerifyPhoneCallPage = lazy(() =>
  import("../../pages/MfaVerifyPhoneCallPage").then(
    ({ MfaVerifyPhoneCallPage }) => ({
      default: compose(
        withAuth0Authentication({
          returnTo: paths.mfa.phone.verifyPhoneCall,
        }),
        withRoute({ documentTitleKey: "MfaVerifyPhoneCallPage.Title" }),
        withToastProvider
      )(MfaVerifyPhoneCallPage),
    })
  )
);

const MfaVerifyTextMessagePage = lazy(() =>
  import("../../pages/MfaVerifyTextMessagePage").then(
    ({ MfaVerifyTextMessagePage }) => ({
      default: compose(
        withAuth0Authentication({
          returnTo: paths.mfa.phone.verifyTextMessage,
        }),
        withRoute({ documentTitleKey: "MfaVerifyTextMessagePage.Title" }),
        withToastProvider
      )(MfaVerifyTextMessagePage),
    })
  )
);

const NotFoundPage = lazy(() =>
  import("../../pages/NotFoundPage").then(({ NotFoundPage }) => ({
    default: withRoute({ documentTitleKey: "NotFoundPage.Title" })(
      NotFoundPage
    ),
  }))
);

const PasswordExpiredPage = lazy(() =>
  import("../../pages/PasswordExpiredPage").then(({ PasswordExpiredPage }) => ({
    default: compose(
      withStorage({
        storageMap: {
          [PasswordExpiredStorageKeys.REDIRECT_URL]: UrlParameters.REPLAY_URL,
        },
        type: StorageTypes.SESSION,
      }),
      withAuth0Authentication({ returnTo: paths.passwordExpired }),
      withRoute({ documentTitleKey: "PasswordExpiredPage.Title" })
    )(PasswordExpiredPage),
  }))
);

const PasswordWarningPage = lazy(() =>
  import("../../pages/PasswordWarningPage").then(({ PasswordWarningPage }) => ({
    default: compose(
      withStorage({
        storageMap: {
          [PasswordExpiredStorageKeys.REDIRECT_URL]: UrlParameters.REPLAY_URL,
        },
        type: StorageTypes.SESSION,
      }),
      withRoute({
        documentTitleKey: "PasswordWarningPage.Title",
      })
    )(PasswordWarningPage),
  }))
);

const PersonaSelectionPage = lazy(() =>
  import("../../pages/PersonaSelectionPage").then(
    ({ PersonaSelectionPage }) => ({
      default: compose(
        withHash({
          storageMap: {
            [PersonaSelectionStorageKeys.SESSION_TOKEN]:
              UrlParameters.SESSION_TOKEN,
          },
          type: StorageTypes.SESSION,
        }),
        withStorage({
          storageMap: {
            [PersonaSelectionStorageKeys.STATE]: UrlParameters.STATE,
          },
          type: StorageTypes.SESSION,
        }),
        withRoute({ documentTitleKey: "PersonaSelectionPage.Title" })
      )(PersonaSelectionPage),
    })
  )
);

const PersonalInfoPage = lazy(() =>
  import("../../pages/PersonalInfoPage").then(({ PersonalInfoPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.personalInfo }),
      withRoute({ documentTitleKey: "PersonalInfoPage.Title" }),
      withToastProvider
    )(PersonalInfoPage),
  }))
);

const ProductRegKeysPage = lazy(() =>
  import("../../pages/ProductRegKeysPage").then(({ ProductRegKeysPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.products.regKeys }),
      withRoute({ documentTitleKey: "ProductRegKeysPage.RegistrationKeys" }),
      withToastProvider
    )(ProductRegKeysPage),
  }))
);

const ProductsPage = lazy(() =>
  import("../../pages/ProductsPage").then(({ ProductsPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.products.index }),
      withRoute({ documentTitleKey: "ProductsPage.Title" }),
      withToastProvider
    )(ProductsPage),
  }))
);

const RegisterProductPage = lazy(() =>
  import("../../pages/RegisterProductPage").then(({ RegisterProductPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.products.registerProduct }),
      withRoute({ documentTitleKey: "RegisterProductPage.Title" })
    )(RegisterProductPage),
  }))
);

const RegistrationPage = lazy(() =>
  import("../../pages/RegistrationPage").then(({ RegistrationPage }) => ({
    default: compose(
      withRoute({ documentTitleKey: "RegistrationPage.Title" }),
      withReCaptchaProvider
    )(RegistrationPage),
  }))
);

const RegistrationVerifyCodePage = lazy(() =>
  import("../../pages/RegistrationVerifyCodePage").then(
    ({ RegistrationVerifyCodePage }) => ({
      default: compose(
        withRoute({
          documentTitleKey: "RegistrationVerifyCodePage.Title",
        }),
        withReCaptchaProvider,
        withToastProvider
      )(RegistrationVerifyCodePage),
    })
  )
);

const SecurityPage = lazy(() =>
  import("../../pages/SecurityPage").then(({ SecurityPage }) => ({
    default: compose(
      withAuth0Authentication({ returnTo: paths.security }),
      withRoute({ documentTitleKey: "SecurityPage.Title" }),
      withToastProvider
    )(SecurityPage),
  }))
);

const SignOutPage = lazy(() =>
  import("../../pages/SignOutPage").then(({ SignOutPage }) => ({
    default: withRoute({
      documentTitleKey: "SignOutPage.Title",
    })(SignOutPage),
  }))
);

const TimeOutPage = lazy(() =>
  import("../../pages/TimeOutPage").then(({ TimeOutPage }) => ({
    default: withRoute({ documentTitleKey: "TimeOutPage.Title" })(TimeOutPage),
  }))
);

const UnblockAccountPage = lazy(() =>
  import("../../pages/UnblockAccountPage").then(({ UnblockAccountPage }) => ({
    default: withRoute({ documentTitleKey: "UnblockAccountPage.Title" })(
      UnblockAccountPage
    ),
  }))
);

const VerifyEmailByOtpPage = lazy(() =>
  import("../../pages/VerifyEmailByOtpPage").then(
    ({ VerifyEmailByOtpPage }) => ({
      default: compose(
        withAuth0Authentication({ returnTo: paths.verifyEmailByOtp }),
        withRoute({ documentTitleKey: "VerifyEmailByOtpPage.Title" })
      )(VerifyEmailByOtpPage),
    })
  )
);

const VerifySecurityQuestionPage = lazy(() =>
  import("../../pages/VerifySecurityQuestionPage").then(
    ({ VerifySecurityQuestionPage }) => ({
      default: compose(
        withRoute({ documentTitleKey: "VerifySecurityQuestionPage.Title" })
      )(VerifySecurityQuestionPage),
    })
  )
);

const VerifyUpdatedEmailPage = lazy(() =>
  import("../../pages/VerifyUpdatedEmailPage").then(
    ({ VerifyUpdatedEmailPage }) => ({
      default: withRoute({ documentTitleKey: "VerifyUpdatedEmailPage.Title" })(
        VerifyUpdatedEmailPage
      ),
    })
  )
);

const PasswordChangedPage = lazy(() =>
  import("../../pages/PasswordChangedPage").then(({ PasswordChangedPage }) => ({
    default: withRoute({
      documentTitleKey: "PasswordChangedPage.Title",
    })(PasswordChangedPage),
  }))
);

const prepareRoutes = (routes: Route[]) =>
  routes.map((route) => ({ ...route, path: route.path.replace("/", "") }));

const mfaRoutes = prepareRoutes([
  {
    element: <MfaConfigurationPage />,
    path: paths.mfa.index,
  },
  {
    element: <MfaVerifyAuthGuardianPage />,
    path: paths.mfa.auth0Guardian.verify,
  },
  {
    element: <MfaAddAuthGuardianPage />,
    path: paths.mfa.auth0Guardian.add,
  },
  {
    element: <MfaVerifyTextMessagePage />,
    path: paths.mfa.phone.verifyTextMessage,
  },
  {
    element: <MfaVerifyPhoneCallPage />,
    path: paths.mfa.phone.verifyPhoneCall,
  },
  {
    element: <MfaAddPhonePage />,
    path: paths.mfa.phone.add,
  },
  {
    element: <MfaAddOtpAppPage />,
    path: paths.mfa.otpApp,
  },
  {
    element: <MfaRecoveryCodePage />,
    path: paths.mfa.recoveryCode,
  },
]);

const profileRoutes = prepareRoutes([
  {
    element: <AboutPage />,
    path: paths.about,
  },
  {
    element: <CheckEmailPage />,
    path: paths.checkEmail,
  },
  {
    element: <CustomErrorPage />,
    path: paths.customError,
  },
  {
    element: <EmailVerificationPage />,
    path: paths.verifyNewEmail,
  },
  {
    element: <EmailVerifiedPage />,
    path: paths.emailVerified,
  },
  {
    element: <EmailVerifiedByOtpPage />,
    path: paths.emailVerifiedByOtp,
  },
  {
    element: <EmergencyOtpPage />,
    path: paths.emergencyOtp,
  },
  {
    element: <GenericErrorPage />,
    path: paths.genericError,
  },
  {
    element: <ManageRegKeyPage />,
    path: paths.products.manageRegKey,
  },
  {
    element: <PasswordChangedPage />,
    path: paths.passwordChanged,
  },
  {
    element: <PasswordExpiredPage />,
    path: paths.passwordExpired,
  },
  {
    element: <PasswordWarningPage />,
    path: paths.passwordWarning,
  },
  {
    element: <PersonaSelectionPage />,
    path: paths.personaSelection,
  },
  {
    element: <PersonalInfoPage />,
    path: paths.personalInfo,
  },
  {
    element: <ProductRegKeysPage />,
    path: paths.products.regKeys,
  },
  {
    element: <ProductsPage />,
    path: paths.products.index,
  },
  {
    element: <RegisterProductPage />,
    path: paths.products.registerProduct,
  },
  {
    element: <RegistrationVerifyCodePage />,
    path: paths.verifyNewAccount,
  },
  {
    element: <SecurityPage />,
    path: paths.security,
  },
  {
    element: <SignOutPage />,
    path: paths.signout,
  },
  {
    element: <TimeOutPage />,
    path: paths.timeout,
  },
  {
    element: <UnblockAccountPage />,
    path: paths.unblockAccount,
  },
  {
    element: <VerifyEmailByOtpPage />,
    path: paths.verifyEmailByOtp,
  },
  {
    element: <VerifySecurityQuestionPage />,
    path: paths.verifySecurityQuestion,
  },
  {
    element: <VerifyUpdatedEmailPage />,
    path: paths.verifyUpdatedEmail,
  },
]);

const registrationRoutes = prepareRoutes([
  {
    element: <AccountCreatedPage />,
    path: paths.registration.accountCreated,
  },
  {
    element: <RegistrationPage />,
    path: paths.registration.index,
  },
]);

export const routesWithBase: Route[] = [
  {
    children: [
      {
        element: <DashboardPage />,
        index: true,
      },

      ...mfaRoutes,
      ...profileRoutes,
      ...registrationRoutes,
    ],
    element: <AppContainer />,
    path: paths.base,
  },
  {
    children: [{ element: <NotFoundPage />, path: paths.catchAll }],
    element: <AppContainer />,
    path: paths.catchAll,
  },
];

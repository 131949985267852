// libraries
import { useTranslate } from "@dcl/tools";

// components
import { ChevronDown_2_Icon as ChevronDownIcon } from "../../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { DISPLAY_NAME } from "./constants";

// types
import { AccordionItemProps } from "./AccordionTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const getButtonId = (index: number) => `button-${index}`;
export const getContentId = (index: number) => `content-${index}`;

export const AccordionItem: React.FC<AccordionItemProps> = ({
  data,
  isActive,
  itemIndex,
  onToggle,
}) => {
  const translate = useTranslate();
  const { heading, description, image, imageTypes } = data;

  return (
    <li
      className={getClassName({
        descendantName: "item",
        modifiers: isActive && "open",
      })}
    >
      <button
        aria-controls={getContentId(itemIndex)}
        aria-expanded={isActive}
        className={getClassName("button")}
        id={getButtonId(itemIndex)}
        onClick={onToggle}
      >
        {translate(heading)}
        <ChevronDownIcon />
      </button>
      <div
        aria-hidden={!isActive}
        className={getClassName({
          descendantName: "contentWrapper",
          modifiers: isActive && "open",
        })}
        id={getContentId(itemIndex)}
      >
        <div
          className={getClassName({
            descendantName: "imageWrapper",
            modifiers: imageTypes,
          })}
        >
          {image}
        </div>
        <div className={getClassName("content")}>
          <h3 className={getClassName("contentHeading")}>
            {translate(heading)}
          </h3>
          <p className={getClassName("contentDescription")}>
            {translate(description)}
          </p>
        </div>
      </div>
    </li>
  );
};

AccordionItem.displayName = DISPLAY_NAME;

// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// constants
import { Auth0Endpoints } from "../../../../api";
import {
  ErrorMessages,
  HttpMethods,
  HttpStatusCodes,
} from "../../../../common/constants";

// types
import { ThunkArguments, ThunkConfiguration } from "./deleteFactorTypes";

export const deleteFactor = createAsyncThunk<
  void,
  ThunkArguments,
  ThunkConfiguration
>(
  "deleteMfaFactor/deleteMfaFactor",
  async ({ apiBasePath, id, mfaAccessToken }, { rejectWithValue }) => {
    try {
      const url = `${apiBasePath}/${Auth0Endpoints.GET_FACTORS}/${id}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${mfaAccessToken}`,
        },
        method: HttpMethods.DELETE,
      });

      if (response.status !== HttpStatusCodes.NO_CONTENT) {
        const error = await response.json();

        throw new Error(error?.error_description);
      }

      return;
    } catch (error) {
      const errorMessage =
        error instanceof Error &&
        error.message === ErrorMessages.AUTHENTICATOR_NOT_FOUND
          ? ErrorMessages.AUTHENTICATOR_NOT_FOUND
          : ErrorMessages.DELETE_FACTOR;

      return rejectWithValue({
        message: errorMessage,
      });
    }
  }
);

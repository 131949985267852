// libraries
import { SafText } from "@saffron/core-components/react";
import { useTranslate } from "@dcl/tools";

// components
import { TextButton } from "../../../../../Buttons";

// utilities
import { getClassNameFactory } from "../../../../../../utilities";

// constants
import { AccessibilityClassnames } from "../../../../../../common/constants";
import { DISPLAY_NAME } from "./EnterpriseCodeLabelConstants";
import { safTextAppearance } from "../../../../../../common/constants";

// types
import { CodeLabelProps } from "../../CodeLabelTypes";
import { TextButtonWeightTypes } from "../../../../../Buttons";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const EnterpriseCodeLabel: React.FC<CodeLabelProps> = ({
  codeLength,
  id,
  secondaryLabelText,
  onResendCode,
  resendCodeButtonText,
}) => {
  const translate = useTranslate();

  const secondaryLabel =
    secondaryLabelText ?? translate("VerificationCode.SecondaryLabel");

  return (
    <>
      <SafText
        appearance={safTextAppearance.BODY_STRONG_MD}
        className={getClassName("primaryLabel")}
        id={id}
      >
        {translate("VerificationCode.Label", [codeLength])}
        <SafText className={AccessibilityClassnames.VISUALLY_HIDDEN}>
          {secondaryLabel}
        </SafText>
      </SafText>
      {onResendCode && (
        <TextButton
          onClick={onResendCode}
          weight={TextButtonWeightTypes.NORMAL}
        >
          {resendCodeButtonText ??
            translate("VerificationCode.ResendCodeButton")}
        </TextButton>
      )}
      <SafText aria-hidden={true} className={getClassName("secondaryLabel")}>
        {secondaryLabel}
      </SafText>
    </>
  );
};

EnterpriseCodeLabel.displayName = DISPLAY_NAME;

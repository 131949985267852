// components
import { AuthorizationProvider } from "./AuthorizationProvider";
import { ConfigurationProvider } from "./ConfigurationProvider";
import { FeatureToggleProvider } from "./FeatureToggleProvider";
import { LocalizationProvider } from "./LocalizationProvider";
import { StoreProvider } from "./StoreProvider";
import { TranslationProvider } from "./TranslationProvider";

export const Providers: React.FC = ({ children }) => (
  <ConfigurationProvider>
    <LocalizationProvider>
      <AuthorizationProvider>
        <FeatureToggleProvider>
          <StoreProvider>
            <TranslationProvider>{children}</TranslationProvider>
          </StoreProvider>
        </FeatureToggleProvider>
      </AuthorizationProvider>
    </LocalizationProvider>
  </ConfigurationProvider>
);

// constants
import { HttpMethods, endpoints } from "../common/constants";

// types
import {
  CreateRegistrationKeyArguments,
  DeleteRegistrationKeyArguments,
  GetProductsArguments,
  GetRegistrationKeysArguments,
  Product,
  ProductsView,
  RegistrationKey,
  UpdateRegistrationKeyArguments,
} from "../common/types";

export const ascendingSort = (products: ProductsView[]) => {
  return products.sort((a, b) => a.productName.localeCompare(b.productName));
};

export const descendingSort = (products: ProductsView[]) => {
  return products.sort((a, b) => b.productName.localeCompare(a.productName));
};

export const createProductMap = (productsList: Product[]) => {
  const productMap = new Map<string, string>();

  for (const product of productsList) {
    const key = `${product.code}^${product.name}`;

    productMap.set(key, product.url || "");
  }

  return productMap;
};

export const trimProductUrl = (url: string) => {
  const protocolIndex = url.indexOf("://");

  return protocolIndex !== -1 ? url.slice(protocolIndex + 3) : url;
};

export const categorizeRegistrationKeysByProduct = (
  registrationKeys: RegistrationKey[],
  productMap: Map<string, string>
): ProductsView[] => {
  // First, group the registration keys by product
  const productGroupedMap: { [key: string]: RegistrationKey[] } = {};

  for (const key of registrationKeys) {
    const { productCode, productName } = key;
    const productKey = `${productCode}^${productName}`;

    if (!productGroupedMap[productKey]) {
      productGroupedMap[productKey] = [];
    }

    productGroupedMap[productKey].push(key);
  }

  // Then, transform the product map into an array of products
  const products: ProductsView[] = [];

  for (const productKey in productGroupedMap) {
    const [productCode, productName] = productKey.split("^");
    const productRegistrationKeys = productGroupedMap[productKey];

    const url = productMap.get(productKey) || "";

    products.push({
      productCode,
      productName,
      productUrl: url,
      registrationKeys: productRegistrationKeys,
    });
  }

  return products;
};

export const getRegistrationKeys = async ({
  accessToken,
  apiBasePath,
  euId,
}: GetRegistrationKeysArguments): Promise<{
  data: RegistrationKey[];
  etag: string | null;
}> => {
  const url = endpoints.getRegistrationKeysEndpoint(apiBasePath, euId);

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: HttpMethods.GET,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.headers.get("content-type") === "application/json") {
      throw await response.json();
    }

    throw new Error(
      JSON.stringify({
        errorCode: response.statusText,
        statusCode: response.status,
      })
    );
  }

  const etag = response.headers.get("Etag");
  const data: RegistrationKey[] = await response.json();

  return { data, etag };
};

export const getProducts = async ({
  accessToken,
  apiBasePath,
}: GetProductsArguments): Promise<Product[]> => {
  const url = endpoints.getProductsEndpoint(apiBasePath);

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: HttpMethods.GET,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.headers.get("content-type") === "application/json") {
      throw await response.json();
    }

    throw new Error(
      JSON.stringify({
        errorCode: response.statusText,
        statusCode: response.status,
      })
    );
  }

  return response.json();
};

export const sendCreateRegistrationKeyRequest = async ({
  accessToken,
  apiBasePath,
  euId,
  registrationKey,
}: CreateRegistrationKeyArguments): Promise<RegistrationKey> => {
  const url = endpoints.getUpdateRegistrationKeysEndpoint(apiBasePath, euId);

  const options = {
    body: JSON.stringify(registrationKey),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    method: HttpMethods.POST,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.headers.get("content-type") === "application/json") {
      throw await response.json();
    }

    throw new Error(
      JSON.stringify({
        errorCode: response.statusText,
        statusCode: response.status,
      })
    );
  }

  return response.json();
};

export const sendDeleteRegistrationKeyRequest = async ({
  accessToken,
  apiBasePath,
  euId,
  productCode,
  registrationKey,
}: DeleteRegistrationKeyArguments) => {
  const url = endpoints.getUpdateRegistrationKeysEndpoint(apiBasePath, euId);

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-Product-Code": productCode,
      "X-Registration-Key": registrationKey,
    },
    method: HttpMethods.DELETE,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.headers.get("content-type") === "application/json") {
      throw await response.json();
    }

    throw new Error(
      JSON.stringify({
        errorCode: response.statusText,
        statusCode: response.status,
      })
    );
  }
};

export const sendUpdateRegistrationKeyRequest = async ({
  accessToken,
  apiBasePath,
  euId,
  newKey,
  productCode,
  registrationKey,
}: UpdateRegistrationKeyArguments) => {
  const url = endpoints.getUpdateRegistrationKeysEndpoint(apiBasePath, euId);

  const options = {
    body: JSON.stringify(newKey),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "X-Product-Code": productCode,
      "X-Registration-Key": registrationKey,
    },
    method: HttpMethods.PATCH,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.headers.get("content-type") === "application/json") {
      throw await response.json();
    }

    throw new Error(
      JSON.stringify({
        errorCode: response.statusText,
        statusCode: response.status,
      })
    );
  }

  return response.json();
};

// constants
import {
  ErrorMessages,
  MfaFactorTypes,
  OobChannels,
} from "../../common/constants";

export const mapFactorTypeToToastName = {
  [MfaFactorTypes.AUTH_GUARDIAN]:
    "SecurityPageMfaConfiguration.FactorEnrollmentAuthGuardianToast",
  [MfaFactorTypes.OTP_APP]:
    "SecurityPageMfaConfiguration.FactorEnrollmentOtpAppToast",
  [MfaFactorTypes.SMS]:
    "SecurityPageMfaConfiguration.FactorEnrollmentTextMessageToast",
  [MfaFactorTypes.VOICE]:
    "SecurityPageMfaConfiguration.FactorEnrollmentPhoneCallToast",
};

export const requestPayloadCodeSending = {
  [MfaFactorTypes.VOICE]: {
    errorMessage: ErrorMessages.SEND_CODE_PHONE_CALL,
    oobChannels: [OobChannels.VOICE],
  },
  [MfaFactorTypes.SMS]: {
    errorMessage: ErrorMessages.SEND_CODE_TEXT_MESSAGE,
    oobChannels: [OobChannels.SMS],
  },
};

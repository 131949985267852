export const GearIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1 1)"
    >
      <circle cx="30" cy="30" r="8" stroke="#FA6400" />
      <path
        d="M60 30a4 4 0 00-4-4h-4.381a21.867 21.867 0 00-3.499-8.464l3.093-3.093a4 4 0 00-5.657-5.657l-3.093 3.093a21.867 21.867 0 00-8.464-3.499V4a4 4 0 00-8 0v4.381a21.867 21.867 0 00-8.464 3.499l-3.093-3.093a4 4 0 00-5.657 5.657l3.093 3.093a21.867 21.867 0 00-3.499 8.464H4a4 4 0 000 8h4.381a21.867 21.867 0 003.499 8.464l-3.093 3.093a4 4 0 005.657 5.657l3.093-3.093a21.867 21.867 0 008.464 3.499V56a4 4 0 008 0v-4.381a21.867 21.867 0 008.464-3.499l3.093 3.093a4 4 0 005.657-5.657l-3.093-3.093a21.867 21.867 0 003.499-8.464H56c2.209.001 4-1.79 4-3.999z"
        stroke="#404040"
      />
    </g>
  </svg>
);

// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { sendCode } from "./sendCodeThunk";

// types
import { ReducerState } from "./sendCodeTypes";

const initialState: ReducerState = {
  error: null,
};

export const sendCodeSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(sendCode.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
  initialState,
  name: "sendCode",
  reducers: {},
});

// libraries
import classNames from "classnames";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./SectionConstants";

// types
import { SectionProps } from "./SectionTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Section: React.FC<SectionProps> = ({ children, className }) => {
  return (
    <div className={classNames(getClassName(), className)}>{children}</div>
  );
};

Section.displayName = DISPLAY_NAME;

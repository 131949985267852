// libraries
import { Middleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

// utilities
import { dataDogMiddleware } from "../analytics";
import { rootReducer } from "./rootReducer";

// constants;
import { IS_DEV } from "../env";

const middlewares: Middleware[] = [dataDogMiddleware];

if (IS_DEV) {
  const logger = createLogger({ collapsed: true });

  middlewares.push(logger);
}

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
  reducer: rootReducer,
});

// libraries
import classNames from "classnames";
import { SafButton } from "@saffron/core-components/react";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { CodeInput, CodeLabel } from "./components";
import { PrimaryButton, TextButton } from "..";

// utilities
import { codeVerifiedWithError, formValidatedWithError } from "../../analytics";
import {
  getClassNameFactory,
  isExpiredOtpCodeError,
  isExpiredPhoneOtpCodeError,
  isFieldValidationError,
  isInvalidBindingCodeError,
  isInvalidOtpCodeError,
} from "../../utilities";
import {
  getDefaultVerificationCode,
  getVerificationCodeValidationRules,
} from "./VerificationCodeUtilities";
import { useVerificationCodeInputValidation, validateForm } from "../../hooks";

// constants
import {
  CODE_LABEL_ID,
  DEFAULT_CODE_LENGTH,
  DISPLAY_NAME,
  RequiredFields,
} from "./VerificationCodeConstants";
import { TextButtonWeightTypes } from "../Buttons";

// types
import { CodeInputRef, CodeLabelProps } from "./components";
import { FieldValidationList } from "../../hooks";
import { VerificationCodeProps } from "./VerificationCodeTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const VerificationCode: React.FC<VerificationCodeProps> = ({
  cancelButtonText,
  className,
  codeLength = DEFAULT_CODE_LENGTH,
  onCancel,
  onResendCode,
  onVerifyCode,
  resendCodeButtonText,
  secondaryLabelText,
  submitButtonText,
  isEnterprise,
}) => {
  const codeInputRef = useRef<CodeInputRef>(null);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const verificationCode = useVerificationCodeInputValidation({
    defaultInputValue: getDefaultVerificationCode(codeLength),
    validationRules: getVerificationCodeValidationRules(codeLength),
  });

  const handleResend = () => {
    if (onResendCode) {
      onResendCode();
      verificationCode.reset();
    }
  };

  const codeLabelProps: CodeLabelProps = {
    codeLength,
    id: CODE_LABEL_ID,
    isEnterprise,
    onResendCode: onResendCode && handleResend,
    resendCodeButtonText,
    secondaryLabelText,
  };

  const validationList: FieldValidationList<string[]> = [
    {
      fieldValidation: verificationCode,
      id: RequiredFields.VERIFICATION_CODE,
    },
  ];

  const focusErrorField = () => codeInputRef.current?.focus();

  const handleSubmit = async () => {
    try {
      validateForm(validationList);

      await onVerifyCode(verificationCode.value.join(""));
    } catch (error) {
      if (isFieldValidationError(error)) {
        dispatch(await formValidatedWithError(error.errorMessages));

        focusErrorField();

        return;
      }

      if (isExpiredOtpCodeError(error)) {
        verificationCode.displayExpiredVerificationCodeError();

        focusErrorField();

        dispatch(codeVerifiedWithError(error.message));

        return;
      }

      if (isExpiredPhoneOtpCodeError(error)) {
        verificationCode.displayExpiredPhoneVerificationCodeError();

        focusErrorField();

        dispatch(codeVerifiedWithError(error.message));

        return;
      }

      if (isInvalidOtpCodeError(error) || isInvalidBindingCodeError(error)) {
        verificationCode.displayInvalidVerificationCodeError();

        focusErrorField();

        dispatch(codeVerifiedWithError(error.message));

        return;
      }
    }
  };

  return (
    <div className={classNames(getClassName(), className)}>
      <CodeInput
        ariaLabelledBy={CODE_LABEL_ID}
        code={verificationCode.value}
        errorMessage={verificationCode.errorMessage}
        id={RequiredFields.VERIFICATION_CODE}
        isEnterprise={isEnterprise}
        label={<CodeLabel {...codeLabelProps} />}
        onBlur={() => verificationCode.validate()}
        onChange={verificationCode.change}
        ref={codeInputRef}
      />
      {isEnterprise ? (
        <div className={getClassName("buttons")}>
          {onCancel && (
            <SafButton
              appearance="secondary"
              className={getClassName("cancelButton")}
              onClick={onCancel}
            >
              {cancelButtonText ?? translate("Button.Cancel")}
            </SafButton>
          )}
          <SafButton
            appearance="primary"
            className={getClassName("verifyButton")}
            onClick={handleSubmit}
          >
            {submitButtonText ?? translate("Button.Verify")}
          </SafButton>
        </div>
      ) : (
        <div className={getClassName("buttons")}>
          <PrimaryButton onClick={handleSubmit}>
            {submitButtonText ?? translate("Button.Verify")}
          </PrimaryButton>
          {onCancel && (
            <TextButton
              onClick={onCancel}
              weight={TextButtonWeightTypes.NORMAL}
            >
              {cancelButtonText ?? translate("Button.Cancel")}
            </TextButton>
          )}
        </div>
      )}
    </div>
  );
};

VerificationCode.displayName = DISPLAY_NAME;

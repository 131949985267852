// libraries
import { SafButton, SafIcon } from "@saffron/core-components/react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getClassNameFactory } from "../../utilities";
import { useNavigation } from "../../hooks";

// constants
import { DISPLAY_NAME } from "./RegisterProductButtonConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const RegisterProductButton: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  const translate = useTranslate();
  const { paths, redirect } = useNavigation();

  const handleOnClick = () => {
    onClick?.();
    redirect(paths.products.registerProduct);
  };

  return (
    <SafButton className={getClassName()} onClick={handleOnClick}>
      <SafIcon appearance="light" iconName="plus" size={16} slot="start" />
      {translate("ProductsPage.RegisterProduct")}
    </SafButton>
  );
};

// libraries
import classNames from "classnames";
import { useTranslate } from "@dcl/tools";

// components
import { BackButton, HeadingSection, PrimaryButton, TextButton } from "..";

// utilities
import { getClassNameFactory } from "../../utilities";
import { useNavigation } from "../../hooks";

// constants
import { DISPLAY_NAME } from "./MfaPageConstants";
import { IconPosition } from "../../components/HeadingSection";
import { TextButtonWeightTypes } from "../Buttons";

// types
import { MfaPageProps } from "./MfaPageTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const MfaPage: React.FC<MfaPageProps> = ({
  children,
  className,
  customButtons,
  heading,
  icon,
  onBack,
  subheading,
  backButtonText,
  onSubmit,
  submitButtonText,
}) => {
  const translate = useTranslate();
  const { paths, redirect } = useNavigation();

  const handleCancel = () => redirect(paths.mfa.index);

  return (
    <div className={classNames(getClassName(), className)}>
      <BackButton onClick={onBack} text={backButtonText} />
      <HeadingSection
        className={getClassName("heading")}
        heading={heading}
        icon={icon}
        iconPosition={IconPosition.SUBHEADING}
        subheading={subheading}
      />
      <div className={getClassName("content")}>{children}</div>
      {customButtons ? (
        <div className={getClassName("buttons")}>{customButtons}</div>
      ) : (
        submitButtonText && (
          <div className={getClassName("buttons")}>
            <PrimaryButton onClick={onSubmit}>{submitButtonText}</PrimaryButton>
            <TextButton
              className={getClassName("cancelButton")}
              onClick={handleCancel}
              weight={TextButtonWeightTypes.NORMAL}
            >
              {translate("Button.Cancel")}
            </TextButton>
          </div>
        )
      )}
    </div>
  );
};

MfaPage.displayName = DISPLAY_NAME;

export const safDividerOrientation = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
} as const;

export const safDividerRole = {
  PRESENTATION: "presentation",
  SEPARATOR: "separator",
} as const;

export const safTextAppearance = {
  BODY_DEFAULT_LG: "body-default-lg",
  BODY_DEFAULT_MD: "body-default-md",
  BODY_DEFAULT_SM: "body-default-sm",
  BODY_STRONG_LG: "body-strong-lg",
  BODY_STRONG_MD: "body-strong-md",
  BODY_STRONG_SM: "body-strong-sm",
  HEADING_2XL: "heading-2xl",
  HEADING_3XL: "heading-3xl",
  HEADING_4XL: "heading-4xl",
  HEADING_MD: "heading-md",
  HEADING_XL: "heading-xl",
} as const;

export type SafDividerOrientation =
  typeof safDividerOrientation[keyof typeof safDividerOrientation];
export type SafDividerRole = typeof safDividerRole[keyof typeof safDividerRole];
export type SafTextAppearance =
  typeof safTextAppearance[keyof typeof safTextAppearance];

// libraries
import classNames from "classnames";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { ButtonTypes } from "../../../common/constants";
import { DISPLAY_NAME } from "./TextButtonConstants";

// types
import { TextButtonProps, TextButtonWeightTypes } from "./TextButtonTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const TextButton: React.FC<TextButtonProps> = ({
  children,
  onClick,
  className = "",
  weight = TextButtonWeightTypes.BOLD,
}) => (
  <button
    className={getClassName({
      className,
      modifiers: classNames(weight),
    })}
    onClick={onClick}
    type={ButtonTypes.BUTTON}
  >
    {children}
  </button>
);

TextButton.displayName = DISPLAY_NAME;

// libraries
import {
  SafAnchor,
  SafButton,
  SafIcon,
  SafText,
} from "@saffron/core-components/react";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "@dcl/tools";

// components
import { HeadingSection } from "../../components";
import { LocalizationContext } from "../../providers/LocalizationProvider";

// utilities
import { getBasePath } from "../../utilities";
import { getClassNameFactory } from "../../utilities";
import { pageViewed } from "../../analytics";
import { useNavigation } from "../../hooks";

// constants
import { CORRECT_BASE_PATH, DISPLAY_NAME } from "./NotFoundPageConstants";
import { safTextAppearance } from "../../common/constants";

// types
import { Dispatch } from "../../redux";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const NotFoundPage: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const translate = useTranslate();
  const { locale } = useContext(LocalizationContext);
  const { navigate, paths, redirect } = useNavigation();

  const basePath = getBasePath(locale);
  const isCorrectPath = CORRECT_BASE_PATH.test(window.location.pathname);

  useEffect(() => {
    dispatch(pageViewed(paths.catchAll));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () =>
    isCorrectPath ? redirect(paths.profile) : navigate(basePath);

  return (
    <div className={getClassName()}>
      <div className={getClassName("content")}>
        <SafIcon appearance="light" iconName="square-exclamation" size={45} />
        <HeadingSection
          className={getClassName("heading")}
          heading={translate("NotFoundPage.Heading")}
          subheading={translate("NotFoundPage.Subheading")}
          isEnterprise
        />
        <SafButton className={getClassName("button")} onClick={handleClick}>
          {translate("NotFoundPage.ButtonText")}
        </SafButton>
        <SafText
          appearance={safTextAppearance.BODY_DEFAULT_LG}
          className={getClassName("linksIntroText")}
        >
          {translate("NotFoundPage.LinksHeading")}
        </SafText>
        <SafAnchor onClick={() => redirect(paths.about)}>
          {translate("NotFoundPage.Link1Text")}
        </SafAnchor>
        <SafAnchor href={translate("Link.HelpSupport")} target="_blank">
          {translate("NotFoundPage.Link2Text")}
          <SafIcon
            className={getClassName("linkIcon")}
            iconName="up-right-from-square"
            size={12}
          />
        </SafAnchor>
      </div>
    </div>
  );
};

export const LaptopIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 15.75v-12a1.5 1.5 0 0 0-1.5-1.5h-15A1.5 1.5 0 0 0 3 3.75v12"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M5.625 4.875h12.75v8.25H5.625z"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      clipRule="evenodd"
      d="M21 15.75H3l-1.87 4.987a.75.75 0 0 0 .702 1.013h20.336a.75.75 0 0 0 .702-1.013L21 15.75Z"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

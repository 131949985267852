// libraries
import {
  SafCheckbox,
  SafProgressRing,
  SafText,
} from "@saffron/core-components/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { CopyCodeButton } from "../CopyCodeButton";
import { EnterpriseDialog } from "../../../../components";

// utilities
import {
  FieldValidationList,
  useCheckboxValidation,
  useNavigation,
  validateForm,
} from "../../../../hooks";
import {
  focusOnAndScrollToField,
  getClassNameFactory,
  isFieldValidationError,
} from "../../../../utilities";
import { formValidatedWithError } from "../../../../analytics";
import { useGetRecoveryCode } from "./RecoveryCodeDialogHooks";

// constants
import { CHECKBOX_ID } from "../../../../components/Checkbox/CheckboxConstants";
import { DISPLAY_NAME } from "./RecoveryCodeDialogConstants";
import {
  DatadogPrivacyTypes,
  DialogsIds,
  TwoStepVerificationCustomErrorCodes,
  safTextAppearance,
} from "../../../../common/constants";

// types
import { Dispatch, State } from "../../../../redux";
import { RecoveryCodeDialogProps } from "./RecoveryCodeDialogTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const RecoveryCodeDialog: React.FC<RecoveryCodeDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const checkbox = useCheckboxValidation({
    key: "Validation.RecoveryCodeError",
  });
  const dispatch = useDispatch<Dispatch>();
  const enrolledRecoveryCode = useSelector<State, string>(
    (state) => state.completeFactorEnrollment.enrolledRecoveryCode
  );
  const getRecoveryCode = useGetRecoveryCode();
  const translate = useTranslate();
  const { paths, redirect } = useNavigation();

  useEffect(() => {
    const shouldRegenerateRecoveryCode = isOpen && !enrolledRecoveryCode;

    if (shouldRegenerateRecoveryCode) {
      const regenerateRecoveryCode = async () => {
        try {
          await getRecoveryCode();
        } catch {
          // redirect to custom error page with regenerate recovery code error state
          redirect(paths.customError, {
            errorCode: TwoStepVerificationCustomErrorCodes.MF0011,
          });
        }
      };

      regenerateRecoveryCode();
    }
  }, [
    getRecoveryCode,
    paths.customError,
    enrolledRecoveryCode,
    redirect,
    isOpen,
  ]);

  const validationList: FieldValidationList<boolean> = [
    {
      fieldValidation: checkbox,
      id: CHECKBOX_ID,
    },
  ];

  const handleClose = () => {
    checkbox.reset();
    onClose();
  };

  const handleSubmit = async () => {
    try {
      validateForm(validationList);
      checkbox.reset();
      onSubmit();
    } catch (error) {
      if (isFieldValidationError(error)) {
        dispatch(await formValidatedWithError(error.errorMessages));
        focusOnAndScrollToField(error.fieldId);
      }
    }
  };

  const dialogBody = (
    <>
      {!enrolledRecoveryCode ? (
        <SafProgressRing />
      ) : (
        <>
          <p className={getClassName("recoveryCodeInfo")}>
            <SafText
              appearance={safTextAppearance.BODY_STRONG_MD}
              data-dd-privacy={DatadogPrivacyTypes.MASK}
            >
              {enrolledRecoveryCode}
            </SafText>
            <span className={getClassName("copyButtonWrapper")}>
              <CopyCodeButton code={enrolledRecoveryCode} />
            </span>
          </p>
          <SafCheckbox
            checked={checkbox.value}
            className={getClassName("checkbox")}
            invalid={!!checkbox.errorMessage}
            onChange={checkbox.change}
            validationMessage={checkbox.errorMessage as string}
          >
            {translate("SecurityPageRecoveryCodeDialog.CheckboxLabel")}
          </SafCheckbox>
        </>
      )}
    </>
  );

  return (
    <EnterpriseDialog
      dialogSubtitle={translate("SecurityPageRecoveryCodeDialog.Description")}
      dialogTitle={translate("SecurityPageRecoveryCodeDialog.Heading")}
      id={DialogsIds.RECOVERY_CODE}
      isOpen={isOpen}
      onClose={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonText={translate(
        "SecurityPageRecoveryCodeDialog.SubmitButton"
      )}
    >
      {dialogBody}
    </EnterpriseDialog>
  );
};

RecoveryCodeDialog.displayName = DISPLAY_NAME;

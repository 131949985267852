export enum MaximumLengths {
  ANSWER = 20,
  EMAIL_USER_PART = 64,
  EMAIL_DOMAIN_PART = 63,
  EMAIL = 254,
  NAME = 150,
  OTP_APP_NAME = 100,
  REG_KEY = 100,
  VERIFICATION_CODE = 6,
}

export enum MinimumLengths {
  ANSWER = 4,
  PASSWORD = 8,
}

export const REQUIRED_PASSWORD_SCORE = 3;

// checks presense of minimum 4 alphanumeric characters

export const ANSWER_PATTERN = "^(?:[^\\p{L}\\p{N}]*[\\p{L}\\p{N}]){4}";

export const EMAIL_PATTERN =
  "^\\s*[a-zA-ZÀ-ž0-9!#$%&'*+\\/=?^_‘{|}~-]+(?:\\.[a-zA-ZÀ-ž0-9!#$%&'*+\\/=?^_‘{|}~-]+)*@(?:[a-zA-ZÀ-ž0-9](?:[a-zA-ZÀ-ž0-9-]*[a-zA-ZÀ-ž0-9])?\\.)+[a-zA-ZÀ-ž]{2,}\\s*$";

// symbols in unicode because of invalid pattern error in FF: ?|\/[]{}"()'+
export const PASSWORD_PATTERN =
  "^\\s*[a-zA-ZÀ-ž0-9!#$%&*,-.:;<=>@^_`~\\u005B\\u005D\\u003F\\u007C\\u007B\\u007D\\u0028\\u0029\\u002B\\u0022\\u0027\\u005C\\u002F\\s]*\\s*$";

export const answerRequirements = {
  errors: {
    maximumLengthExceeded: {
      key: "Validation.AnswerMaxLengthError",
      replacements: [MaximumLengths.ANSWER],
    },
    minimumLengthIsNotReached: {
      key: "Validation.AnswerMinimumLengthError",
      replacements: [MinimumLengths.ANSWER],
    },
    patternMismatch: {
      key: "Validation.AnswerPatternMismatchError",
    },
    valueMissing: {
      key: "Validation.AnswerMissingError",
    },
  },
  maximumLength: MaximumLengths.ANSWER,
  minimumLength: MinimumLengths.ANSWER,
  pattern: ANSWER_PATTERN,
};

export const basicEmailRequirements = {
  errors: {
    emailExists: {
      key: "EnterpriseValidation.EmailExistsError",
    },
    emailMissing: {
      key: "Validation.EmailMissingError",
    },
    maximumLengthExceeded: {
      key: "Validation.EmailMaximumLengthExceededError",
    },
    patternMismatch: {
      key: "Validation.EmailMismatchError",
    },
  },
  maximumDomainPartLength: MaximumLengths.EMAIL_DOMAIN_PART,
  maximumUserPartLength: MaximumLengths.EMAIL_USER_PART,
  pattern: EMAIL_PATTERN,
};

export const firstNameRequirements = {
  errors: {
    maximumLengthExceeded: {
      key: "Validation.FirstNameMaxLengthError",
      replacements: [MaximumLengths.NAME],
    },
    valueMissing: {
      key: "Validation.FirstNameMissingError",
    },
  },
  maximumLength: MaximumLengths.NAME,
};

export const lastNameRequirements = {
  errors: {
    maximumLengthExceeded: {
      key: "Validation.LastNameMaxLengthError",
      replacements: [MaximumLengths.NAME],
    },
    valueMissing: {
      key: "Validation.LastNameMissingError",
    },
  },
  maximumLength: MaximumLengths.NAME,
};

export const otpAppNameRequirements = {
  errors: {
    maximumLengthExceeded: {
      key: "Validation.OtpAppNameMaxLengthError",
      replacements: [MaximumLengths.OTP_APP_NAME],
    },
    valueMissing: {
      key: "Validation.OtpAppNameMissingError",
    },
  },
  maximumLength: MaximumLengths.OTP_APP_NAME,
};

export const passwordRequirements = {
  errors: {
    commonPassword: {
      key: "Validation.CommonPasswordError",
    },
    matchingCurrentPassword: {
      key: "Validation.MatchingCurrentPasswordError",
    },
    minimumLengthIsNotReached: {
      key: "Validation.MinimumLengthIsNotReachedError",
      replacements: [MinimumLengths.PASSWORD],
    },
    passwordConfirmationMissing: {
      key: "Validation.PasswordConfirmationMissingError",
    },
    passwordContainsUserInformation: {
      key: "Validation.PasswordContainsUserInformation",
    },
    passwordInHistory: {
      key: "Validation.PasswordInHistory",
    },
    passwordMissing: {
      key: "Validation.PasswordMissingError",
    },
    passwordsMismatch: {
      key: "Validation.PasswordsMismatchError",
    },
    patternMismatch: {
      key: "Validation.PasswordPatternMismatchError",
    },
    weakPassword: {
      key: "Validation.WeakPasswordError",
    },
  },
  minimumLength: MinimumLengths.PASSWORD,
  pattern: PASSWORD_PATTERN,
};

export const phoneNumberErrors = {
  invalidPhoneNumber: {
    key: "Validation.InvalidPhoneNumberError",
  },
  phoneNumberMissing: {
    key: "Validation.PhoneNumberMissingError",
  },
};

export const productNameRequirements = {
  errors: {
    valueMissing: {
      key: "Validation.ProductNameMissingError",
    },
  },
};

export const regKeyNameRequirements = {
  errors: {
    maximumLengthExceeded: {
      key: "Validation.RegKeyMaxLengthError",
      replacements: [MaximumLengths.REG_KEY],
    },
    valueMissing: {
      key: "Validation.RegKeyNameMissingError",
    },
  },
  maximumLength: MaximumLengths.REG_KEY,
};

export const regKeyRequirements = {
  errors: {
    maximumLengthExceeded: {
      key: "Validation.RegKeyMaxLengthError",
      replacements: [MaximumLengths.REG_KEY],
    },
    valueMissing: {
      key: "Validation.RegKeyMissingError",
    },
  },
  maximumLength: MaximumLengths.REG_KEY,
};

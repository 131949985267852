// libraries
import { useTranslate } from "@dcl/tools";

//components
import { CopyIcon } from "../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../utilities";
import { useNavigation } from "../../../hooks";

// constants
import {
  ButtonTypes,
  GeneralConfigurationErrorCodes,
} from "../../../common/constants";
import { DISPLAY_NAME } from "./CopyButtonConstants";

// types
import { CopyButtonProps } from "./CopyButtonTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const CopyButton: React.FC<CopyButtonProps> = ({ code, handleCopy }) => {
  const translate = useTranslate();
  const { paths, redirect } = useNavigation();

  // Copies code prop to the device clipboard.
  const copyCode = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(code);
      } else {
        window["clipboardData"].setData("Text", code);
      }

      handleCopy();
    } catch (error) {
      redirect(paths.customError, {
        errorCode: GeneralConfigurationErrorCodes.GC0004,
      });
    }
  };

  return (
    <button
      aria-label={translate("CopyButton.Text")}
      className={getClassName("button")}
      onClick={copyCode}
      type={ButtonTypes.BUTTON}
    >
      <span className={getClassName("content")} aria-hidden>
        <span className={getClassName("icon")}>
          <CopyIcon />
        </span>
        {translate("CopyButton.Text")}
      </span>
    </button>
  );
};

CopyButton.displayName = DISPLAY_NAME;

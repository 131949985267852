// libraries
import classNames from "classnames";

// utilities
import { getClassNameFactory, getErrorId } from "../../../utilities";

// constants
import { DISPLAY_NAME } from "../FormInputConstants";

// types
import { InputProps } from "../FormInputTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Input: React.FC<InputProps> = ({
  id,
  inputModifier,
  isErrorVisible,
  onBlur,
  onChange,
  value,
  type,
  ...props
}) => (
  <input
    aria-describedby={getErrorId(id)}
    className={getClassName({
      className: inputModifier,
      descendantName: "input",
      modifiers: classNames(isErrorVisible && "isInvalid"),
    })}
    id={id}
    onBlur={onBlur}
    onChange={onChange}
    type={type}
    value={value}
    {...props}
  />
);

export const VerifiedPhoneIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 76 69" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <circle cx="12" cy="12" id="a" r="12" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(47.462 40)">
        <circle
          cx="12"
          cy="12"
          fill="#FFF"
          r="14.25"
          stroke="#FFF"
          strokeWidth="4.5"
        />
        <circle cx="12" cy="12" r="13" stroke="#666" strokeWidth="2" />
      </g>
      <path
        d="M55.337 52.19l2.647 2.773 6.016-6.3"
        stroke="#666"
        strokeLinecap="round"
        strokeWidth="1.575"
      />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(11 2)"
      >
        <circle cx="21" cy="51" r="3" stroke="#FA6400" />
        <path d="M0 10h42M0 42h42" stroke="#FA6400" />
        <path
          d="M38 60H4a4 4 0 01-4-4V4a4 4 0 014-4h34a4 4 0 014 4v52a4 4 0 01-4 4z"
          stroke="#404040"
        />
      </g>
      <g>
        <g transform="translate(47 40)">
          <circle
            cx="12"
            cy="12"
            fill="#FA6400"
            r="9.75"
            stroke="#FA6400"
            strokeLinejoin="miter"
            strokeWidth="4.5"
          />
          <circle cx="12" cy="12" r="14.25" stroke="#FFF" strokeWidth="4.5" />
        </g>
        <path
          d="M54.875 52.19l2.647 2.773 6.016-6.3"
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth="1.575"
        />
      </g>
    </g>
  </svg>
);

// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { enrollFactor } from "../../factors/enrollFactor";

// types
import {
  RequestPayload,
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./sendCodeTypes";

export const sendCode = createAsyncThunk<
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration
>(
  "factor/sendCode",
  async (
    { apiBasePath, errorMessage, locale, mfaToken, requestPayload },
    { rejectWithValue }
  ) => {
    try {
      return await enrollFactor<RequestPayload, ResponsePayload>({
        apiBasePath,
        locale,
        mfaToken,
        requestPayload,
      });
    } catch (error) {
      return rejectWithValue({ message: errorMessage });
    }
  }
);

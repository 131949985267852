// libraries
import classNames from "classnames";
import { SafText } from "@saffron/core-components/react";

// components
import { Heading } from "../../.";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { DISPLAY_NAME, IconPosition } from "../HeadingSectionConstants";
import {
  DatadogPrivacyTypes,
  safTextAppearance,
} from "../../../common/constants";
import { HeadingRank } from "../../Heading";

// types
import { HeadingSectionProps } from "../HeadingSectionTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const EnterpriseHeadingSection: React.FC<HeadingSectionProps> = ({
  className,
  ddPrivacyType = DatadogPrivacyTypes.ALLOW,
  heading,
  headingRank = HeadingRank.First,
  headingType = safTextAppearance.HEADING_4XL,
  icon,
  iconPosition = IconPosition.HEADING,
  subheading,
  subheadingType = safTextAppearance.BODY_DEFAULT_LG,
}) => (
  <div
    className={classNames(
      getClassName({
        modifiers: classNames({
          enterprise: true,
          [iconPosition]: true,
        }),
      }),
      className
    )}
  >
    {icon && <div className={getClassName("icon")}>{icon}</div>}
    <Heading
      ddPrivacyType={ddPrivacyType}
      rank={headingRank}
      type={headingType}
      isEnterprise
    >
      {heading}
    </Heading>
    <p className={getClassName("subheading")}>
      <SafText appearance={subheadingType}>{subheading}</SafText>
    </p>
  </div>
);

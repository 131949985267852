// libraries
import { useEffect } from "react";

// utilities
import { useGetMfaFactors, useGetMfaStatus, useNavigation } from "../";

// constants
import { TwoStepVerificationCustomErrorCodes } from "../../common/constants";

/**
 * @description Uploads MFA data. It uses `useDeleteInactiveFactors`, `useGetMfaFactors`, and `useGetMfaStatus` hooks to get
 * the necessary data and then delete any inactive factors.
 * @handlesError Redirects to Generic Error page.
 */
export const useUploadMfaData = () => {
  const getMfaFactors = useGetMfaFactors();
  const getMfaStatus = useGetMfaStatus();
  const { paths, redirect } = useNavigation();

  useEffect(() => {
    const uploadMfaData = async () => {
      try {
        await getMfaStatus();
        await getMfaFactors();
      } catch {
        redirect(paths.customError, {
          errorCode: TwoStepVerificationCustomErrorCodes.MF0003,
        });
      }
    };

    uploadMfaData();
  }, [getMfaStatus, getMfaFactors, redirect, paths.customError]);
};

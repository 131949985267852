// types
import { RawFactor } from "../../redux";

/**
 * @description Checks if MFA factor is enabled.
 *
 * @param factor - Raw MFA factor returned from backend.
 *
 * @returns True is factor is active, false if it's not.
 */

export const isActiveFactor = (factor: RawFactor) => factor.active;

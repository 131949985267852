// library
import { useTranslate } from "@dcl/tools";

// components
import { HeaderLogoIcon } from "../../../../../common/icons";
import { Navigation } from "../../Navigation/";

// utilities
import { getClassNameFactory } from "../../../../../utilities";

// constants
import { ENTERPRISE_DISPLAY_NAME } from "../HeaderConstants";
import { RoleTypes } from "../../../../../common/constants";

const getClassName = getClassNameFactory(ENTERPRISE_DISPLAY_NAME);

export const DesktopHeader: React.FC = () => {
  const translate = useTranslate();

  return (
    <div className={getClassName({ modifiers: "desktop" })}>
      <div
        className={getClassName("headerWrapper")}
        tasks-aria-label={translate("Header.ProductName")}
      >
        <div className={getClassName("logoWrapper")}>
          <HeaderLogoIcon />
          <span
            className={getClassName("separator")}
            role={RoleTypes.PRESENTATION}
          >
            |
          </span>
          <div className={getClassName("productName")}>
            {translate("Header.ProductName")}
          </div>
        </div>
        <div className={getClassName("navigation")} slot="global">
          <Navigation />
        </div>
      </div>
    </div>
  );
};

export const USERS_GROUP_KEY = "users";

export enum FeatureStatuses {
  OFF = "off",
  ON = "on",
}

export enum FeatureNames {
  ADD_OPT_APP_TOOLTIP = "ADO-769274_Using_Saffron_tooltip_component",
  DATADOG_RUM = "ADO-725732_datadog_rum_integration",
  DATADOG_RUM_SESSION_REPLAY = "ADO-725732_datadog_rum_session_replay",
  DISPLAY_AVATAR = "DUE-15656_avatar",
  DISPLAY_CURRENT_PASSWORD_FIELD = "DUE-1623_current_password_field_in_password_editor",
  ENABLE_PHONE_CALL = "DUE-28468_update_add_phone_section_remove_phone_call_option",
  LANGUAGE_SUPPORT = "ADO-159840_onePass_language_support",
  MANAGING_OTP_NAME = "ADO-745492_managing_otp_authenticator_name",
  PROFILE_PAGE_NEW_FLOW = "DUE-21008-Enterprise_Profile_redesign",
  REG_KEY_MANAGEMENT = "ADO-153747_reg_key_management",
  SECURITY_QUESTION = "ADO-152472_security_question",
}

// libraries
import { useCallback } from "react";

// utilities
import { getFactorsRequest } from "../../utilities/mfa";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { useGetAccessToken, useGetMfaAccessToken, useUser } from "..";

// constants
import {
  AuthenticatorTypes,
  ErrorMessages,
  HttpMethods,
  endpoints,
} from "../../common/constants";

export const useUpdateOtpAppName = () => {
  const {
    auth0: { domain: apiBasePath },
    ciam: { basePath },
  } = useConfiguration();
  const { euId } = useUser();
  const { getAccessToken } = useGetAccessToken();
  const getMfaAccessToken = useGetMfaAccessToken();

  const getOtpAppFactor = useCallback(async () => {
    const mfaAccessToken = await getMfaAccessToken();
    const { rawFactors } = await getFactorsRequest({
      apiBasePath,
      mfaAccessToken,
    });

    return rawFactors.find(
      (factor) => factor.authenticator_type === AuthenticatorTypes.OTP
    );
  }, [apiBasePath, getMfaAccessToken]);

  return useCallback(
    async (newName: string) => {
      const [accessToken, factor] = await Promise.all([
        getAccessToken(),
        getOtpAppFactor(),
      ]);

      if (!factor) {
        throw new Error(ErrorMessages.FACTOR_NOT_FOUND);
      }

      const url = endpoints.getUpdateAuthenticationMethodEndpoint(
        basePath,
        euId,
        factor.id
      );
      const options = {
        body: JSON.stringify({ name: newName }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: HttpMethods.PATCH,
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        const error = await response.json();

        throw new Error(error.message ?? error.error);
      }

      return response.json();
    },
    [basePath, euId, getAccessToken, getOtpAppFactor]
  );
};

// constants
import {
  HttpStatusCodes,
  ProfileManagementCustomErrorCodes,
  ProfileManagementErrorMessages,
} from "../../common/constants";
import { UpdateUserErrorCodes } from "./constants";

// types
import { CustomError } from "../../common/types";

const isAccessDeniedError = (error: any) =>
  error.statusCode === HttpStatusCodes.FORBIDDEN &&
  error.errorCode === UpdateUserErrorCodes.ACCESS_DENIED;

const isPayloadValidationError = (error: any) =>
  error.statusCode === HttpStatusCodes.BAD_REQUEST &&
  error.errorCode === UpdateUserErrorCodes.INVALID_BODY;

const isServerError = (error: any) =>
  error.statusCode === HttpStatusCodes.ERROR;

const isUserNotFound = (error: any) =>
  error.statusCode === HttpStatusCodes.NOT_FOUND &&
  error.errorCode === UpdateUserErrorCodes.INEXISTENT_USER;

export const getCustomProfileManagementError = (
  error: unknown
): CustomError | void => {
  if (isUserNotFound(error)) {
    return {
      customErrorCode: ProfileManagementCustomErrorCodes.PM0004,
      message: ProfileManagementErrorMessages.INEXISTENT_USER,
    };
  }

  if (isPayloadValidationError(error)) {
    return {
      customErrorCode: ProfileManagementCustomErrorCodes.PM0001,
      message: ProfileManagementErrorMessages.PAYLOAD_VALIDATION,
    };
  }

  if (isAccessDeniedError(error)) {
    return {
      customErrorCode: ProfileManagementCustomErrorCodes.PM0003,
      message: ProfileManagementErrorMessages.ACCESS_DENIED,
    };
  }

  if (isServerError(error)) {
    return {
      customErrorCode: ProfileManagementCustomErrorCodes.PM0005,
      message: ProfileManagementErrorMessages.SERVER,
    };
  }
};

export const DISPLAY_NAME = "RelatedProducts";

export const securityMethods = [
  {
    description: "RelatedProducts.MfaMethodDescription",
    heading: "RelatedProducts.MfaMethodHeading",
    linkText: "RelatedProducts.MfaMethodLink",
    linkUrl:
      "https://www.thomsonreuters.com/en-us/help/account-management/thomson-reuters-account/two-factor-authentication-thomson-reuters-account.html",
  },
  {
    description: "RelatedProducts.BotMethodDescription",
    heading: "RelatedProducts.BotMethodHeading",
    linkText: "RelatedProducts.BotMethodLink",
    linkUrl:
      "https://www.thomsonreuters.com/en-us/help/account-management/thomson-reuters-account/bot-detection-with-auth0.html",
  },
];

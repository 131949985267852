// libraries
import classNames from "classnames";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// components
import { Footer, Header, MainContentTag, Sidebar } from "./components";
import { Section } from "..";

// utilities
import { getClassNameFactory } from "../../utilities";
import { isDisabledSection, isEnterprisePage } from "./AppContainerUtilities";
import { useFeatureToggle } from "../../providers/FeatureToggleProvider";
import { useUser } from "../../hooks";

// constants
import { DISPLAY_NAME } from "./AppContainerConstants";
import { FeatureNames } from "../../providers/FeatureToggleProvider";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const AppContainer: React.FC = () => {
  const user = useUser();
  const { isAuthenticated } = useAuth0();
  const isNewProfileFlowEnabled = useFeatureToggle(
    FeatureNames.PROFILE_PAGE_NEW_FLOW
  );

  const isEnterpriseNavigation =
    isAuthenticated && isNewProfileFlowEnabled && isEnterprisePage() && user;

  const isDefaultProfile = !isNewProfileFlowEnabled;

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          default: isDefaultProfile,
          enterprise: isEnterpriseNavigation,
        }),
      })}
    >
      <Header />
      {isEnterpriseNavigation && <Sidebar />}
      <Section
        className={getClassName({
          descendantName: "content",
          modifiers: classNames({
            disabled: isDisabledSection() || isEnterpriseNavigation,
            enterprise: isEnterpriseNavigation,
          }),
        })}
      >
        <MainContentTag>
          <Outlet />
        </MainContentTag>
      </Section>
      <Footer />
    </div>
  );
};

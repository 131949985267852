// utilities
import { isEmpty } from "../../hooks";

// constants
import { verificationCodeRequirements } from "./VerificationCodeConstants";

// types
import { ValidationRule } from "../../hooks";

export const getDefaultVerificationCode = (codeLength: number) =>
  Array(codeLength).fill("");

export const getVerificationCodeValidationRules = (
  requiredCodeLength: number
): ValidationRule<string[]>[] => [
  (rawCode) => {
    const actualCode = rawCode.join("");

    return (
      isEmpty(actualCode) &&
      verificationCodeRequirements.errors.getValueMissingError()
    );
  },

  (rawCode) => {
    const actualCodeLength = rawCode.join("").length;

    return (
      actualCodeLength !== requiredCodeLength &&
      verificationCodeRequirements.errors.getCodeLengthMismatchError(
        requiredCodeLength
      )
    );
  },
];

// libraries
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

// constants
import { MAIN_CONTENT_ID } from "./MainContentTagConstants";

/** if hashtag is available in the url, we need to focus on the main content.
 * Otherwise 'Tab' will continue going through other content on the page (example: nav header)
 * instead of focusing on Main Content*/
export const MainContentTag = ({ children }) => {
  const createFocus = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash.includes(`#${MAIN_CONTENT_ID}`)) {
      createFocus.current?.focus();
    }
  });

  return (
    <main id={MAIN_CONTENT_ID} ref={createFocus} tabIndex={-1}>
      {children}
    </main>
  );
};

MainContentTag.displayName = "MainContentTag";

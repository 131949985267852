// libraries
import { useContext, useEffect } from "react";

// components
import { LocalizationContext } from "../LocalizationProvider";

// utilities
import { isSupportedLocale } from "../../utilities";
import { shouldUseAuth0Locale } from "./TranslationProviderUtilities";
import { useNavigation, useUser } from "../../hooks";

/**
 * @description Gets and returns locales in lowerCase.
 * @returns auth0Locale - Locale which is set in Auth0 user;
 * urlLocale - Locale from the url.
 */

export const useLocale = () => {
  const { locale: auth0Locale } = useUser();
  const { locale, updateLocale } = useContext(LocalizationContext);
  const { replace } = useNavigation();

  useEffect(() => {
    if (
      shouldUseAuth0Locale(auth0Locale, locale) &&
      isSupportedLocale(auth0Locale)
    ) {
      updateLocale(auth0Locale.toLowerCase());
    }
  }, [auth0Locale, locale, replace, updateLocale]);

  return locale;
};

// utilities
import { isActiveFactor } from ".";

// constants
import { Auth0Endpoints } from "../../api";
import {
  AuthenticatorTypes,
  ErrorMessages,
  MfaFactorTypes,
  OobChannels,
  rawToEnrolledOobFactorsMap,
} from "../../common/constants";

// types
import { EnrolledMfaFactor } from "../../common/types";
import { RawFactor } from "../../redux";

/**
 * @description Checks if the factor is Auth0 Guardian.
 *
 * @param factor - Raw MFA factor returned from backend.
 *
 * @returns True if the factor is Auth0 Guardian, false if it's not.
 */

const isAuthGuardian = (factor: RawFactor) =>
  factor.authenticator_type === AuthenticatorTypes.OOB &&
  factor.oob_channel === OobChannels.AUTH0;

/**
 * @description Checks if the factor is Phone Call (Voice).
 *
 * @param factor - Raw MFA factor returned from backend.
 *
 * @returns True if the factor is Voice, false if it's not.
 */

const isVoice = (factor: RawFactor) =>
  factor.authenticator_type === AuthenticatorTypes.OOB &&
  factor.oob_channel === OobChannels.VOICE;

/**
 * @description Checks if the factor is OTP.
 *
 * @param factor - Raw MFA factor returned from backend.
 *
 * @returns True if the factor is OTP, false if it's not.
 */

const isOtp = (factor: RawFactor) =>
  factor.authenticator_type === AuthenticatorTypes.OTP;

/**
 * @description Checks if the current OTP factor is redundant by looking at the previous factor.
 * If previous factor is Auth0 Guardian, then current OTP is redundant.
 *
 * @param currentFactor -  Current raw MFA factor in the iteration process.
 *
 * @param previousFactor - Previous raw MFA factor in the iteration process.
 *
 * @returns True if the factor is redundant, false if it's not.
 */

const isRedundantOtpFactor = (
  currentFactor: RawFactor,
  previousFactor: RawFactor
) =>
  isOtp(currentFactor) &&
  Boolean(previousFactor) &&
  isAuthGuardian(previousFactor);

/**
 * @description Prepares factor object dependent on factor's type.
 *
 * @param factor - Raw MFA factor returned from backend.
 *
 * @returns Transformed factor's object.
 *
 * @throws Error in case of unsupported factor type.
 */

const prepareFactor = (factor: RawFactor): EnrolledMfaFactor => {
  switch (factor.authenticator_type) {
    case AuthenticatorTypes.OOB:
      return {
        id: factor.id,
        type: rawToEnrolledOobFactorsMap[factor.oob_channel],
        value: factor.name,
      };

    case AuthenticatorTypes.OTP:
      return {
        id: factor.id,
        type: MfaFactorTypes.OTP_APP,
        value: factor.name || "",
      };

    case AuthenticatorTypes.RECOVERY_CODE:
      return {
        id: factor.id,
        type: MfaFactorTypes.RECOVERY_CODE,
      };

    default:
      throw new Error(ErrorMessages.GET_ENROLLED_MFA_FACTORS);
  }
};

/**
 * @description Prepares MFA factors response from backend into the list of enrolled factors.
 * Removes all inactive factors, redundant Voice enrollments which belongs to Text Message
 * and retundant OTP enrollments which belongs to Auth0 Guardian.
 *
 * @param rawMfaFactors - List of raw MFA factors returned from backend.
 *
 * @returns Enrolled MFA factors list prepared to be used by UI.
 */

export const getEnrolledMfaFactors = (
  rawMfaFactors: RawFactor[]
): EnrolledMfaFactor[] =>
  rawMfaFactors.filter(isActiveFactor).map(prepareFactor);

/**
 * @description Checks active field in the factor object and prepares the list of inactive factors.
 *
 * @param rawMfaFactors - List of raw MFA factors returned from backend.
 *
 * @returns Inactive MFA factors list.
 */

export const getInactiveMfaFactors = (rawMfaFactors: RawFactor[]) =>
  rawMfaFactors.filter((factor) => !isActiveFactor(factor));

/**
 * @description Updates MFA factors response from backend.
 * Removes voice factor with belongs phone factor and redundant OTP enrollments which belongs to Auth0 Guardian.
 *
 * @param rawMfaFactors - List of raw MFA factors returned from backend.
 *
 * @returns  MFA factors list prepared without Voice and OTP enrollments.
 *
 */

export const removeRedundantFactors = (rawMfaFactors: RawFactor[]) =>
  rawMfaFactors
    .filter((factor: RawFactor) => !isVoice(factor))
    .filter(
      (factor: RawFactor, index: number, array: RawFactor[]) =>
        !isRedundantOtpFactor(factor, array[index - 1])
    );

export const getFactorsRequest = async ({ apiBasePath, mfaAccessToken }) => {
  const url = `${apiBasePath}/${Auth0Endpoints.GET_FACTORS}`;
  const options = {
    headers: {
      Authorization: `Bearer ${mfaAccessToken}`,
    },
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw await response.text();
  }

  const rawFactors: RawFactor[] = await response.json();

  return { rawFactors };
};

// utilities
import { ciamFetch } from "..";

// constants
import { HttpMethods, endpoints } from "../../common/constants";

// types
import { SendVerificationEmailArguments } from "./types";

export const sendVerificationEmail = async ({
  accessToken,
  apiBasePath,
  clientId,
  euId,
  locale,
}: SendVerificationEmailArguments) => {
  const options = {
    method: HttpMethods.GET,
  };

  const url = endpoints.getSendVerificationEmailEndpoint({
    apiBasePath,
    clientId,
    euId,
    locale,
  });

  const response = await ciamFetch(url, accessToken, options);

  if (!response.ok) {
    const errorMessage = await response.text();

    throw new Error(errorMessage);
  }
};

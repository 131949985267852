// libraries
import { FeatureToggle } from "@tr-digital/react-feature-toggle";

// components
import { Loading } from "../../components";

// utilities
import { useFeatureToggleInstance } from "./FeatureToggleHooks";

/**
 * @description Provides feature toggle configurations for different features.
 * Every functionality that requires these configuration values should be wrapped in the provider.
 */

export const FeatureToggleProvider: React.FC = ({ children }) => {
  const instance = useFeatureToggleInstance();

  return instance ? (
    <FeatureToggle featureToggleInstance={instance}>{children}</FeatureToggle>
  ) : (
    <Loading />
  );
};

// libraries
import { useCallback } from "react";
import { useDispatch } from "react-redux";

// utilities
import { getStatus } from "../../redux";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { useGetAccessToken, useNavigation, useUser } from "..";

// constants
import { TwoStepVerificationCustomErrorCodes } from "../../common/constants";

// types
import { Dispatch } from "../../redux";

/**
 * @description Hook is called in places where there's a need to get actual MFA status, e.g. for factors list getting
 */

export const useGetMfaStatus = () => {
  const dispatch = useDispatch<Dispatch>();
  const { getAccessToken } = useGetAccessToken();
  const {
    ciam: { basePath: apiBasePath },
  } = useConfiguration();
  const { paths, redirect } = useNavigation();
  const { sub } = useUser();

  /**
   * @description The function is called to get the MFA status boolean value.
   *
   * @handlesError Redirects to Generic Error page.
   */

  return useCallback(async () => {
    try {
      const accessToken = await getAccessToken();

      await dispatch(getStatus({ accessToken, apiBasePath, sub })).unwrap();
    } catch {
      redirect(paths.customError, {
        errorCode: TwoStepVerificationCustomErrorCodes.MF0002,
      });
    }
  }, [apiBasePath, dispatch, getAccessToken, paths.customError, redirect, sub]);
};

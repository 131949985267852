export const PhoneIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.91 7.375h10.5M6.91 15.25h10.5"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      clipRule="evenodd"
      d="M18.16 24.25h-12a1.5 1.5 0 0 1-1.5-1.5V3.25a1.5 1.5 0 0 1 1.5-1.5h12a1.5 1.5 0 0 1 1.5 1.5v19.5a1.5 1.5 0 0 1-1.5 1.5Z"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M6.91 4.375h10.5V18.25H6.91z"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="12.16"
      cy="21.25"
      r=".75"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

// libraries
import { matchPath } from "react-router";

// utilities
import { getAbsolutePath, getPath } from "../../utilities";

// constants
import { disabledSectionPaths, enterprisePaths } from "./AppContainerConstants";

export const isDisabledSection = () =>
  disabledSectionPaths
    .map((path) => getPath(path))
    .includes(window.location.pathname);

export const checkPathsMatch = (pathPattern: string, currentPath: string) => {
  return (
    matchPath({ path: getAbsolutePath(pathPattern) }, currentPath) !== null
  );
};

export const isEnterprisePage = () => {
  return enterprisePaths.some((path) => {
    return checkPathsMatch(path, window.location.pathname);
  });
};

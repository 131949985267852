// libraries
import classNames from "classnames";
import {
  SafIcon,
  SafMenuItem,
  SafSideNav,
} from "@saffron/core-components/react";
import { useTranslate } from "@dcl/tools";

// utilities
import {
  FeatureNames,
  useFeatureToggle,
} from "../../../../providers/FeatureToggleProvider";
import { checkPathsMatch } from "../../AppContainerUtilities";
import { getClassNameFactory } from "../../../../utilities";
import { useNavigation, useUser } from "../../../../hooks";

// constants
import { DISPLAY_NAME } from "./SidebarConstants";
import { RoleTypes, paths } from "../../../../common/constants";
import {
  SAFFRON_SELECTED_CLASSNAME,
  pageNavigationConfiguration,
} from "../../AppContainerConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Sidebar: React.FC = () => {
  const isRegKeyManagementEnabled = useFeatureToggle(
    FeatureNames.REG_KEY_MANAGEMENT
  );
  const translate = useTranslate();
  const { federatedUserId } = useUser();
  const { redirect } = useNavigation();

  const pathname = window.location.pathname;

  return (
    <div className={getClassName()}>
      <SafSideNav
        aria-label={translate("Navigation.AriaLabel")}
        close-aria-label={translate("Navigation.CollapseButtonText")}
        open-aria-label={translate("Navigation.ExpandButtonText")}
        role={RoleTypes.NAVIGATION}
      >
        {pageNavigationConfiguration.map(
          ({
            shouldShowInFederatedView,
            iconName,
            path,
            pathPatternToSelectItem,
            textKey,
          }) => {
            if (!shouldShowInFederatedView && federatedUserId) {
              return null;
            }

            if (!isRegKeyManagementEnabled && path === paths.products.index) {
              return null;
            }

            return (
              <SafMenuItem
                className={classNames(
                  getClassName("item"),
                  checkPathsMatch(pathPatternToSelectItem, pathname) &&
                    SAFFRON_SELECTED_CLASSNAME
                )}
                hasLink={false}
                key={textKey}
                onClick={() => redirect(path)}
              >
                <SafIcon
                  appearance={`${
                    checkPathsMatch(pathPatternToSelectItem, pathname)
                      ? "solid"
                      : "light"
                  }`}
                  iconName={iconName}
                  slot="start"
                />
                <span>{translate(textKey)}</span>
              </SafMenuItem>
            );
          }
        )}
      </SafSideNav>
    </div>
  );
};

Sidebar.displayName = DISPLAY_NAME;

// constants
import { ConfirmationErrors } from "./confirmAuthGuardianEnrollmentConstants";
import { ErrorMessages } from "../../../../common/constants";

export const handleConfirmAuthGuardianEnrollmentError = (data) => {
  if (data?.error === ConfirmationErrors.AUTHORIZATION_PENDING) {
    throw new Error(ConfirmationErrors.AUTHORIZATION_PENDING);
  } else if (data?.error_description === ErrorMessages.MALFORMED_MFA_TOKEN) {
    throw new Error(ErrorMessages.MALFORMED_MFA_TOKEN);
  } else if (data?.error_description === ErrorMessages.MALFORMED_OBB_CODE) {
    throw new Error(ErrorMessages.MALFORMED_OBB_CODE);
  } else if (data?.error === ConfirmationErrors.ACCESS_DENIED) {
    throw new Error(ConfirmationErrors.ACCESS_DENIED);
  }
};

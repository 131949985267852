// libraries
import { SafProgressRing } from "@saffron/core-components/react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getClassNameFactory } from "../../utilities";
import { useLoadingNotification } from "./LoadingHooks";

// constants
import { RoleTypes } from "../../common/constants";

const getClassName = getClassNameFactory("Loading");

export const Loading: React.FC = () => {
  const translate = useTranslate();

  useLoadingNotification();

  return (
    <div className={getClassName()}>
      <SafProgressRing
        aria-label={translate("Page.Loading")}
        progressSize="large"
        role={RoleTypes.PROGRESS_BAR}
      />
    </div>
  );
};

export const DISPLAY_NAME = "Heading";

export enum HeadingRank {
  First = "1",
  Fourth = "4",
  Second = "2",
  Third = "3",
}

export enum HeadingTypes {
  Large = "l",
  Medium = "m",
  Small = "s",
  XtraSmall = "xs",
}

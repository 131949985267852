// libraries
import { useDispatch } from "react-redux";

// utilities
import { updatePassword } from "../redux/password/passwordUpdate/passwordUpdateThunk";
import { useConfiguration } from "../providers/ConfigurationProvider";
import { useGetAccessToken } from "./useGetAccessToken";

// types
import { Dispatch } from "../redux";
import { RequestPayload } from "../redux/password/passwordUpdate/passwordUpdateTypes";

export const useUpdatePassword = () => {
  const dispatch = useDispatch<Dispatch>();
  const { getAccessToken } = useGetAccessToken();
  const {
    ciam: { basePath: apiBasePath },
  } = useConfiguration();

  return async (requestPayload: RequestPayload) => {
    const accessToken = await getAccessToken();

    const passwordUpdateArguments = {
      accessToken,
      apiBasePath,
      requestPayload,
    };

    return dispatch(updatePassword(passwordUpdateArguments)).unwrap();
  };
};

// libraries
import { createContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";

// components
import { Loading } from "../../components";

// utilities
import { userSlice } from "../../redux/user";

// types
import { Auth0User, RawAuth0User } from "../../common/types";
import { Dispatch, State } from "../../redux";
import { ReducerState } from "../../redux/user/types";
import { toProfileUser } from "../../utilities";

export const UserContext: React.Context<{
  user: Auth0User | null;
}> = createContext({
  user: null as Auth0User | null,
});

export const UserProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch<Dispatch>();
  const { user: auth0User } = useAuth0();
  const { user } = useSelector<State, ReducerState>((state) => state.getUser);

  useEffect(() => {
    if (!user && auth0User) {
      const profileUser = toProfileUser<RawAuth0User, Auth0User>(
        auth0User as RawAuth0User
      );

      dispatch(userSlice.actions.setUser(profileUser));
    }
  }, [auth0User, dispatch, user]);

  if (!user) return <Loading />;

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

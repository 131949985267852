export const BiometricsIcon: React.FC = () => (
  <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="8"
      cy="8"
      r="2"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="16"
      cy="8"
      r="2"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="8"
      cy="16"
      r="2"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="16"
      cy="16"
      r="2"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle cx="1" cy="8" fill="#FA6400" r="1" />
    <circle cx="1" cy="16" fill="#FA6400" r="1" />
    <circle cx="23" cy="8" fill="#FA6400" r="1" />
    <circle cx="23" cy="16" fill="#FA6400" r="1" />
    <circle cx="8" cy="1" fill="#FA6400" r="1" />
    <circle cx="16" cy="1" fill="#FA6400" r="1" />
    <circle cx="8" cy="23" fill="#FA6400" r="1" />
    <circle cx="16" cy="23" fill="#FA6400" r="1" />
  </svg>
);

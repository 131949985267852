// libraries
import classNames from "classnames";

// components
import { Heading } from "../../.";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { DISPLAY_NAME, IconPosition } from "../HeadingSectionConstants";
import { HeadingRank, HeadingTypes } from "../../Heading";

// types
import { HeadingSectionProps } from "../HeadingSectionTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const DefaultHeadingSection: React.FC<HeadingSectionProps> = ({
  className,
  heading,
  headingRank = HeadingRank.First,
  headingType = HeadingTypes.Large,
  icon,
  iconPosition = IconPosition.HEADING,
  subheading,
}) => (
  <div
    className={classNames(
      getClassName({
        modifiers: classNames({
          default: true,
          [iconPosition]: true,
        }),
      }),
      className
    )}
  >
    {icon && <div className={getClassName("icon")}>{icon}</div>}
    <Heading rank={headingRank} type={headingType}>
      <strong>{heading}</strong>
    </Heading>
    {subheading && <p className={getClassName("subheading")}>{subheading}</p>}
  </div>
);

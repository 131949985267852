// libraries
import classNames from "classnames";

// components
import { CheckboxInput } from "./components/CheckboxInput";
import { InlineErrorMessage } from "../../components";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import {
  CHECKBOX_ERROR_ID,
  CHECKBOX_ID,
  DISPLAY_NAME,
} from "./CheckboxConstants";

// types
import { CheckboxProps } from "./CheckboxTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  errorMessage,
  label,
  onChange,
}) => (
  <div className={classNames(getClassName(), className)}>
    <CheckboxInput
      aria-describedby={CHECKBOX_ERROR_ID}
      id={CHECKBOX_ID}
      isError={!!errorMessage}
      label={label}
      onChange={onChange}
      required
    />
    {!!errorMessage && (
      <InlineErrorMessage errorMessage={errorMessage} id={CHECKBOX_ERROR_ID} />
    )}
  </div>
);

Checkbox.displayName = DISPLAY_NAME;

export const MessageIcon: React.FC = () => (
  <svg
    className="messageIcon"
    focusable="false"
    viewBox="0 0 24 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1.736)">
      <path
        d="M0 0v18.264l6.13-5H22V0z"
        stroke="#404040"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        className="accentSvg"
        cx="5.615"
        cy="6.5"
        fill="#FA6400"
        r="1.615"
      />
      <circle className="accentSvg" cx="11" cy="6.5" fill="#FA6400" r="1.615" />
      <circle
        className="accentSvg"
        cx="16.385"
        cy="6.5"
        fill="#FA6400"
        r="1.615"
      />
    </g>
  </svg>
);

export enum Locales {
  EN_AU = "en-AU",
  EN_CA = "en-CA",
  EN_GB = "en-GB",
  EN_NZ = "en-NZ",
  EN_US = "en-US",
  ES_AR = "es-AR",
  ES_BR = "es-BR",
  ES_MX = "es-MX",
  FR_CA = "fr-CA",
  JA_JP = "ja-JP",
  PT_BR = "pt-BR",
  ZH_CN = "zh-CN",
}

export const DEFAULT_LOCALE = Locales.EN_US;

export const dropdownLocaleItems = [
  {
    label: "English (AU)",
    value: Locales.EN_AU,
  },
  {
    label: "English (CA)",
    value: Locales.EN_CA,
  },
  {
    label: "English (GB)",
    value: Locales.EN_GB,
  },
  {
    label: "English (US)",
    value: Locales.EN_US,
  },
  {
    label: "Español (AR)",
    value: Locales.ES_AR,
  },
  {
    label: "Français (CA)",
    value: Locales.FR_CA,
  },
  {
    label: "Português (BR)",
    value: Locales.PT_BR,
  },
];

export const extendedDropdownLocaleItems = [
  ...dropdownLocaleItems,
  {
    label: "English (NZ)",
    value: Locales.EN_NZ,
  },
  {
    label: "Español (BR)",
    value: Locales.ES_BR,
  },
  {
    label: "Español (MX)",
    value: Locales.ES_MX,
  },
  {
    label: "日本語",
    value: Locales.JA_JP,
  },
  {
    label: "中文",
    value: Locales.ZH_CN,
  },
];

// constants
import { MfaSessionStorageKeys } from "../../common/constants";

/**
 * @description Removes all session storage keys connected to MFA.
 */

export const removeMfaSessionStorageKeys = () => {
  Object.values(MfaSessionStorageKeys).forEach((storageKey) =>
    sessionStorage.removeItem(storageKey)
  );
};

// constants
import { paths } from "../../common/constants";

export const DISPLAY_NAME = "AppContainer";
export const SAFFRON_SELECTED_CLASSNAME = "selected";

export const disabledSectionPaths = [paths.about];

export const enterprisePaths = [
  paths.personalInfo,
  paths.products.index,
  paths.products.manageRegKey,
  paths.products.regKeys,
  paths.products.registerProduct,
  paths.profile,
  paths.security,
];

export const pageNavigationConfiguration = [
  {
    iconName: "objects-column",
    path: paths.profile,
    pathPatternToSelectItem: paths.profile,
    shouldShowInFederatedView: true,
    textKey: "Navigation.DashboardLink",
  },
  {
    iconName: "square-user",
    path: paths.personalInfo,
    pathPatternToSelectItem: paths.personalInfo,
    shouldShowInFederatedView: true,
    textKey: "Navigation.PersonalInfoLink",
  },
  {
    iconName: "lock-keyhole",
    path: paths.security,
    pathPatternToSelectItem: paths.security,
    shouldShowInFederatedView: false,
    textKey: "Navigation.SecurityLink",
  },
  {
    iconName: "grid-2",
    path: paths.products.index,
    pathPatternToSelectItem: `${paths.products.index}/*`,
    shouldShowInFederatedView: true,
    textKey: "Navigation.ProductsLink",
  },
];

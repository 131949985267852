// libraries
import ResizeObserver from "resize-observer-polyfill";
import classNames from "classnames";
import { useEffect, useRef } from "react";

// components
import { CheckMarkSmallIcon } from "../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { DISPLAY_NAME } from "../DropdownConstants";
import { RoleTypes } from "../../../common/constants";

// types
import { IDropdownItemProps } from "../DropdownTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const DropdownItem: React.FC<IDropdownItemProps> = ({
  getItemHeight,
  isFocused,
  isSelected,
  item,
  onBlur,
  onItemClicked,
}) => {
  const itemElement = useRef<HTMLLIElement>(null);

  const observer = useRef(
    new ResizeObserver(([dropdownItem]: ResizeObserverEntry[]) => {
      const { height } = dropdownItem.contentRect;

      if (getItemHeight) {
        getItemHeight(height);
      }
    })
  );

  useEffect(() => {
    const currentItem = itemElement.current;
    const currentObserver = observer.current;

    if (currentItem && getItemHeight) {
      currentObserver.observe(currentItem);
    }

    return () => {
      if (currentItem && getItemHeight) {
        currentObserver.unobserve(currentItem);
      }
    };
  }, [itemElement, observer, getItemHeight]);

  useEffect(() => {
    if (isFocused && itemElement.current) {
      itemElement.current.scrollIntoView({
        block: "nearest",
      });

      itemElement.current.focus();
    }
  }, [isFocused]);

  return (
    <li
      aria-selected={isSelected}
      className={getClassName("item")}
      ref={itemElement}
      role={RoleTypes.OPTION}
      tabIndex={0}
    >
      <button
        className={`${getClassName({
          descendantName: "itemInner",
          modifiers: classNames({
            focused: isFocused,
            withCheckmark: isSelected,
          }),
        })} ${getClassName({
          descendantName: "itemInnerButton",
          modifiers: isSelected && "selected",
        })}`}
        id={item.value ? item.value.toString() : ""}
        onBlur={onBlur}
        // preventing losing focus from the parent UL element to keep accessibility features working
        onMouseDown={(event): void => {
          event.preventDefault();

          onItemClicked(item, event);
        }}
        tabIndex={0}
      >
        <span className={getClassName("itemInnerText")}>{item.label}</span>
        {isSelected && (
          <span className={getClassName("checkmark")}>
            <CheckMarkSmallIcon />
          </span>
        )}
      </button>
    </li>
  );
};

export const UsersIcon: React.FC = () => (
  <svg
    focusable="false"
    viewBox="0 0 312 268"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M197.9 253c0-5.2-3.2-9.9-8.1-11.6-7.2-2.6-19-5.7-35.1-5.7s-27.9 3.1-35.1 5.7c-4.9 1.8-8.1 6.4-8.1 11.6v13.7h86.4V253zm-42-24.4h0c-5.6 0-10.8-2-14.7-5.2-2.6-2.1-4.6-4.8-5.9-7.9-.6-1.3-1-2.7-1.3-4.1-.2-1.2-.3-2.4-.3-3.7v-6c0-11.5 9.9-20.9 22.2-20.9h0c12.3 0 22.2 9.3 22.2 20.9v6c0 11.6-9.9 20.9-22.2 20.9z"
      fill="none"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.1428"
    />
    <path
      d="M154.7 216.7h-20.4c-8.6 0-10.5-4.9-10.5-9.7v-7.9c0-15.3 13.8-27.7 30.9-27.7s30.9 12.4 30.9 27.7v7.9"
      fill="none"
      stroke="#fa6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.1428"
    />
    <path
      d="M122.6 207.1v-9.5m24.4 17.8 7.9.1m31.9-8.4v-9.5"
      fill="none"
      stroke="#fa6400"
      strokeLinecap="round"
      strokeWidth="6.286"
    />
    <path
      d="M56.6 83.2c-4.2-.5-8.9-.9-14.1-.9-17.5 0-29.1 3.7-35.3 6.4-3.4 1.5-5.6 4.9-5.6 8.6v16.4h50.3"
      fill="none"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.1428"
    />
    <circle cx="80.1" cy="105.9" fill="#fa6400" r="3.1" />
    <path
      d="M20.4 44.7c0-12.2 9.8-22 22-22s22 9.8 22 22c0 12.1-9.8 25.1-22 25.1-12.1 0-22-13-22-25.1z"
      fill="none"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.1428"
    />
    <path
      d="M64.4 94.9h31.4v22H64.4z"
      fill="none"
      stroke="#fa6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.1428"
    />
    <path
      d="M70.7 94.9V82.4c0-5.2 4.2-9.4 9.4-9.4h0c5.2 0 9.4 4.2 9.4 9.4V95m177.4 18.8v-88m-39.3 44h78.6"
      fill="none"
      stroke="#fa6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.1428"
    />
    <path
      d="M266.9 25.8c-12.6 9.4-31.4 15.7-44 15.7 0 29.3 14.7 59.7 44 72.3 29.3-12.6 44-43 44-72.3-12.6 0-31.4-6.3-44-15.7z"
      fill="none"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.1428"
    />
    <path
      d="M115.9 231.1c-47.3-11.2-75.4-76.8-74.4-103.9m155 105.7c51-15 84.5-62.7 84.5-106.9M231.8 25.9C186.6-10.4 120.2-4.4 80.6 32.8"
      fill="none"
      stroke="#fa6400"
      strokeDasharray="10,9"
      strokeWidth="3.143"
    />
  </svg>
);

// types
import { To } from "../../common/types";

/**
 * @description Replaces current locale in the url with provided locale.
 * @param locale - Auth0 or url locale.
 * @param replace - Replace function from useNavigation hook.
 */
export const replaceLocaleInUrl = (
  locale: string,
  replace: <T>(to: To, state?: T | undefined) => void
) => {
  const LOCALE_INDEX = 1;
  const pathname = window.location.pathname.split("/");

  if (pathname[LOCALE_INDEX].includes("-")) {
    pathname[LOCALE_INDEX] = locale;
    replace({ pathname: pathname.join("/"), search: window.location.search });
  }
};

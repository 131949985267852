// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { ciamFetch } from "../../../utilities";
import { getErrorCode } from "./passwordUpdateUtilities";

// constants
import { HttpMethods, endpoints } from "../../../common/constants";

// types
import { ThunkArguments, ThunkConfiguration } from "./passwordUpdateTypes";
import { ThunkError } from "../../../common/types";

export const updatePassword = createAsyncThunk<
  void,
  ThunkArguments,
  ThunkConfiguration
>(
  "updatePassword/updatePassword",
  async ({ accessToken, apiBasePath, requestPayload }, { rejectWithValue }) => {
    const url = endpoints.getUpdatePasswordEndpoint(apiBasePath);

    const options = {
      body: requestPayload,
      method: HttpMethods.PATCH,
    };

    try {
      const response = await ciamFetch(url, accessToken, options);

      if (!response.ok) {
        const error = await response.json();

        throw new Error(error.message);
      }

      return;
    } catch (error) {
      const errorMessage = getErrorCode(error as ThunkError);

      return rejectWithValue({ message: errorMessage });
    }
  }
);

// libraries
import { SafTextfield } from "@saffron/core-components/react";
import { useState } from "react";

// utilities
import { getClassNameFactory } from "../../../../utilities";
import { useEnterprisePasswordInputConfiguration } from "./EnterprisePasswordInputHooks";

// constants
import { ButtonTypes } from "../../../../common/constants";
import { DISPLAY_NAME } from "./EnterprisePasswordInputConstants";

// types
import { PasswordInputProps } from "../../PasswordInputTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const EnterprisePasswordInput: React.FC<PasswordInputProps> = ({
  errorMessage,
  isCustomValidationError,
  label,
  name,
  onChange,
  placeholder,
  value,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { icon, inputType, title } =
    useEnterprisePasswordInputConfiguration(isPasswordVisible);

  const handleToggle = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={getClassName("wrapper")}>
      <SafTextfield
        invalid={isCustomValidationError}
        label={label as string}
        name={name}
        // @ts-ignore
        onInput={onChange}
        placeholder={placeholder}
        // @ts-ignore
        type={inputType}
        validation-message={errorMessage}
        value={value as string}
        required
        {...props}
      >
        <button
          className={getClassName("togglePasswordButton")}
          onClick={handleToggle}
          slot="end"
          title={title}
          type={ButtonTypes.BUTTON}
          value={value as string}
        >
          {icon}
        </button>
      </SafTextfield>
    </div>
  );
};

EnterprisePasswordInput.displayName = DISPLAY_NAME;

// libraries
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// utilities
import { getAppendedPath, getCutPath, getPath } from "../utilities";

// constants
import { paths } from "../common/constants";

// types
import { To } from "../common/types";

/**
 * @description Provides navigation functions for the application. Built on top of React's `useNavigate` hook.
 * @returns An object containing the following functions:
 * - `back`: Navigates back one step in history.
 * - `paths`: An object containing all the paths used in the application.
 * - `redirect`: Redirects to a given path with an optional state.
 * - `replace`: Replaces the current location with a given path and an optional state.
 */
export const useNavigation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return {
    appendPath: useCallback(
      (to: string) => navigate(getAppendedPath(pathname, to)),
      [navigate, pathname]
    ),
    back: useCallback(() => navigate(-1), [navigate]),
    navigate,
    paths,
    redirect: useCallback(
      <T>(to: To, state?: T) => navigate(getPath(to), { state }),
      [navigate]
    ),
    redirectUp: useCallback(
      (levels: number = 1) => navigate(getCutPath(pathname, levels)),
      [navigate, pathname]
    ),
    replace: useCallback(
      <T>(to: To, state?: T) => navigate(getPath(to), { replace: true, state }),
      [navigate]
    ),
  };
};

// libraries
import { SafButton, SafIcon } from "@saffron/core-components/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useTranslate } from "@dcl/tools";

// components
import { Modal } from "./components/Modal";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { DISPLAY_NAME } from "./MenuConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Menu: React.FC = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const translate = useTranslate();

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <div className={getClassName()}>
      <SafButton
        a11yAriaLabel={translate("Menu.Heading")}
        appearance="tertiary"
        icon-only=""
        onClick={handleOpen}
      >
        <SafIcon icon-name="bars" />
      </SafButton>
      {isModalOpen && <Modal onClose={handleClose} />}
    </div>
  );
};

Menu.displayName = DISPLAY_NAME;

// constants
import { Auth0Endpoints } from "../../../../api";
import { HttpMethods } from "../../../../common/constants";

// types
import { VerifyFactorArguments } from "./verifyFactorTypes";

export const verifyFactor = async <T extends Record<string, string>>({
  apiBasePath,
  headers,
  requestPayload,
}: VerifyFactorArguments<T>) => {
  const url = `${apiBasePath}/${Auth0Endpoints.VERIFY_FACTOR}`;

  const options = {
    body: new URLSearchParams(requestPayload),
    method: HttpMethods.POST,
    ...(headers && { headers }),
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const error = await response.json();

    throw new Error(error.error_description);
  }

  return response.json();
};

// libraries
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// utilities
import { getProducts } from "../../utilities";
import { getProductsSlice } from "../../redux/products";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { useGetAccessToken } from "../../hooks";

// types
import { Product } from "../../common/types";
import { ProductsArrayState } from "../../redux/products/productsTypes";
import { State } from "../../redux";

/**
 * Custom React hook to fetch and manage the state of products.
 *
 * @returns An object containing:
 * - `productsList`: The list of products.
 * - `isProductsListLoading`: The loading state of the products list.
 */
export const useRawProducts = () => {
  const dispatch = useDispatch();
  const { getAccessToken } = useGetAccessToken();
  const {
    ciam: { basePath: apiBasePath },
  } = useConfiguration();

  const [productsList, setProductsList] = useState<Product[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { productArray } = useSelector<State, ProductsArrayState>(
    (state) => state.getProducts
  );

  const fetchProducts = useCallback(async () => {
    if (productArray && productArray.length) {
      setProductsList(productArray);

      return;
    }

    setIsLoading(true);
    try {
      const products = await getProducts({
        accessToken: await getAccessToken(),
        apiBasePath,
      });

      setProductsList(products);
      if (!productArray || !productArray.length) {
        dispatch(getProductsSlice.actions.setProducts(products));
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [apiBasePath, dispatch, getAccessToken, productArray]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return { isProductsListLoading: isLoading, productsList };
};

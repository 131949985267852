export const CheckMarkSmallIcon: React.FC = () => (
  <svg focusable={false} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 12l4 4 8-8"
      fill="none"
      stroke="#404040"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);

import { Auth0UserParameters } from "../../common/constants";

export const profileToRawMap = {
  email: Auth0UserParameters.EMAIL,
  firstName: Auth0UserParameters.FIRST_NAME,
  lastName: Auth0UserParameters.LAST_NAME,
  locale: Auth0UserParameters.LOCALE,
};

export const rawToUpdatedUserMap = {
  [Auth0UserParameters.EMAIL]: "email",
  [Auth0UserParameters.LAST_NAME]: "lastName",
  [Auth0UserParameters.FIRST_NAME]: "firstName",
  [Auth0UserParameters.LOCALE]: "locale",
};

export const rawToProfileUserMap = {
  [Auth0UserParameters.AVATAR]: "avatar",
  [Auth0UserParameters.EMAIL]: "email",
  [Auth0UserParameters.EU_ID]: "euId",
  [Auth0UserParameters.FEDERATED_USER_ID]: "federatedUserId",
  [Auth0UserParameters.FIRST_NAME]: "firstName",
  [Auth0UserParameters.LAST_NAME]: "lastName",
  [Auth0UserParameters.LOCALE]: "locale",
  [Auth0UserParameters.SUB]: "sub",
};

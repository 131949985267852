// components
import PasswordDesktop from "../../images/password-desktop.png";
import PasswordDesktopWebp from "../../images/password-desktop.webp";
import ProfileDesktop from "../../images/profile-desktop.png";
import ProfileDesktopWebp from "../../images/profile-desktop.webp";
import { UsersIcon } from "../../../../common/icons";

// constants
import { RoleTypes } from "../../../../common/constants";

export const DISPLAY_NAME = "Tabs";

export const features = [
  {
    description: "Features.ItemDescription1",
    heading: "Features.ItemHeading1",
    image: <UsersIcon />,
  },
  {
    description: "Features.ItemDescription2",
    heading: "Features.ItemHeading2",
    image: (
      <picture>
        <source srcSet={ProfileDesktopWebp} type="image/webp" />
        <img alt="" role={RoleTypes.PRESENTATION} src={ProfileDesktop} />
      </picture>
    ),
  },
  {
    description: "Features.ItemDescription3",
    heading: "Features.ItemHeading3",
    image: (
      <picture>
        <source srcSet={PasswordDesktopWebp} type="image/webp" />
        <img alt="" role={RoleTypes.PRESENTATION} src={PasswordDesktop} />
      </picture>
    ),
  },
];

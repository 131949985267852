export const DISPLAY_NAME = "About";

export const metaTagsConfiguration = [
  {
    contentKey: "AboutPage.MetaTagDescription",
    name: "description",
  },
  {
    contentKey: "AboutPage.MetaTagKeywords",
    name: "keywords",
  },
];

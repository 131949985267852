export const paths = {
  about: "/about",
  base: "/:language-:country/profile/",
  catchAll: "*",
  checkEmail: "/check-email",
  customError: "/errors",
  emailVerified: "/email-verified",
  emailVerifiedByOtp: "/verify-email/email-verified",
  emergencyOtp: "/emergency-otp/:email",
  genericError: "/error",
  hash: {
    confirm: "confirm",
    edit: "edit",
    mfa: {
      addAuth0Guardian: "add-auth0",
      addOtpApp: "add-app",
      addPhoneCall: "add-phone",
      addTextMessage: "add-text",
      configuration: "2fa",
      verifyAuth0Guardian: "verify-auth0",
      verifyPhoneCall: "verify-phone",
      verifyTextMessage: "verify-text",
    },
  },
  mfa: {
    auth0Guardian: {
      add: "/2fa/add-auth0",
      verify: "/2fa/add-auth0/verify",
    },
    index: "/2fa",
    otpApp: "/2fa/add-app",
    phone: {
      add: "/2fa/add-phone",
      verifyPhoneCall: "/2fa/add-phone/verify-phone",
      verifyTextMessage: "/2fa/add-phone/verify-text",
    },
    recoveryCode: "/2fa/recovery-code",
  },
  passwordChanged: "/password-changed",
  passwordExpired: "/change-password",
  passwordWarning: "/password-expires",
  personaSelection: "/product-options",
  personalInfo: "/personal-info",
  products: {
    index: "/products",
    manageRegKey: "/products/:productCode/manage-key",
    regKeys: "/products/:productCode",
    registerProduct: "/products/register-product",
  },
  profile: "/",
  registration: {
    accountCreated: "/create-account/account-created",
    index: "/create-account",
  },
  security: "/security",
  signout: "/signed-out",
  timeout: "/timeout",
  unblockAccount: "/unblock-account",
  verifyEmailByOtp: "/verify-code",
  verifyNewAccount: "/verify-your-code",
  verifyNewEmail: "/email-verification",
  verifySecurityQuestion: "/verify-account",
  verifyUpdatedEmail: "/verify-email",
} as const;

export enum TextButtonWeightTypes {
  BOLD = "bold",
  NORMAL = "normal",
}

export interface TextButtonProps {
  onClick: (event?: React.SyntheticEvent) => void;
  className?: string;
  weight?: TextButtonWeightTypes;
}

// libraries
import classNames from "classnames";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./CardConstants";

// types
import { CardProps } from "./CardTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Card: React.FC<CardProps> = ({
  children,
  className = "",
  isEnterprise,
  mobileBorderless,
  withShadow,
}) => (
  <div
    className={classNames(
      getClassName({
        modifiers: classNames(
          isEnterprise && "enterprise",
          mobileBorderless && "mobileBorderless",
          withShadow && "shadow"
        ),
      }),
      className
    )}
  >
    {children}
  </div>
);

Card.displayName = DISPLAY_NAME;

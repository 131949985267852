// constants
import { Auth0Endpoints } from "../../../../api";
import { HttpMethods } from "../../../../common/constants";

// types
import { EnrollFactorArguments } from "./enrollFactorTypes";

export const enrollFactor = async <T, K>({
  apiBasePath,
  locale,
  mfaToken,
  requestPayload,
}: EnrollFactorArguments<T>): Promise<K> => {
  const headers = {
    Authorization: `Bearer ${mfaToken}`,
    "Content-Type": "application/json",
  };
  const url = `${apiBasePath}/${Auth0Endpoints.ENROLL_FACTOR}`;

  let response = await fetch(url, {
    body: JSON.stringify(requestPayload),
    headers: {
      ...headers,
      ...(locale && { "X-Request-Language": locale }),
    },
    method: HttpMethods.POST,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  }

  return data;
};

// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// constants
import {
  ErrorMessages,
  HttpMethods,
  endpoints,
} from "../../../common/constants";

// types
import {
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./recoveryCodeTypes";

export const regenerateRecoveryCode = createAsyncThunk<
  string,
  ThunkArguments,
  ThunkConfiguration
>(
  "recoveryCode/regenerateRecoveryCode",
  async ({ accessToken, apiBasePath, sub }, { rejectWithValue }) => {
    const url = endpoints.getRegenerateRecoveryCodeEndpoint(apiBasePath, sub);
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: HttpMethods.POST,
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw await response.text();
      }

      const { recovery_code: recoveryCode }: ResponsePayload =
        await response.json();

      return recoveryCode;
    } catch (error) {
      return rejectWithValue({
        message: ErrorMessages.REGENERATE_RECOVERY_CODE,
      });
    }
  }
);

// types
import { GetSendVerificationEmailEndpointParameters } from "./types";

export enum ErrorCodes {
  ACCESS_DENIED = "access_denied",
  INVALID_BODY = "invalid_body",
  SERVER_ERROR = "internal_server_error",
}

export enum HttpMethods {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

export enum HttpStatusCodes {
  BAD_REQUEST = 400,
  CONFLICT = 409,
  ERROR = 500,
  FORBIDDEN = 403,
  NO_CONTENT = 204,
  NOT_FOUND = 404,
  OK = 200,
  UNAUTHORIZED = 401,
}

export enum ResponseStatuses {
  REJECTED = "rejected",
}

export enum UrlParameters {
  CAUSE = "cause",
  CLIENT_ID = "client_id",
  DAYS_LEFT = "days_left",
  EMAIL = "email",
  QUESTION_ID = "question_id",
  REMAINING_ATTEMPTS = "remaining_attempts",
  REPLAY_URL = "replayURL",
  SESSION_TOKEN = "session_token",
  STATE = "state",
  TOKEN = "token",
}

export const endpoints = {
  getAemDictionaryEndpoint: (locale: string) =>
    `${
      window.location.origin
    }/content/ue-settings/endpointsMapping/translations.ciam.${locale.replace(
      "-",
      "_"
    )}.json`,

  getCheckEmailVerificationEndpoint: (apiBasePath: string, euId: string) =>
    `${apiBasePath}/users/${euId}`,

  getMfaStatusEndpoint: (apiBasePath: string, sub: string) =>
    `${apiBasePath}/users/bySub/${sub}/multifactor/status`,

  getProductUrlEndpoint: (apiBasePath: string, clientId: string) =>
    `${apiBasePath}/digital/clients/${clientId}`,

  getProductsEndpoint: (apiBasePath: string) => `${apiBasePath}/products`,

  getRedirectToProductEndpoint: (apiBasePath: string, state: string) =>
    `${apiBasePath}/continue?state=${state}`,

  getRegenerateRecoveryCodeEndpoint: (apiBasePath: string, sub: string) =>
    `${apiBasePath}/users/${sub}/recovery-code-regeneration`,

  getRegisterUserEndpoint: (apiBasePath: string) =>
    `${apiBasePath}/digital/users`,

  getRegistrationKeysEndpoint: (apiBasePath: string, euId: string) =>
    `${apiBasePath}/users/${euId}/registration-keys`,

  getSecurityQuestionsListEndpoint: (apiBasePath: string, locale: string) =>
    `${apiBasePath}/security-questions/questions_${locale}.json`,

  getSelectedSecurityQuestionEndpoint: (apiBasePath: string, euId: string) =>
    `${apiBasePath}/users/${euId}/security-questions`,

  getSendRegistrationCodeEndpoint: (apiBasePath: string, euId: string) =>
    `${apiBasePath}/digital/users/send-otp/${euId}`,

  getSendVerificationEmailEndpoint: ({
    apiBasePath,
    clientId,
    euId,
    locale,
  }: GetSendVerificationEmailEndpointParameters) =>
    `${apiBasePath}/users/tickets/verification-email/${euId}?client_id=${clientId}&locale=${locale}`,

  getUpdateAuthenticationMethodEndpoint: (
    apiBasePath: string,
    euId: string,
    authenticationMethodId: string
  ) =>
    `${apiBasePath}/users/${euId}/authentication-methods/${authenticationMethodId}`,

  getUpdatePasswordEndpoint: (apiBasePath: string) =>
    `${apiBasePath}/users/password`,

  getUpdateRegistrationKeysEndpoint: (apiBasePath: string, euId: string) =>
    `${apiBasePath}/users/${euId}/registration-keys`,

  getUpdateSecurityQuestionEndpoint: (
    apiBasePath: string,
    euId: string,
    questionId: string
  ) => `${apiBasePath}/users/${euId}/security-questions/${questionId}`,

  getUpdateUserEndpoint: (apiBasePath: string, euId: string) =>
    `${apiBasePath}/users/${euId}`,

  getVerifyRegistrationEndpoint: (apiBasePath: string, euId: string) =>
    `${apiBasePath}/digital/users/verify-otp/${euId}`,
};

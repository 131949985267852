// libraries
import { useDispatch } from "react-redux";
import { useEffect } from "react";

// utilities
import { dialogOpened } from "../../analytics";
import { useUser } from "../../hooks";

// constants
import { DialogsIds } from "../../common/constants";

// types
import { Dispatch } from "../../redux";

export const useEnterpriseDialogAnalytics = (
  isOpen: boolean,
  dialogId?: DialogsIds
) => {
  const dispatch = useDispatch<Dispatch>();
  const user = useUser();

  useEffect(() => {
    if (dialogId && isOpen && user) {
      dispatch(dialogOpened(user.euId, dialogId));
    }
  }, [dialogId, dispatch, isOpen, user]);
};

// libraries
import classNames from "classnames";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { DISPLAY_NAME } from "../ButtonConstants";

// types
import { BaseButtonProps } from "../ButtonTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Button: React.FC<BaseButtonProps> = ({
  children,
  className,
  color,
  disabled,
  icon,
  loading,
  type,
  ...props
}) => {
  return (
    <button
      className={classNames(
        getClassName({
          modifiers: color,
          states: classNames({
            disabled,
            loading,
          }),
        }),
        className
      )}
      disabled={disabled}
      type={type}
      {...props}
    >
      <span className={getClassName("body")}>{children}</span>
      {icon && <span className={getClassName("icon")}>{icon}</span>}
    </button>
  );
};

Button.displayName = DISPLAY_NAME;

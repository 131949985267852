// components
import { HeadingSection, PrimaryButton } from "../";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./ErrorPagesConstants";

// types
import { ErrorPageProps } from "./ErrorPageTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const ErrorPage: React.FC<ErrorPageProps> = ({
  buttonProps,
  heading,
  icon,
  subheading,
}) => (
  <div className={getClassName()}>
    <HeadingSection
      className={getClassName("heading")}
      heading={heading}
      icon={icon}
      subheading={subheading}
    />
    {buttonProps && <PrimaryButton {...buttonProps} />}
  </div>
);

ErrorPage.displayName = DISPLAY_NAME;

// constants
import {
  UpdatePasswordErrorAction,
  UpdatePasswordErrorActionTypes,
} from "../../common/constants";

// types
import { DialogConfiguration } from "./types";

export const updatePasswordErrorReducer = (
  configuration: DialogConfiguration,
  action: UpdatePasswordErrorAction
): DialogConfiguration => {
  switch (action.type) {
    case UpdatePasswordErrorActionTypes.OPEN_INVALID_PASSWORD_DIALOG:
      return {
        ...configuration,
        body: "Dialog.InvalidPasswordErrorText",
        isOpen: true,
      };
    case UpdatePasswordErrorActionTypes.OPEN_ERROR_DIALOG:
      return {
        ...configuration,
        isOpen: true,
      };
    case UpdatePasswordErrorActionTypes.CLOSE_ERROR_DIALOG:
      return {
        ...configuration,
        isOpen: false,
      };
  }
};

// libraries
import { useState } from "react";

// components
import { FormInput } from "../../../";

// utilities
import { getClassNameFactory } from "../../../../utilities";
import { useDefaultPasswordInputConfiguration } from "./DefaultPasswordInputHooks";

// constants
import { ButtonTypes } from "../../../../common/constants";
import { DISPLAY_NAME } from "./DefaultPasswordInputConstants";

// types
import { PasswordInputProps } from "../../PasswordInputTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const DefaultPasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { icon, inputType, title } =
    useDefaultPasswordInputConfiguration(isPasswordVisible);

  const handleToggle = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={getClassName("wrapper")}>
      <FormInput type={inputType} {...props} />
      <button
        className={getClassName("togglePasswordButton")}
        onClick={handleToggle}
        title={title}
        type={ButtonTypes.BUTTON}
      >
        {icon}
      </button>
    </div>
  );
};

DefaultPasswordInput.displayName = DISPLAY_NAME;

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./StoreLinkConstants";

// types
import { StoreLinkProps } from "./StoreLinkTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const StoreLink: React.FC<StoreLinkProps> = ({ icon, link, name }) => (
  <a className={getClassName()} href={link} rel="noreferrer" target="_blank">
    <div className={getClassName("logo")}>{icon}</div>
    <p className={getClassName("name")}>{name}</p>
  </a>
);

StoreLink.displayName = DISPLAY_NAME;

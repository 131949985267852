// libraries
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

// utilities
import {
  ascendingSort,
  categorizeRegistrationKeysByProduct,
  createProductMap,
  descendingSort,
} from "../../utilities";

import { useRawProducts, useRegistrationKeys } from "../../hooks";

// constants
import { LoadingStates, OrderingState } from "../../common/constants";

// types
import { ProductsArrayState } from "../../redux/products/productsTypes";
import { ProductsView } from "../../common/types";
import { State } from "../../redux";

/**
 * Custom React hook to categorize registration keys into products.
 *
 * @returns An object containing:
 * - `products`: The list of products based on reg keys.
 */
export const useProductsList = (orderBy: OrderingState) => {
  const { isRegistrationKeysListLoading: isLoading, registrationKeysList } =
    useRegistrationKeys();
  const { isProductsListLoading: isProductsLoading, productsList } =
    useRawProducts();
  const [products, setProducts] = useState<ProductsView[] | null>(null);

  const { productArray } = useSelector<State, ProductsArrayState>(
    (state) => state.getProducts
  );

  const productMap = useMemo(
    () => createProductMap(productArray),
    [productArray]
  );

  useEffect(() => {
    if (
      isProductsLoading ||
      isLoading === LoadingStates.IDLE ||
      isLoading === LoadingStates.LOADING
    ) {
      return;
    }

    if (
      registrationKeysList &&
      registrationKeysList.length > 0 &&
      productsList.length > 0
    ) {
      const registrationProducts = categorizeRegistrationKeysByProduct(
        registrationKeysList,
        productMap
      );

      setProducts(
        orderBy === OrderingState.ASCENDING
          ? ascendingSort(registrationProducts)
          : descendingSort(registrationProducts)
      );
    } else {
      setProducts([]);
    }
  }, [
    isLoading,
    isProductsLoading,
    orderBy,
    registrationKeysList,
    productsList,
    productArray,
    productMap,
  ]);

  return { isLoading: products === null, products };
};

// libraries
import { useContext } from "react";

// constants
import { ConfigurationContext } from "./ConfigurationProvider";

/**
 * @description Gets the context for `ConfigurationContext`
 * @returns The `ConfigurationContext` object.
 */
export const useConfiguration = () => useContext(ConfigurationContext);

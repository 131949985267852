// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { ciamFetch } from "../../../utilities";

// constants
import {
  ErrorMessages,
  HttpMethods,
  endpoints,
} from "../../../common/constants";

// types
import {
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./getStatusTypes";

export const getStatus = createAsyncThunk<
  boolean,
  ThunkArguments,
  ThunkConfiguration
>(
  "mfa/getStatus",
  async ({ accessToken, apiBasePath, sub }, { rejectWithValue }) => {
    const url = endpoints.getMfaStatusEndpoint(apiBasePath, sub);

    try {
      const response = await ciamFetch(url, accessToken, {
        method: HttpMethods.GET,
      });

      if (!response.ok) {
        throw await response.text();
      }

      const { multifactor_enrolled: isMfaEnabled }: ResponsePayload =
        await response.json();

      return isMfaEnabled;
    } catch (error) {
      return rejectWithValue({ message: ErrorMessages.GET_MFA_STATUS });
    }
  }
);

// utilities
import { getExitLink, getFormValidationErrorMessage } from "../utilities";

// constants
import { AnalyticsActionsType } from "../constants";
import { DialogsIds, ErrorMessages } from "../../common/constants";
import { ErrorMessage } from "../../hooks";

export const codeVerifiedWithError = (errorMessage: string) => ({
  payload: { errorMessage },
  type: AnalyticsActionsType.CODE_VERIFICATION_ERROR,
});

export const dialogOpened = (euId: string, dialogId: DialogsIds) => ({
  payload: {
    euId,
    pageName: dialogId,
  },
  type: AnalyticsActionsType.DIALOG_OPENED,
});

export const editModeOpened = (euId: string) => ({
  payload: { euId },
  type: AnalyticsActionsType.EDIT_PROFILE_MODE_OPENED,
});

export const exitLinkClicked = async (exitLink: string) => ({
  payload: { exitLink: await getExitLink(exitLink) },
  type: AnalyticsActionsType.EXIT_LINK_CLICKED,
});

export const failedToGetAccessToken = () => ({
  payload: {
    errorMessage: ErrorMessages.GET_ACCESS_TOKEN,
  },
  type: AnalyticsActionsType.ACCESS_TOKEN_ERROR,
});

export const failedToRegisterUser = (errorMessage: string) => ({
  payload: { errorMessage },
  type: AnalyticsActionsType.REGISTRATION_FAILED,
});

export const failedToSendVerificationEmail = (error: any) => {
  const errorMessage =
    (error instanceof Error && error.message) ||
    ErrorMessages.SEND_VERIFICATION_EMAIL;

  return {
    payload: { errorMessage },
    type: AnalyticsActionsType.VERIFICATION_EMAIL_SEND_ERROR,
  };
};

export const failedToUpdatedUserEmail = (error: any) => {
  const errorMessage =
    error instanceof Error ? error.message : ErrorMessages.UPDATE_EMAIL;

  return {
    payload: { errorMessage },
    type: AnalyticsActionsType.UPDATE_EMAIL_FAILED,
  };
};

export const formValidatedWithError = async (
  validationErrors: ErrorMessage[]
) => ({
  payload: {
    errorMessage: await getFormValidationErrorMessage(validationErrors),
  },
  type: AnalyticsActionsType.FORM_VALIDATION_ERROR,
});

export const pageViewed = (path: string) => ({
  payload: { path },
  type: AnalyticsActionsType.PAGE_VIEWED,
});

export const profileEdited = (euId: string) => ({
  payload: { euId },
  type: AnalyticsActionsType.PROFILE_EDITED,
});

export const userCreatedProfile = () => ({
  type: AnalyticsActionsType.PROFILE_CREATED,
});

export const userEnrolledMfaFactor = () => ({
  type: AnalyticsActionsType.MFA_FACTOR_ENROLLED,
});

export const userDeletedMfaFactors = () => ({
  type: AnalyticsActionsType.MFA_FACTORS_DELETE,
});

export const sentVerificationEmail = () => ({
  type: AnalyticsActionsType.VERIFICATION_EMAIL_SENT,
});

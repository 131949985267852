// libraries
import { matchPath } from "react-router";

// constants
import { paths } from "../../common/constants";

// types
import { LocaleUrlParameters } from "../types";

/**
 * @description Parses the url and gets country and language parameters out of it.
 *
 * @param pathname - Path to the page without domain part (e.g. /en-us/profile/test).
 *
 * @returns Object with country and language values.
 */

export const getLocaleFromUrl = (
  pathname: string = window.location.pathname
): LocaleUrlParameters => {
  const result = matchPath<string, string>(
    { path: paths.base + "*" },
    pathname
  );

  return {
    country: result?.params.country ?? "",
    language: result?.params.language ?? "",
  };
};

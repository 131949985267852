export const Send_3_Icon: React.FC = () => (
  <svg
    focusable="false"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#404040" strokeLinecap="round" strokeWidth="2">
      <path d="M60 4L20 36v20l10.9-12.2" />
      <path d="M4 24L60 4 50 58z" />
    </g>
  </svg>
);

// libraries
import classNames from "classnames";
import { SafText } from "@saffron/core-components/react";
import { createElement } from "react";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./HeadingConstants";
import { DatadogPrivacyTypes } from "../../common/constants";

// types
import { HeadingProps } from "./HeadingTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Heading: React.FC<HeadingProps> = ({
  children,
  className,
  ddPrivacyType = DatadogPrivacyTypes.ALLOW,
  isEnterprise,
  rank,
  type,
}) => {
  const HeadingTag = `h${rank}`;

  return isEnterprise
    ? createElement(
        HeadingTag,
        {
          className: getClassName({ className }),
        },
        // @ts-ignore
        <SafText appearance={type} data-dd-privacy={ddPrivacyType}>
          {children}
        </SafText>
      )
    : createElement(
        HeadingTag,
        {
          className: getClassName({
            className,
            modifiers: classNames({
              [type as string]: true,
            }),
          }),
        },
        children
      );
};

Heading.displayName = DISPLAY_NAME;

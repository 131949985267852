export const LockIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 48 66" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1 1)"
    >
      <path
        d="M0 28.863h45.176V64H0zM10.04 28.863V12.549C10.04 5.619 15.656 0 22.587 0h0c6.931 0 12.55 5.618 12.55 12.549v16.314"
        stroke="#404040"
      />
      <circle cx="22.588" cy="43.922" r="5.02" stroke="#FA6400" />
      <path d="M22.588 48.941v5.02" stroke="#FA6400" />
    </g>
  </svg>
);

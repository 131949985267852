export const OpenedEyeIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
    >
      <path d="M2.5 12.5s4-6 10-6 10 6 10 6-4 6-10 6-10-6-10-6Z" />
      <path d="M12.5 14.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
    </g>
  </svg>
);

// libraries
import { Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router";

// constants
import { MAIN_CONTENT_ID } from "../MainContentTag/MainContentTagConstants";
import { paths } from "../../../../common/constants";

export const useComponentWithHashLink = (text: string, isVisible: boolean) => {
  const location = useLocation();
  const routes = Object.values(paths);

  for (let route of routes) {
    const path = `${paths.base}${route}`;
    const pathMatch = matchPath<string, string>(
      {
        path,
      },
      location.pathname
    );

    if (pathMatch) {
      return (
        <Link
          className={isVisible ? "visibleLink" : "hiddenLink"}
          to={{
            hash: `#${MAIN_CONTENT_ID}`,
            pathname: pathMatch.pathname,
          }}
        >
          {text}
        </Link>
      );
    }
  }

  return null;
};

// components
import {
  ConnectionIcon,
  LaptopIcon,
  SmallLockIcon,
} from "../../../../common/icons";

// types
import { BenefitsOption } from "./BenefitsTypes";

export const benefits: BenefitsOption[] = [
  {
    description: "Benefits.Description1",
    heading: "Benefits.Heading1",
    icon: <LaptopIcon />,
  },
  {
    description: "Benefits.Description2",
    heading: "Benefits.Heading2",
    icon: <SmallLockIcon />,
  },
  {
    description: "Benefits.Description3",
    heading: "Benefits.Heading3",
    icon: <ConnectionIcon />,
  },
];

export const DISPLAY_NAME = "Benefits";

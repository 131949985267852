// libraries
import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

// components
import { CrossIcon } from "../../common/icons";
import { Heading } from "../";

// utilities
import { Provider as IsModalOpenProvider } from "./ModalUtilities";
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME, OVERLAY_TEST_ID } from "./ModalConstants";
import { HeadingRank, HeadingTypes } from "../Heading";

// types
import { ModalProps } from "./ModalTypes";

const getFocusableDescendants = (node: Element): NodeListOf<HTMLDivElement> => {
  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Utils.isFocusable`
   */
  const selector = [
    "button:not([disabled])",
    "[href]",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "[tabindex]:not([tabindex='-1'])",
  ].join(", ");

  return node.querySelectorAll(selector);
};

const getModalRoot = (): HTMLElement => {
  const modalRootId = "modalRoot";
  const modalRoot = document.getElementById(modalRootId) as HTMLElement;

  if (!modalRoot) {
    const newElement = document.createElement("div");

    newElement.id = modalRootId;
    document.body.appendChild(newElement);

    return newElement;
  }

  return modalRoot;
};

export class Modal extends React.Component<ModalProps> {
  public static displayName = DISPLAY_NAME;

  // this static member is used as a global variable
  // multiple Modal instances interact with this
  public static mountedModals: Modal[] = [];

  private getClassName = getClassNameFactory(DISPLAY_NAME);
  private contentRef = React.createRef<HTMLDivElement>();
  private dialogRef = React.createRef<HTMLDivElement>();
  private closeRef = React.createRef<HTMLButtonElement>();
  private appRoot = document.getElementById(
    this.props.appRootId || "root"
  ) as HTMLElement;
  private modalRoot = getModalRoot();
  private el = document.createElement("div");
  private lastScrollRatio!: number;
  // @ts-ignore
  private lastFocusEl!: React.RefObject<HTMLDivElement>;
  private ref!: React.RefObject<HTMLDivElement>;
  private activeElementBeforeOpenModal = document.activeElement;

  public componentDidMount(): void {
    /*
     * The portal element is inserted in the DOM tree after
     * the Modal's children are mounted, meaning that children
     * will be mounted on a detached DOM node. If a child
     * component requires to be attached to the DOM tree
     * immediately when mounted, for example to measure a
     * DOM node, or uses 'autoFocus' in a descendant, add
     * state to Modal and only render the children when Modal
     * is inserted in the DOM tree.
     */
    this.modalRoot.appendChild(this.el);

    /**
     * hide all other page content from screen readers
     * https://www.w3.org/TR/wai-aria-1.0/states_and_properties#aria-hidden
     */
    this.appRoot.setAttribute("aria-hidden", "true");

    const scrollTop = window.scrollY || window.pageYOffset || 0;

    this.lastScrollRatio = scrollTop / document.body.scrollHeight;

    document.body.classList.add("is-scrollLocked");

    document.addEventListener("focus", this.handleFocus, true);
    if (!this.props.uncloseable) {
      document.addEventListener("keydown", this.handleKeydown, false);
    }

    this.lastFocusEl = this.contentRef;
    if (this.contentRef.current) {
      this.contentRef.current.focus();
    }

    // According to the accessibility standarts, when Modal is open, the close icon should be focused by default
    if (this.closeRef.current && !this.props.isCloseButtonHidden) {
      this.closeRef.current.focus();
    }

    // close all other modals, and add this instance to the list
    Modal.mountedModals.forEach((modal: Modal): void => modal.handleClose());
    Modal.mountedModals.push(this as Modal);
  }

  public componentWillUnmount(): void {
    this.modalRoot.removeChild(this.el);

    this.appRoot.removeAttribute("aria-hidden");

    document.body.classList.remove("is-scrollLocked");

    document.removeEventListener("focus", this.handleFocus, true);
    if (!this.props.uncloseable) {
      document.removeEventListener("keydown", this.handleKeydown, false);
    }

    const scrollTop = this.lastScrollRatio * document.body.scrollHeight;

    window.scroll(0, scrollTop);

    // remove this instance from the list of mounted modals
    Modal.mountedModals = Modal.mountedModals.filter(
      (modalInstance): boolean => modalInstance !== this
    );

    // Return focus to the last active element before the Modal was opened
    if (this.activeElementBeforeOpenModal) {
      (this.activeElementBeforeOpenModal as HTMLElement).focus();
    }
  }

  public handleClose = (event?: React.SyntheticEvent): void => {
    if (event) {
      event.stopPropagation();
    }

    this.props.closeThisComponent();
  };

  private handleKeydown = (event: KeyboardEvent): void => {
    const ESCAPE = "Escape";

    if (event.key === ESCAPE) {
      this.handleClose();
    }
  };

  private handleOverlayClick = (event: React.SyntheticEvent): void => {
    // close if target element is the overlay, not the dialog
    if (event.target === this.ref.current) {
      this.handleClose(event);
    }
  };

  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Dialog.prototype.trapFocus`
   *
   * this function is not a react event, it is added as a global document eventListener
   * see `componentDidMount`
   */
  private handleFocus = (event: FocusEvent): void => {
    if (!this.dialogRef.current) {
      return;
    }

    const isTargetWithinModal = this.dialogRef.current.contains(
      event.target as Node
    );

    if (isTargetWithinModal) {
      this.lastFocusEl = {
        current: event.target,
      } as React.RefObject<HTMLDivElement>;

      return;
    }

    const focusableDialogDescendants = getFocusableDescendants(
      this.dialogRef.current
    );

    if (!focusableDialogDescendants.length && this.contentRef.current) {
      this.contentRef.current.focus();

      return;
    }

    // Commented as it breaks EditPasswordMode error handling ("Password is too common" error).
    // Focus goes on button instead of input field. Check a11y for other dialogs.
    // if (this.lastFocusEl.current === focusableDialogDescendants[0]) {
    //   focusableDialogDescendants[focusableDialogDescendants.length - 1].focus();
    // } else {
    //   focusableDialogDescendants[0].focus();
    // }

    this.lastFocusEl = {
      current: document.activeElement,
    } as React.RefObject<HTMLDivElement>;
  };

  public render(): JSX.Element {
    /*
     * Bracket the dialog node with two invisible, focusable nodes.
     * While this dialog is open, we use these to make sure that focus never
     * leaves the document even if dialogNode is the first or last node.
     *
     * following example of:
     * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
     * see: `aria.Dialog` constructor
     */

    let a11yPropsContainer = {};

    if (this.props.ariaLabel && !this.props.headerId) {
      a11yPropsContainer = { "aria-label": this.props.ariaLabel };
    } else if (this.props.headerId) {
      a11yPropsContainer = { "aria-labelledby": this.props.headerId };
    }

    const a11yPropsCloseButton = {};

    if (this.props.closeButtonAriaLabel) {
      a11yPropsCloseButton["aria-label"] = this.props.closeButtonAriaLabel;
    }

    if (this.props.headerId) {
      a11yPropsCloseButton["aria-describedby"] = this.props.headerId;
    }

    return ReactDOM.createPortal(
      <IsModalOpenProvider value={true}>
        {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(this.getClassName(), this.props.className)}
          data-testid={OVERLAY_TEST_ID}
          // eslint-disable-next-line
          onClick={this.props.uncloseable ? undefined : this.handleOverlayClick}
          ref={(ref): void => {
            if (ref) {
              this.ref = { current: ref };
            }
          }}
        >
          <div
            {...a11yPropsContainer}
            aria-modal={true}
            className={classNames(
              this.getClassName({
                descendantName: "dialog",
                modifiers: this.props.animation,
              })
            )}
            ref={this.dialogRef}
            role="dialog"
          >
            {!this.props.uncloseable && (
              <div className={this.getClassName({ descendantName: "header" })}>
                <div
                  className={this.getClassName({
                    descendantName: "headerOuter",
                  })}
                >
                  {this.props.headerTitle && (
                    <div
                      className={this.getClassName({
                        descendantName: "headerTitle",
                      })}
                    >
                      <div
                        className={this.getClassName({
                          descendantName: "headerTitleCenter",
                        })}
                      >
                        <Heading
                          rank={HeadingRank.Second}
                          type={HeadingTypes.Small}
                        >
                          <strong>{this.props.headerTitle}</strong>
                        </Heading>
                      </div>
                    </div>
                  )}
                  {!this.props.isCloseButtonHidden && (
                    <div
                      className={this.getClassName({
                        descendantName: "headerInner",
                      })}
                    >
                      <button
                        {...a11yPropsCloseButton}
                        className={this.getClassName({
                          descendantName: "button",
                        })}
                        onClick={this.handleClose}
                        ref={this.closeRef}
                      >
                        {this.props.closeLabel && (
                          <span
                            className={this.getClassName({
                              descendantName: "buttonLabel",
                              utilities: "typographySmallCaps",
                            })}
                          >
                            {this.props.closeLabel}
                          </span>
                        )}
                        <span
                          className={this.getClassName({
                            descendantName: "buttonCross",
                          })}
                        >
                          <CrossIcon />
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              className={this.getClassName({ descendantName: "content" })}
              ref={this.contentRef}
            >
              <div
                className={this.getClassName({
                  descendantName: "contentOuter",
                })}
              >
                <div
                  className={this.getClassName({
                    descendantName: "contentInner",
                  })}
                >
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
          />
        </div>
      </IsModalOpenProvider>,
      this.el
    );
  }
}

// libraries
import { useTranslate } from "@dcl/tools";

// constants
import { InputTypes } from "../../../../common/constants";
import { SafIcon } from "@saffron/core-components/react";

export const useEnterprisePasswordInputConfiguration = (
  isPasswordVisible: boolean
) => {
  const translate = useTranslate();

  const hiddenPasswordConfiguration = {
    icon: <SafIcon appearance="light" icon-name="eye-slash" />,
    inputType: InputTypes.PASSWORD,
    title: translate("AccessibilityHint.ShowPassword"),
  };

  const visiblePasswordConfiguration = {
    icon: <SafIcon appearance="light" icon-name="eye" />,
    inputType: InputTypes.TEXT,
    title: translate("AccessibilityHint.HidePassword"),
  };

  return isPasswordVisible
    ? visiblePasswordConfiguration
    : hiddenPasswordConfiguration;
};

// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import {
  isExpiredPhoneOtpCodeError,
  isInvalidBindingCodeError,
} from "../../../../utilities";
import { verifyFactor } from "../../factors/verifyFactor";

// constants
import { ErrorMessages } from "../../../../common/constants";

// types
import {
  RequestPayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./verifyPhoneCallCodeTypes";

export const verifyPhoneCallCode = createAsyncThunk<
  void,
  ThunkArguments,
  ThunkConfiguration
>(
  "phoneCall/verifyCode",
  async ({ apiBasePath, requestPayload }, { rejectWithValue }) => {
    try {
      return await verifyFactor<RequestPayload>({
        apiBasePath,
        requestPayload,
      });
    } catch (error) {
      let errorMessage;

      if (isInvalidBindingCodeError(error)) {
        errorMessage = ErrorMessages.INVALID_BINDING_CODE_ERROR;
      } else if (isExpiredPhoneOtpCodeError(error)) {
        errorMessage = ErrorMessages.EXPIRED_PHONE_OTP_CODE_ERROR;
      } else {
        errorMessage = ErrorMessages.VERIFY_FACTOR;
      }

      return rejectWithValue({
        message: errorMessage,
      });
    }
  }
);

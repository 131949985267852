// libraries
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

// utilities
import { sendCode } from "../../redux";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { useGetMfaAccessToken } from "../../hooks";

// constants
import { AuthenticatorTypes, MfaFactorTypes } from "../../common/constants";
import { requestPayloadCodeSending } from "./constants";

// types
import { Dispatch } from "../../redux";
import { RawAuth0User } from "../../common/types";

/**
 * @description Sends request to Auth0 to send a code to the specified phone number for MFA authentication.
 * @returns An async function which sends request to Auth0.
 */
export const useSendCode = () => {
  const { user: { locale } = {} }: { user?: RawAuth0User } = useAuth0();
  const {
    auth0: { domain },
  } = useConfiguration();
  const dispatch = useDispatch<Dispatch>();
  const getMfaAccessToken = useGetMfaAccessToken();

  return async (phoneNumber: string, factorType: MfaFactorTypes) => {
    const mfaAccessToken = await getMfaAccessToken();
    const { errorMessage, oobChannels } = requestPayloadCodeSending[factorType];

    const requestPayload = {
      apiBasePath: domain,
      errorMessage,
      locale,
      mfaToken: mfaAccessToken,
      requestPayload: {
        authenticator_types: [AuthenticatorTypes.OOB],
        oob_channels: oobChannels,
        phone_number: phoneNumber,
      },
    };

    return dispatch(sendCode(requestPayload)).unwrap();
  };
};

// libraries
import { useTranslate } from "@dcl/tools";

// components
import LaptopDesktop1x from "../../images/laptop-desktop-1x.png";
import LaptopDesktop1xWebp from "../../images/laptop-desktop-1x.webp";
import LaptopDesktop2x from "../../images/laptop-desktop-2x.png";
import LaptopDesktop2xWebp from "../../images/laptop-desktop-2x.webp";
import LaptopMobile1x from "../../images/laptop-mobile-1x.png";
import LaptopMobile1xWebp from "../../images/laptop-mobile-1x.webp";
import LaptopMobile2x from "../../images/laptop-mobile-2x.png";
import LaptopMobile2xWebp from "../../images/laptop-mobile-2x.webp";
import SpiralDesktop1x from "../../images/spiral-desktop-1x.png";
import SpiralDesktop1xWebp from "../../images/spiral-desktop-1x.webp";
import SpiralDesktop2x from "../../images/spiral-desktop-2x.png";
import SpiralDesktop2xWebp from "../../images/spiral-desktop-2x.webp";
import SpiralMobile1x from "../../images/spiral-mobile-1x.png";
import SpiralMobile1xWebp from "../../images/spiral-mobile-1x.webp";
import SpiralMobile2x from "../../images/spiral-mobile-2x.png";
import SpiralMobile2xWebp from "../../images/spiral-mobile-2x.webp";
import {
  HeadingSection,
  PrimaryButton,
  SecondaryButton,
  Section,
} from "../../../../components";

// utilities
import { getClassNameFactory } from "../../../../utilities";
import { useNavigation } from "../../../../hooks";

// constants
import { DISPLAY_NAME } from "./AboutMainConstants";
import { RoleTypes } from "../../../../common/constants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const AboutMain: React.FC = () => {
  const translate = useTranslate();
  const { paths, redirect } = useNavigation();

  const handleRegistration = () => redirect(paths.registration.index);
  const handleSignIn = () => redirect(paths.profile);

  return (
    <section className={getClassName()}>
      <Section>
        <p className={getClassName("caption")}>
          {translate("AboutMain.Caption")}
        </p>
        <HeadingSection
          className={getClassName("headingSection")}
          heading={translate("AboutMain.Heading")}
          subheading={translate("AboutMain.Subheading")}
        />
        <div className={getClassName("buttons")}>
          <PrimaryButton
            className={getClassName("button")}
            onClick={handleRegistration}
          >
            {translate("AboutMain.RegisterButton")}
          </PrimaryButton>
          <SecondaryButton onClick={handleSignIn}>
            {translate("AboutMain.SignInButton")}
          </SecondaryButton>
        </div>
      </Section>
      <picture>
        <source
          media="(min-width: 768px)"
          srcSet={`${SpiralDesktop1xWebp}, ${SpiralDesktop2xWebp} 2x`}
          type="image/webp"
        />
        <source
          media="(min-width: 768px)"
          srcSet={`${SpiralDesktop1x}, ${SpiralDesktop2x} 2x`}
        />
        <source
          srcSet={`${SpiralMobile1xWebp}, ${SpiralMobile2xWebp} 2x`}
          type="image/webp"
        />
        <source srcSet={`${SpiralMobile1x}, ${SpiralMobile2x} 2x`} />
        <img
          alt=""
          className={getClassName("imageSpiral")}
          role={RoleTypes.PRESENTATION}
          src={SpiralMobile1x}
        />
      </picture>
      <picture>
        <source
          media="(min-width: 768px)"
          srcSet={`${LaptopDesktop1xWebp}, ${LaptopDesktop2xWebp} 2x`}
          type="image/webp"
        />
        <source
          media="(min-width: 768px)"
          srcSet={`${LaptopDesktop1x}, ${LaptopDesktop2x} 2x`}
        />
        <source
          srcSet={`${LaptopMobile1xWebp}, ${LaptopMobile2xWebp} 2x`}
          type="image/webp"
        />
        <source srcSet={`${LaptopMobile1x}, ${LaptopMobile2x} 2x`} />
        <img
          alt=""
          className={getClassName("imageLaptop")}
          role={RoleTypes.PRESENTATION}
          src={LaptopMobile1x}
        />
      </picture>
    </section>
  );
};

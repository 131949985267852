export const Security_1_Icon: React.FC = () => (
  <svg
    height="65"
    viewBox="0 0 65 65"
    width="65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#404040">
      <path
        d="M37 40.548A71.374 71.374 0 0 0 28 40c-11.14 0-18.494 2.331-22.453 4.087A5.977 5.977 0 0 0 2 49.567V60h32"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path d="M52 53a2 2 0 1 0 0 4 2 2 0 1 0 0-4z" fill="#FF6400" />
      <path
        d="M14 16c0-7.732 6.268-14 14-14s14 6.268 14 14-6.268 16-14 16-14-8.268-14-16z"
        fill="none"
        stroke="#404040"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M42 48h20v14H42zM46 48v-8a6 6 0 1 1 12 0v8"
        fill="none"
        stroke="#FF6400"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

// libraries
import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";

// components
import RecoveryCodeDialogContext from "./RecoveryCodeDialogContext";

// utilities
import { regenerateRecoveryCode } from "../../../../redux";
import { useConfiguration } from "../../../../providers/ConfigurationProvider";
import { useGetAccessToken, useUser } from "../../../../hooks";

// types
import { Dispatch } from "../../../../redux";

export const useGetRecoveryCode = () => {
  const dispatch = useDispatch<Dispatch>();
  const {
    ciam: { basePath: apiBasePath },
  } = useConfiguration();
  const { getAccessToken } = useGetAccessToken();
  const { sub } = useUser();

  return useCallback(async () => {
    const accessToken = await getAccessToken();

    return dispatch(
      regenerateRecoveryCode({
        accessToken,
        apiBasePath,
        sub,
      })
    ).unwrap();
  }, [apiBasePath, dispatch, getAccessToken, sub]);
};

export const useRecoveryCodeDialog = () =>
  useContext(RecoveryCodeDialogContext);

export const CheckCircleLargeIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    height="46"
    viewBox="0 0 46 46"
    width="46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m11 23 8 8 16-16"
      stroke="#FA6400"
      strokeLinecap="square"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M23 45c12.15 0 22-9.85 22-22S35.15 1 23 1 1 10.85 1 23s9.85 22 22 22Z"
      stroke="#404040"
      strokeLinecap="square"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

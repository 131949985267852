// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { getAuthGuardianQrCode } from "./getAuthGuardianQrCodeThunk";

// types
import { ReducerState } from "./getAuthGuardianQrCodeTypes";

const initialState: ReducerState = {
  qrCode: "",
};

export const getAuthGuardianQrCodeSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAuthGuardianQrCode.fulfilled, (state, action) => {
      state.qrCode = action.payload.barcode_uri;
    });
  },
  initialState,
  name: "getQrCode",
  reducers: {},
});

// components
import PasswordMobile from "../../images/password-mobile.png";
import PasswordMobileWebp from "../../images/password-mobile.webp";
import ProfileMobile from "../../images/profile-desktop.png";
import ProfileMobileWebp from "../../images/profile-mobile.webp";
import { UsersIcon } from "../../../../common/icons";

// constants
import { RoleTypes } from "../../../../common/constants";

export const ACCORDION_LABEL = "Accordion Control Button Group";
export const DISPLAY_NAME = "Accordion";

export enum ImageTypes {
  ICON = "icon",
  PICTURE = "picture",
}

export const features = [
  {
    description: "Features.ItemDescription1",
    heading: "Features.ItemHeading1",
    image: <UsersIcon />,
    imageTypes: ImageTypes.ICON,
  },
  {
    description: "Features.ItemDescription2",
    heading: "Features.ItemHeading2",
    image: (
      <picture>
        <source srcSet={ProfileMobileWebp} type="image/webp" />
        <img alt="" role={RoleTypes.PRESENTATION} src={ProfileMobile} />
      </picture>
    ),
    imageTypes: ImageTypes.PICTURE,
  },
  {
    description: "Features.ItemDescription3",
    heading: "Features.ItemHeading3",
    image: (
      <picture>
        <source srcSet={PasswordMobileWebp} type="image/webp" />
        <img alt="" role={RoleTypes.PRESENTATION} src={PasswordMobile} />
      </picture>
    ),
    imageTypes: ImageTypes.PICTURE,
  },
];

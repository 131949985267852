// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import {
  isExpiredPhoneOtpCodeError,
  isInvalidBindingCodeError,
} from "../../../../utilities";
import { verifyFactor } from "../../factors/verifyFactor";

// constants
import { ErrorMessages } from "../../../../common/constants";

// types
import {
  RequestPayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./verifyTextMessageCodeTypes";

export const verifyTextMessageCode = createAsyncThunk<
  void,
  ThunkArguments,
  ThunkConfiguration
>(
  "textMessage/verifyCode",
  async ({ apiBasePath, requestPayload }, { rejectWithValue }) => {
    try {
      const headers = {
        Authorization: `Bearer ${requestPayload.mfa_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      };

      return await verifyFactor<RequestPayload>({
        apiBasePath,
        headers,
        requestPayload,
      });
    } catch (error) {
      let errorMessage;

      if (isInvalidBindingCodeError(error)) {
        errorMessage = ErrorMessages.INVALID_BINDING_CODE_ERROR;
      } else if (isExpiredPhoneOtpCodeError(error)) {
        errorMessage = ErrorMessages.EXPIRED_PHONE_OTP_CODE_ERROR;
      } else {
        errorMessage = ErrorMessages.VERIFY_FACTOR;
      }

      return rejectWithValue({
        message: errorMessage,
      });
    }
  }
);

// library
import { SafAnchor, SafIcon } from "@saffron/core-components/react";
import { useTranslate } from "@dcl/tools";

// components
import { HeaderEnterprise } from "./components/HeaderEnterprise";
import { HeaderLogoIcon } from "../../../../common/icons";
import { Section } from "../../..";
import { SkipToMainContent } from "../SkipToMainContent";

// utilities
import { getClassNameFactory } from "../../../../utilities";
import { useFeatureToggle } from "../../../../providers/FeatureToggleProvider";

// constants
import {
  AccessibilityClassnames,
  RoleTypes,
} from "../../../../common/constants";
import { DISPLAY_NAME } from "./HeaderConstants";
import { FeatureNames } from "../../../../providers/FeatureToggleProvider";

const getClassName = getClassNameFactory(DISPLAY_NAME);

const Header: React.FC = () => {
  const translate = useTranslate();

  return (
    <header className={getClassName()} role={RoleTypes.BANNER}>
      <Section className={getClassName("container")}>
        <SkipToMainContent />
        {/* Temporary returned to hash until AB#711546 is done */}
        {/* eslint-disable-next-line */}
        <a className={getClassName("logoLink")} href="#">
          <span className={AccessibilityClassnames.VISUALLY_HIDDEN}>
            {translate("AccessibilityHint.CompanyName")}
          </span>
          <HeaderLogoIcon />
        </a>
        <SafAnchor
          className={getClassName("supportLink")}
          href={translate("Link.HelpSupport")}
          rel="noreferrer"
          target="_blank"
        >
          <SafIcon icon-name="question-circle" size={16} />
          <span className={getClassName("supportText")}>
            {translate("Header.SupportLink")}
          </span>
        </SafAnchor>
      </Section>
    </header>
  );
};

Header.displayName = DISPLAY_NAME;

export default (function () {
  const isNewFlowEnabled = useFeatureToggle(FeatureNames.PROFILE_PAGE_NEW_FLOW);

  return isNewFlowEnabled ? <HeaderEnterprise /> : <Header />;
});

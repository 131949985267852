// libraries
import IdleTimer from "react-idle-timer";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { Dialog, EnterpriseDialog } from "..";
import { WatchCircle_2_Icon as WatchCircleIcon } from "../../common/icons";

// utilities
import { getClassNameFactory, getFullPath } from "../../utilities";
import { useFeatureToggle } from "../../providers/FeatureToggleProvider";

// constants
import { DISPLAY_NAME } from "./IdleTimerContainerConstants";
import { DialogsIds, paths } from "../../common/constants";
import { FeatureNames } from "../../providers/FeatureToggleProvider";

// types
import { IdleTimerProps } from "./IdleTimerContainerTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const IdleTimerContainer: React.FC<IdleTimerProps> = ({
  timeBeforeWarning,
  timeLeftBeforeLogOut,
}) => {
  const idleTimerRef = useRef(null);
  const logOutTimerRef = useRef<NodeJS.Timeout | null>(null);
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isNewFlowEnabled = useFeatureToggle(FeatureNames.PROFILE_PAGE_NEW_FLOW);

  const { logout } = useAuth0();

  const idleThreshold = timeBeforeWarning * 60 * 1000;
  const logoutThreshold = timeLeftBeforeLogOut * 60 * 1000;

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);
  const handleSignOut = useCallback(() => {
    handleDialogClose();
    logout({
      returnTo: getFullPath(paths.timeout),
    });
  }, [logout]);

  useEffect(() => {
    if (isDialogOpen) {
      logOutTimerRef.current = setTimeout(handleSignOut, logoutThreshold);
    } else {
      clearTimeout(logOutTimerRef.current as NodeJS.Timeout);
    }

    return () => clearTimeout(logOutTimerRef.current as NodeJS.Timeout);
  }, [handleSignOut, isDialogOpen, logoutThreshold]);

  return (
    <>
      {isNewFlowEnabled ? (
        <EnterpriseDialog
          className={getClassName({ descendantName: "modal" })}
          dialogTitle={translate(
            "IdleTimerContainer.SessionTimeoutDialogHeader"
          )}
          id={DialogsIds.TIME_OUT}
          isOpen={isDialogOpen}
          onClose={handleDialogClose}
          onPrimaryButtonClick={handleDialogClose}
          onSecondaryButtonClick={handleSignOut}
          primaryButtonText={translate(
            "IdleTimerContainer.SessionTimeoutConfirmButton"
          )}
          secondaryButtonText={translate("Button.SignOut")}
        >
          <p>{translate("IdleTimerContainer.SessionTimeoutDialogBody")}</p>
        </EnterpriseDialog>
      ) : (
        <Dialog
          body={translate("IdleTimerContainer.SessionTimeoutDialogBody")}
          buttonText={translate(
            "IdleTimerContainer.SessionTimeoutConfirmButton"
          )}
          className={getClassName()}
          headerIcon={<WatchCircleIcon />}
          headerText={translate(
            "IdleTimerContainer.SessionTimeoutDialogHeader"
          )}
          isOpen={isDialogOpen}
          onClickButton={handleDialogClose}
          onClickSecondaryButton={handleSignOut}
          onClose={handleDialogClose}
          secondaryButtonText={translate("Button.SignOut")}
        />
      )}
      <IdleTimer
        onIdle={handleDialogOpen}
        ref={idleTimerRef}
        timeout={idleThreshold}
      />
    </>
  );
};

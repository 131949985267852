// libraries
import ReactDOM from "react-dom";
import classNames from "classnames";
import {
  SafButton,
  SafIcon,
  SafMenuItem,
} from "@saffron/core-components/react";
import { SafMenuItemInstance } from "@saffron/core-components";
import { useEffect, useRef, useState } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { Heading } from "../../../../../components";

// utilities
import { checkPathsMatch } from "../../../AppContainerUtilities";
import { getClassNameFactory } from "../../../../../utilities";
import { useNavigation, useUser } from "../../../../../hooks";

// constants
import { DIALOG_TITLE_ID, DISPLAY_NAME } from "../MenuConstants";
import {
  FeatureNames,
  useFeatureToggle,
} from "../../../../../providers/FeatureToggleProvider";
import { HeadingRank } from "../../../../../components/Heading";
import {
  KeyboardKeys,
  paths,
  safTextAppearance,
} from "../../../../../common/constants";
import {
  SAFFRON_SELECTED_CLASSNAME,
  pageNavigationConfiguration,
} from "../../../AppContainerConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Modal = ({ onClose }) => {
  const isRegKeyManagementEnabled = useFeatureToggle(
    FeatureNames.REG_KEY_MANAGEMENT
  );
  const modalRef = useRef<HTMLDialogElement>(null);
  const translate = useTranslate();
  const { federatedUserId } = useUser();
  const { redirect } = useNavigation();
  const [lastActiveElement, setLastActiveElement] = useState(
    () => window.document?.activeElement as HTMLElement | null
  );

  const pathname = window.location.pathname;

  useEffect(() => {
    modalRef.current?.focus();

    return () => {
      if (lastActiveElement) {
        lastActiveElement?.focus();
        setLastActiveElement(null);
      }
    };
  }, [lastActiveElement]);

  useEffect(() => {
    const handleKeyboardEvents = (event: KeyboardEvent) => {
      const pressingEscape = event.key === KeyboardKeys.ESCAPE;

      if (pressingEscape) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyboardEvents);

    return () => {
      document.removeEventListener("keydown", handleKeyboardEvents);
    };
  }, [onClose]);

  const handleKeyDown = (
    event: React.KeyboardEvent<SafMenuItemInstance>,
    callback: () => void
  ) => {
    if (event.key === KeyboardKeys.ENTER || event.key === KeyboardKeys.SPACE) {
      callback();
    }
  };

  return ReactDOM.createPortal(
    <dialog
      aria-labelledby={DIALOG_TITLE_ID}
      aria-modal="true"
      className={getClassName("dialog")}
      ref={modalRef}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      open
    >
      <div className={getClassName("dialogHeader")}>
        <Heading
          className={getClassName("heading")}
          id={DIALOG_TITLE_ID}
          rank={HeadingRank.Second}
          type={safTextAppearance.HEADING_XL}
          isEnterprise
        >
          {translate("Menu.Heading")}
        </Heading>
        <SafButton
          appearance="tertiary"
          aria-label={translate("AccessibilityHint.Close")}
          icon-only=""
          onClick={onClose}
        >
          <SafIcon icon-name="xmark-large" />
        </SafButton>
      </div>
      <div className={getClassName("dialogBody")}>
        <ul>
          {pageNavigationConfiguration.map(
            ({
              shouldShowInFederatedView,
              iconName,
              path,
              pathPatternToSelectItem,
              textKey,
            }) => {
              if (!shouldShowInFederatedView && federatedUserId) {
                return null;
              }

              if (!isRegKeyManagementEnabled && path === paths.products.index) {
                return null;
              }

              return (
                <li key={textKey}>
                  <SafMenuItem
                    className={classNames(
                      getClassName("item"),
                      checkPathsMatch(pathPatternToSelectItem, pathname) &&
                        SAFFRON_SELECTED_CLASSNAME
                    )}
                    hasLink={false}
                    onClick={() => redirect(path)}
                    onKeyDown={(
                      event: React.KeyboardEvent<SafMenuItemInstance>
                    ) => handleKeyDown(event, () => redirect(path))}
                  >
                    <SafIcon
                      appearance={`${
                        checkPathsMatch(pathPatternToSelectItem, pathname)
                          ? "solid"
                          : "light"
                      }`}
                      iconName={iconName}
                      slot="start"
                    />
                    <span>{translate(textKey)}</span>
                  </SafMenuItem>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </dialog>,
    document.getElementById("modalRoot") as HTMLElement
  );
};

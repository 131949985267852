// utilities
import { isMinimumLengthReached } from "../../hooks/validationHooks"; // don't change to index, it fails tests

// constants
import { passwordRequirements } from "../../common/constants";

const UPPERCASE_LETTER_PATTERN = /[A-Z]/;
const LOWERCASE_LETTER_PATTERN = /[a-z]/;
const NUMBERS_PATTERN = /[0-9]/;

// symbols in unicode: ?|\/[]{}"()'+
const SPECIAL_CHARACTERS_PATTERN =
  /[!#$%&*,-.:;<=>@^_`~\u005B\u005D\u003F\u007C\u007B\u007D\u0028\u0029\u002B\u0022\u0027\u005C\u002F]/u;

export const DISPLAY_NAME = "PasswordRequirements";
export const PASSWORD_REQUIREMENTS_ID = "password-requirements";

/* eslint-disable typescript-sort-keys/string-enum */
export enum Requirements {
  UPPERCASE_LETTERS = "PasswordRequirements.UppercaseLetters",
  LENGTH = "PasswordRequirements.Length",
  LOWERCASE_LETTERS = "PasswordRequirements.LowercaseLetters",
  NUMBERS = "PasswordRequirements.Numbers",
  SPECIAL_CHARACTERS = "PasswordRequirements.SpecialCharacters",
}

export const requirementToPredicateMap: Record<
  Requirements,
  (password: string) => boolean
> = {
  [Requirements.UPPERCASE_LETTERS]: (password) =>
    UPPERCASE_LETTER_PATTERN.test(password),

  [Requirements.LENGTH]: (password) =>
    isMinimumLengthReached(password, passwordRequirements.minimumLength),

  [Requirements.LOWERCASE_LETTERS]: (password) =>
    LOWERCASE_LETTER_PATTERN.test(password),

  [Requirements.NUMBERS]: (password) => NUMBERS_PATTERN.test(password),

  [Requirements.SPECIAL_CHARACTERS]: (password) =>
    SPECIAL_CHARACTERS_PATTERN.test(password),
};

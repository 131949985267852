// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { getFactorsRequest } from "../../../../utilities/mfa";

// constants
import { ErrorMessages } from "../../../../common/constants";

// types
import {
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./getFactorsTypes";

export const getFactors = createAsyncThunk<
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration
>(
  "getMfaFactors/getMfaFactors",
  async ({ apiBasePath, mfaAccessToken }, { rejectWithValue }) => {
    try {
      return await getFactorsRequest({ apiBasePath, mfaAccessToken });
    } catch (error) {
      return rejectWithValue({ message: ErrorMessages.GET_MFA_FACTORS });
    }
  }
);

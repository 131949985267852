// libraries
import PhoneInputWithCountrySelector from "react-phone-number-input";
import classNames from "classnames";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { CountrySelector } from "./components/CountrySelector";
import { InlineErrorMessage } from "../";

// utilities
import {
  getClassNameFactory,
  getErrorId,
  getLocaleFromUrl,
} from "../../utilities";

// constants
import {
  DISPLAY_NAME,
  PHONE_NUMBER_INPUT_ID,
} from "./PhoneNumberInputConstants";

// types
import { PhoneNumberInputProps } from "./PhoneNumberInputTypes";
import {
  getAvailableCountriesList,
  getDefaultCountry,
  getDictionary,
} from "./PhoneNumberInputUtilities";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const PhoneNumberInput = forwardRef(
  (
    {
      className,
      errorMessage,
      isEnterprise,
      labelText,
      onChange,
      onBlur,
      value,
    }: PhoneNumberInputProps,
    ref
  ) => {
    const labelRef = useRef<HTMLLabelElement>();
    const translate = useTranslate();

    const { country, language } = getLocaleFromUrl();

    useImperativeHandle(ref, () => ({
      focus: () => labelRef.current?.click(),
    }));

    return (
      <div className={classNames(getClassName(), className)}>
        <label
          className={getClassName("label")}
          htmlFor={PHONE_NUMBER_INPUT_ID}
          // @ts-expect-error
          ref={labelRef}
        >
          {labelText ?? translate("Field.Phone")}
        </label>
        <PhoneInputWithCountrySelector
          aria-describedby={getErrorId(PHONE_NUMBER_INPUT_ID)}
          className={getClassName({
            descendantName: "inputWrapper",
            modifiers: !!errorMessage && "isInvalid",
          })}
          countries={getAvailableCountriesList()}
          countrySelectComponent={CountrySelector}
          defaultCountry={getDefaultCountry(country)}
          id={PHONE_NUMBER_INPUT_ID}
          labels={getDictionary(language)}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          international
        />
        {errorMessage && (
          <InlineErrorMessage
            className={getClassName("error")}
            errorMessage={errorMessage}
            id={getErrorId(PHONE_NUMBER_INPUT_ID)}
            isEnterprise={isEnterprise}
          />
        )}
      </div>
    );
  }
);

PhoneNumberInput.displayName = DISPLAY_NAME;

// libraries
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

// utilities
import { useConfiguration } from "../providers/ConfigurationProvider";

/**
 * @description Gets access token for MFA.
 * @returns A callback function that can be used to get an MFA access token.
 */
export const useGetMfaAccessToken = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const { mfa: mfaConfiguration } = useConfiguration();

  return useCallback(async () => {
    let token: string;

    try {
      token = await getAccessTokenSilently(mfaConfiguration);
    } catch (error) {
      token = await getAccessTokenWithPopup(mfaConfiguration);
    }

    return token;
  }, [getAccessTokenSilently, getAccessTokenWithPopup, mfaConfiguration]);
};

// constants
import { paths } from "../../common/constants";

// types
import { Dictionary } from "./TranslationProviderTypes";

/**
 * @description Gets the dictionary by provided locale.
 * @param locale - Auth0 or url locale.
 * @returns Json file with translations.
 */

export const getDictionary = async (locale: string): Promise<Dictionary> => {
  const { default: dictionary } = await import(
    /*  webpackChunkName: "dictionary.json" */
    `./dictionaries/${locale}.json`
  );

  return dictionary;
};

/**
 * @description Defines if Auth0 locale should be used instead of url's locale.
 * @param auth0Locale - Locale from Auth0's user.
 * @param urlLocale - Locale from the url.
 * @returns True - if auth0 locale should be user, false - if not.
 */

export const shouldUseAuth0Locale = (auth0Locale: string, urlLocale: string) =>
  window.location.pathname.includes(paths.verifyNewEmail) &&
  auth0Locale !== urlLocale;

// libraries
import { Auth0Provider } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";

// components
import { Loading } from "../../components";
import { LocalizationContext } from "../LocalizationProvider";

// utilities
import { getAuthenticationConfiguration } from "./AuthorizationProviderUtilities";
import { getBasePath } from "../../utilities";
import { useConfiguration } from "../ConfigurationProvider";
import { useRedirect } from "./AuthorizationProviderHooks";

// constatns
import { AUTH0_MAX_AGE } from "./AuthorizationProviderConstants";

/**
 * @description Provides the access to Auth0's SDK functions and objects that allows to login and logout the user and to get user information.
 * Every functionality that requires authentication should be wrapped in the provider.
 */

export const AuthorizationProvider: React.FC = ({ children }) => {
  const handleRedirect = useRedirect();
  const { locale } = useContext(LocalizationContext);
  const { auth0 } = useConfiguration();
  const [location, setLocation] = useState("");

  const { hint } = getAuthenticationConfiguration();

  useEffect(() => {
    if (locale) {
      setLocation(`${window.location.origin}${getBasePath(locale)}`);
    }
  }, [locale]);

  const isLoading = !locale || !location;

  return isLoading ? (
    <Loading />
  ) : (
    <Auth0Provider
      audience={auth0.audience}
      clientId={auth0.clientId}
      domain={auth0.domain.replace("https://", "")}
      hint={hint}
      maxAge={AUTH0_MAX_AGE}
      onRedirectCallback={handleRedirect}
      redirectUri={location}
      scope={auth0.scope}
    >
      {children}
    </Auth0Provider>
  );
};

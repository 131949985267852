// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { getStatus } from "./getStatusThunk";

// types
import { ReducerState } from "./getStatusTypes";

const initialState: ReducerState = {
  error: null,
  isEnabled: false,
  isLoading: true,
};

export const getStatusSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.error = null;
      state.isEnabled = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getStatus.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
        state.isEnabled = false;
        state.isLoading = false;
      }
    });
  },
  initialState,
  name: "getStatus",
  reducers: {
    resetState() {
      return initialState;
    },
  },
});

// libraries
import { EventDefinition } from "redux-beacon";

// constants
import { DatadogTrackLevel } from "../constants";

// types
import {
  ActionEventDefinition,
  AnalyticsEvent,
  ErrorEventDefinition,
} from "../types";

export const trackUserActions =
  (eventDefinition: EventDefinition<ActionEventDefinition>): EventDefinition =>
  (action, prevState, nextState): AnalyticsEvent => {
    const event = eventDefinition(action, prevState, nextState);
    const {
      exitLink = "",
      euId = "",
      factor = "",
      path = "",
      pageName = "",
    } = event;

    return {
      context: {
        enrolledFactor: factor,
        exitLink,
        pageName,
        pageURL: path,
        profileID: euId,
        trackLevel: DatadogTrackLevel.ACTION,
      },
      type: action.type,
    };
  };

export const trackErrors =
  (eventDefinition: EventDefinition<ErrorEventDefinition>): EventDefinition =>
  (action, prevState, nextState): AnalyticsEvent => {
    const event = eventDefinition(action, prevState, nextState);
    const { errorMessage, type } = event;

    return {
      context: { errorMessage, trackLevel: DatadogTrackLevel.ERROR },
      type,
    };
  };

export const trackWarning =
  (eventDefinition: EventDefinition<ErrorEventDefinition>): EventDefinition =>
  (action, prevState, nextState): AnalyticsEvent => {
    const event = eventDefinition(action, prevState, nextState);
    const { errorMessage, type } = event;

    return {
      context: { errorMessage, trackLevel: DatadogTrackLevel.WARNING },
      type,
    };
  };

export const AnalyticsActionsType = {
  ACCESS_TOKEN_ERROR: "ACCESS_TOKEN_ERROR",
  CODE_VERIFICATION_ERROR: "CODE_VERIFICATION_ERROR",
  DIALOG_OPENED: "DIALOG_OPENED",
  EDIT_PROFILE_MODE_OPENED: "EDIT_PROFILE_MODE_OPENED",
  EXIT_LINK_CLICKED: "EXIT_LINK_CLICKED",
  FORM_VALIDATION_ERROR: "FORM_VALIDATION_ERROR",
  GET_STATUS_ERROR: "GET_STATUS_ERROR",
  MFA_FACTOR_ENROLLED: "MFA_FACTOR_ENROLLED",
  MFA_FACTORS_DELETE: "MFA_FACTORS_DELETE",
  PAGE_VIEWED: "PAGE_VIEWED",
  PROFILE_CREATED: "PROFILE_CREATED",
  PROFILE_EDITED: "PROFILE_EDITED",
  REGENERATE_RECOVERY_CODE_ERROR: "REGENERATE_RECOVERY_CODE_ERROR",
  REGISTRATION_FAILED: "REGISTRATION_FAILED",
  UPDATE_EMAIL_FAILED: "UPDATE_EMAIL_FAILED",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
  VERIFICATION_EMAIL_SEND_ERROR: "VERIFICATION_EMAIL_SEND_ERROR",
  VERIFICATION_EMAIL_SENT: "VERIFICATION_EMAIL_SENT",
} as const;

export const DatadogTrackLevel = {
  ACTION: "action",
  ERROR: "error",
  WARNING: "warning",
} as const;

// libraries
import { useCallback, useEffect, useRef } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getNotificationElement } from "./LoadingUtilities";

// constants
import { NOTIFICATION_REMOVING_THRESHOLD } from "./LoadingConstants";

export const useLoadingNotification = () => {
  const translate = useTranslate();
  const notificationElement = useRef<HTMLElement>(getNotificationElement());

  const setLoadingText = useCallback(() => {
    notificationElement.current.textContent = translate("Page.Loading");
  }, [notificationElement, translate]);

  const setFinishedText = useCallback(() => {
    notificationElement.current.textContent = translate("Page.LoadingComplete");
  }, [notificationElement, translate]);

  const cleanNotificationElement = useCallback(() => {
    setFinishedText();
    // there should be a time gap for screenreaders to announce the message
    setTimeout(() => {
      if (notificationElement.current.parentNode) {
        notificationElement.current.parentNode.removeChild(
          notificationElement.current
        );
      }
    }, NOTIFICATION_REMOVING_THRESHOLD);
  }, [setFinishedText, notificationElement]);

  useEffect(() => {
    setLoadingText();

    return () => {
      cleanNotificationElement();
    };
  }, [cleanNotificationElement, setLoadingText]);
};

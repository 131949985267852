// side effects
import "./polyfills";
import "@saffron/core-components";
import "@saffron/core-styles/index.css";
import "./main.css";

// libraries
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// components
import { App, ErrorBoundary } from "./components";
import { Providers } from "./providers";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </ErrorBoundary>
);

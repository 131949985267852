// components
import { DefaultPasswordInput } from "./components/DefaultPasswordInput";
import { EnterprisePasswordInput } from "./components/EnterprisePasswordInput";

// types
import { PasswordInputProps } from "./PasswordInputTypes";

export const PasswordInput: React.FC<PasswordInputProps> = ({
  isEnterprise,
  ...props
}) =>
  isEnterprise ? (
    <EnterprisePasswordInput {...props} />
  ) : (
    <DefaultPasswordInput {...props} />
  );

// libraries
import { createContext } from "react";

// utilities
import { validateConfiguration } from "./ConfigurationProviderUtilities";

// constants
import { DEFAULT_CONFIGURATION } from "./ConfigurationProviderConstants";

// types
import { ApplicationConfiguration } from "./ConfigurationProviderTypes";

export const ConfigurationContext = createContext<ApplicationConfiguration>(
  DEFAULT_CONFIGURATION
);

/**
 * @description Provides different values for Auth0 configurations, CIAM base URL, connections, MFA, ReCAPTCHA, and Split.
 * Every functionality that requires these configuration values should be wrapped in the provider.
 */

export const ConfigurationProvider: React.FC = ({ children }) => {
  const { applicationConfiguration } = window;

  validateConfiguration(applicationConfiguration);

  return (
    <ConfigurationContext.Provider value={applicationConfiguration}>
      {children}
    </ConfigurationContext.Provider>
  );
};

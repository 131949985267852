// libraries
import initializeFeatureToggle, {
  IFeatureToggle as FeatureToggleInstance,
  ISplitFeatToggleConfig as FeatureToggleConfiguration,
} from "@tr-digital/feature-toggle";
import { useEffect, useState } from "react";
import { useFeatureToggle as useReactFeatureToggle } from "@tr-digital/react-feature-toggle";

// utilities
import { useConfiguration } from "../ConfigurationProvider";
import { useNavigation } from "../../hooks";

// constants
import { FeatureNames, USERS_GROUP_KEY } from "./FeatureToggleConstants";
import { GeneralConfigurationErrorCodes } from "../../common/constants";
import { features } from "./FeatureToggleConfiguration";

/**
 * @description Gets the status if the feature is enabled or not in SplitIO.
 * @param feature - feature name of type `FeatureNames`.
 * @returns A boolean whether the feature is enabled or not.
 */
export const useFeatureToggle = (feature: FeatureNames) =>
  useReactFeatureToggle()(feature);

/**
 * @description Initializes a feature toggle instance using the SplitIO library.
 * @returns An instance of `FeatureToggleInstance`.
 * @handlesError Redirect to Generic Error page.
 */
export const useFeatureToggleInstance = () => {
  const [instance, setInstance] =
    useState<FeatureToggleInstance<SplitIO.IClient>>();
  const { paths, redirect } = useNavigation();
  const {
    split: { authorizationKey },
  } = useConfiguration();

  useEffect(() => {
    const configuration: FeatureToggleConfiguration = {
      authorizationKey,
      features,
      key: USERS_GROUP_KEY,
    };

    const getFeatureToggleInstance = async () => {
      try {
        const initialInstance = await initializeFeatureToggle(configuration);

        setInstance(initialInstance);
      } catch {
        redirect(paths.customError, {
          errorCode: GeneralConfigurationErrorCodes.GC0003,
        });
      }
    };

    getFeatureToggleInstance();
  }, [authorizationKey, paths.customError, redirect]);

  return instance;
};

// constants
import { MfaSessionStorageKeys } from "../../common/constants";

// types
import { PhoneData } from "../../common/types";

/**
 * @description Gets MFA Phone data from session storage.
 *
 * @returns Object with phone data
 */

export const getPhoneData = (): PhoneData => {
  const storedData =
    sessionStorage.getItem(MfaSessionStorageKeys.PHONE_DATA) ?? "{}";

  return JSON.parse(storedData);
};

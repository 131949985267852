// libraries
import classNames from "classnames";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./DetailsItemConstants";

// types
import { DatadogPrivacyTypes } from "../../common/constants";
import { DetailsItemProps } from "./DetailsItemTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const DetailsItem = ({
  className,
  header,
  value,
  isEnterprise = false,
}: DetailsItemProps): JSX.Element => {
  return (
    <div className={classNames(getClassName(), className)}>
      <div
        className={getClassName({
          descendantName: "header",
          modifiers: isEnterprise && "enterprise",
        })}
      >
        {header}
      </div>
      <div
        className={getClassName({
          descendantName: "value",
          modifiers: isEnterprise && "enterprise",
        })}
        data-dd-privacy={DatadogPrivacyTypes.MASK}
      >
        {value}
      </div>
    </div>
  );
};

DetailsItem.displayName = DISPLAY_NAME;

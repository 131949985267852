export const CheckCircleIcon: React.FC = () => (
  <svg
    fill="none"
    focusable={false}
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="currentColor" strokeLinecap="square" strokeWidth="2">
      <path d="M6 12l4 4 8-8" />
      <path d="M12 1a11 11 0 1 0 0 22 11 11 0 1 0 0-22z" />
    </g>
  </svg>
);

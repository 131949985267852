// utilities
import { getBasePath } from "../../utilities";

// constants
import { DEFAULT_LOCALE, paths } from "../../common/constants";

export enum Auth0Errors {
  INVALID_STATE = "Invalid state",
  LOGIN_TO_LOCAL_ACCOUNT = "Please use SSO to log in.",
  REMAINING_ATTEMPTS_ERROR = "No remaining attempts",
}

export const oldPaths = {
  mfa: "/2sv",
  profileCreated: "/create-(profile|account)/profile-created",
  registration: "/create-profile",
};

export const redirectConfiguration = [
  {
    newUrl: getBasePath(DEFAULT_LOCALE.toLowerCase()),
    oldUrl: new RegExp("^/$"),
  },
  {
    newUrl: paths.mfa.index,
    oldUrl: new RegExp(oldPaths.mfa),
  },
  {
    newUrl: paths.registration.index,
    oldUrl: new RegExp(oldPaths.registration),
  },
  {
    newUrl: paths.registration.accountCreated,
    oldUrl: new RegExp(oldPaths.profileCreated),
  },
];

// libraries
import { AppState } from "@auth0/auth0-react";

// utilities
import { useNavigation } from "../../hooks";

// types
import { Paths } from "../../common/types";

/**
 * @description Allows for redirection to a given path during callback from `Auth0Provider`.
 * A function that redirects to the `returnTo` path if it is present.
 */
export const useRedirect = () => {
  const { redirect } = useNavigation();

  return ({ returnTo }: AppState) => {
    if (returnTo) {
      redirect(returnTo as Paths);
    }
  };
};

// utilities
import { FieldValidationError } from "../hooks/validationHooks";

// constants
import { ErrorMessages, ResponseStatuses } from "../common/constants";

/**
 * @description Checks if error has message key.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if there is error message, false if it's not.
 */

export const hasErrorMessage = (error: any): error is Pick<Error, "message"> =>
  error.hasOwnProperty("message");

/**
 * @description  Checks if it is error for absent authenticator id.
 *
 * @param responses - List of promises results (resolved or rejected).
 *
 * @returns True if authenticator id is not found, false if it is found.
 */

export const isAuthenticatorPromiseError = (
  responses: PromiseSettledResult<void>[]
) =>
  responses.some(
    (result) =>
      result.status === ResponseStatuses.REJECTED &&
      result.reason.message !== ErrorMessages.AUTHENTICATOR_NOT_FOUND
  );

/**
 * @description Checks if it is too common password error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if password is too common, false if it's not.
 */

export const isCommonPasswordError = (error: any) =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.COMMON_PASSWORD);

/**
 * @description Checks if it is email already exists error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if email already exists, false if it's not.
 */

export const isEmailExistsError = (
  error: any
): error is Pick<Error, "message"> =>
  hasErrorMessage(error) && error.message === ErrorMessages.EMAIL_EXISTS;

/**
 * @description Checks if it is failed factor error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if email factor verification failed, false if it's not.
 */

export const isFailedFactorError = (
  error: any
): error is Pick<Error, "message"> =>
  hasErrorMessage(error) && error.message === ErrorMessages.VERIFY_FACTOR;

/**
 * @description Checks if it is inline validation error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if error is from inline input validation, false if it's not.
 */

export const isFieldValidationError = (
  error: any
): error is FieldValidationError => error instanceof FieldValidationError;

/**
 * @description Checks if it is invalid binding code error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if binding code is invalid, false if it's not.
 */

export const isInvalidBindingCodeError = (
  error: any
): error is Pick<Error, "message"> =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.INVALID_BINDING_CODE_ERROR);

/**
 * @description Checks if it is client id not found error.
 *
 * @param error - Error object.
 *
 * @returns True if client id not found error, false otherwise.
 */

export const isClientIdNotFoundError = (
  error: any
): error is Pick<Error, "message"> =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.GET_PRODUCT_URL_INVALID_ID);

/**
 * @description Checks if it is expired otp code error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if otp code is expired, false if it's not.
 */

export const isExpiredOtpCodeError = (
  error: any
): error is Pick<Error, "message"> =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.EXPIRED_OTP_CODE_ERROR);

/**
 * @description Checks if it is expired otp code error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if otp code is expired, false if it's not.
 */
export const isExpiredPhoneOtpCodeError = (
  error: any
): error is Pick<Error, "message"> =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.EXPIRED_PHONE_OTP_CODE_ERROR);

/**
 * @description Checks if it is invalid otp code error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if otp code is invalid, false if it's not.
 */

export const isInvalidOtpCodeError = (
  error: any
): error is Pick<Error, "message"> =>
  hasErrorMessage(error) &&
  error.message.search(
    new RegExp(ErrorMessages.INVALID_OTP_CODE_ERROR, "i")
  ) !== -1;

/**
 * @description Checks if it is password containing user information error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if password contains user information, false if it's not.
 */

export const isPasswordContainsUserInformationError = (error: any) =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.PASSWORD_CONTAINS_USER_INFORMATION);

/**
 * @description Checks if it is invalid password error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if password is invalid, false if it's not.
 */

export const isPasswordInvalidError = (error: any) =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.INVALID_PASSWORD);

/**
 * @description Checks if the password has been reused and is present in history.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if password has been reused, false if it's not.
 */
export const isPasswordInHistoryError = (error: any) =>
  hasErrorMessage(error) &&
  error.message.includes(ErrorMessages.PASSWORD_IN_HISTORY);

/**
 * @description Checks if it is recaptcha score below the limit error.
 *
 * @param error - Error object from backend response.
 *
 * @returns True if recaptcha score is below the limit, false if it's not.
 */

export const isReCaptchaThresholdError = (error: any) =>
  error.message === ErrorMessages.RECAPTCHA_THRESHOLD;

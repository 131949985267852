// libraries
import { useSelector } from "react-redux";

// constants
import { Locales } from "../common/constants";

// types
import { ReducerState } from "../redux/user/types";
import { State } from "../redux";

export const useUser = () => {
  const { user } = useSelector<State, ReducerState>((state) => state.getUser);

  return {
    avatar: user?.avatar ?? "",
    email: user?.email ?? "",
    euId: user?.euId ?? "",
    federatedUserId: user?.federatedUserId,
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    locale: user?.locale ?? Locales.EN_US,
    sub: user?.sub ?? "",
  };
};

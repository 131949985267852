export const EnvelopeIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" transform="translate(-1 -5)">
      <path
        d="M21 7.047H3V18h18V7.047zM23 5v15H1V5h22z"
        fill="#404040"
        fillRule="nonzero"
      />
      <path
        d="M5.59 9.365L12 14.447l6.479-5.082"
        stroke="#FA6400"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const ChevronDown_2_Icon: React.FC = () => (
  <svg height="9" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      className="accentSvg"
      clipRule="evenodd"
      d="M.816.653a.505.505 0 0 1 .724 0L8 7.24 14.46.653a.505.505 0 0 1 .723 0c.2.204.2.534 0 .738L8.362 8.347a.505.505 0 0 1-.724 0L.816 1.391a.53.53 0 0 1 0-.738Z"
      fill="#404040"
      fillRule="evenodd"
    />
  </svg>
);

export const CopyIcon: React.FC = () => (
  <svg
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path className="accentSvg" d="M6.833 16H1V1h13.333v7.5" />
      <path d="M10.167 8.5h9.167v10.833h-9.167z" />
    </g>
  </svg>
);

// utilities
import {
  isCommonPasswordError,
  isPasswordContainsUserInformationError,
  isPasswordInHistoryError,
} from "../../../utilities";

// constants
import { ErrorMessages } from "../../../common/constants";

// types
import { ThunkError } from "../../../common/types";

/**
 * @description This function is used to get the error code based on the type of error.
 *
 * @param error - The error object that needs to be checked.
 *
 * @returns ErrorMessages - Returns the error message.
 */
export const getErrorCode = (error: ThunkError) => {
  if (isCommonPasswordError(error)) return ErrorMessages.COMMON_PASSWORD;
  if (isPasswordInHistoryError(error)) return ErrorMessages.PASSWORD_IN_HISTORY;
  if (isPasswordContainsUserInformationError(error))
    return ErrorMessages.PASSWORD_CONTAINS_USER_INFORMATION;

  return ErrorMessages.PASSWORD_UPDATE;
};

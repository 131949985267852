// libraries
import { EventsMap } from "redux-beacon";

// utilities
import { getStatus, regenerateRecoveryCode } from "../../redux/mfa";
import {
  trackErrors,
  trackUserActions,
  trackWarning,
} from "./eventDefinitions";
import { updateUser } from "../../redux/user";

// constants
import { AnalyticsActionsType } from "../constants";

export const eventsMap: EventsMap = {
  [AnalyticsActionsType.ACCESS_TOKEN_ERROR]: trackErrors((action) => ({
    errorMessage: action.payload.errorMessage,
    type: action.type,
  })),
  [AnalyticsActionsType.CODE_VERIFICATION_ERROR]: trackWarning((action) => ({
    errorMessage: action.payload.errorMessage,
    type: action.type,
  })),
  [AnalyticsActionsType.DIALOG_OPENED]: trackUserActions((action) => ({
    euId: action.payload.euId,
    pageName: action.payload.pageName,
    type: action.type,
  })),
  [AnalyticsActionsType.EDIT_PROFILE_MODE_OPENED]: trackUserActions(
    (action) => ({
      euId: action.payload.euId,
      type: action.type,
    })
  ),
  [AnalyticsActionsType.EXIT_LINK_CLICKED]: trackUserActions((action) => ({
    exitLink: action.payload.exitLink,
    type: action.type,
  })),
  [AnalyticsActionsType.FORM_VALIDATION_ERROR]: trackWarning((action) => ({
    errorMessage: action.payload.errorMessage,
    type: action.type,
  })),
  [AnalyticsActionsType.MFA_FACTOR_ENROLLED]: trackUserActions(
    (action, previousState, nextState) => ({
      factor: nextState.completeFactorEnrollment.enrolledMfaFactor,
      type: action.type,
    })
  ),
  [AnalyticsActionsType.MFA_FACTORS_DELETE]: trackUserActions((action) => ({
    type: action.type,
  })),
  [AnalyticsActionsType.PAGE_VIEWED]: trackUserActions((action) => ({
    path: action.payload.path,
    type: action.type,
  })),
  [AnalyticsActionsType.PROFILE_CREATED]: trackUserActions((action) => ({
    type: action.type,
  })),
  [AnalyticsActionsType.PROFILE_EDITED]: trackUserActions((action) => ({
    euId: action.payload.euId,
    type: action.type,
  })),
  [AnalyticsActionsType.REGISTRATION_FAILED]: trackErrors((action) => ({
    errorMessage: action.payload.errorMessage,
    type: action.type,
  })),
  [AnalyticsActionsType.VERIFICATION_EMAIL_SENT]: trackUserActions(
    (action) => ({
      type: action.type,
    })
  ),
  [AnalyticsActionsType.VERIFICATION_EMAIL_SEND_ERROR]: trackErrors(
    (action) => ({
      errorMessage: action.payload.errorMessage,
      type: action.type,
    })
  ),
  [AnalyticsActionsType.UPDATE_EMAIL_FAILED]: trackErrors((action) => ({
    errorMessage: action.payload.errorMessage,
    type: action.type,
  })),
  [getStatus.rejected.type]: trackErrors(
    (action, previousState, nextState) => ({
      errorMessage: nextState.getStatus.error.message,
      type: AnalyticsActionsType.GET_STATUS_ERROR,
    })
  ),
  [regenerateRecoveryCode.rejected.type]: trackErrors(
    (action, previousState, nextState) => ({
      errorMessage: nextState.regenerateRecoveryCode.error.message,
      type: AnalyticsActionsType.REGENERATE_RECOVERY_CODE_ERROR,
    })
  ),
  [updateUser.rejected.type]: trackErrors(
    (action, previousState, nextState) => ({
      errorMessage: nextState.getUser.error.message,
      type: AnalyticsActionsType.UPDATE_USER_ERROR,
    })
  ),
};

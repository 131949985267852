// libraries
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// utilities
import { getSecurityQuestionsList } from "../utilities";
import { useConfiguration } from "../providers/ConfigurationProvider";

// types
import { Dispatch, State } from "../redux";
import { ReducerState } from "../redux/securityQuestions/securityQuestionsListTypes";

export const useSecurityQuestionsList = (
  locale: string,
  onHandleError: () => void
) => {
  const dispatch = useDispatch<Dispatch>();
  const {
    mfa: { securityQuestionsPath },
  } = useConfiguration();
  const { locale: currentLocale, securityQuestionsList } = useSelector<
    State,
    ReducerState
  >((state) => state.securityQuestions);

  useEffect(() => {
    if (!securityQuestionsList.length || currentLocale !== locale) {
      getSecurityQuestionsList({
        apiBasePath: securityQuestionsPath,
        dispatch,
        locale,
        onHandleError,
      });
    }
  }, [
    currentLocale,
    dispatch,
    locale,
    onHandleError,
    securityQuestionsList.length,
    securityQuestionsPath,
  ]);
};

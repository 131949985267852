// libraries
import { useRef } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import {
  ButtonTypes,
  KeyboardKeys,
  RoleTypes,
} from "../../../../common/constants";
import { DISPLAY_NAME, features } from "./constants";

// types
import { TabsProps } from "./TabsTypes";

const getButtonId = (index: number) => `tab-${index}`;
const getClassName = getClassNameFactory(DISPLAY_NAME);
const getTabpanelId = (index: number) => `tabpanel-${index}`;

export const Tabs: React.FC<TabsProps> = ({
  activeItemIndex,
  onActivateItem,
}) => {
  const tabRefs = useRef({});
  const translate = useTranslate();

  const handleClick = (index: number) => onActivateItem(index);

  const setFocus = (index: number) => {
    const tab = tabRefs.current[index];

    if (tab) {
      tab.focus();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const count = features.length;

    const nextTab = () => setFocus((activeItemIndex + 1) % count);
    const prevTab = () => setFocus((activeItemIndex - 1 + count) % count);
    const firstTab = () => setFocus(0);
    const lastTab = () => setFocus(count - 1);

    const keyMap = {
      [KeyboardKeys.END]: lastTab,
      [KeyboardKeys.HOME]: firstTab,
      [KeyboardKeys.LEFT_ARROW]: prevTab,
      [KeyboardKeys.RIGHT_ARROW]: nextTab,
    };

    const action = keyMap[event.key];

    if (action) {
      event.preventDefault();
      action();
    }
  };

  return (
    <div className={getClassName()}>
      <div className={getClassName("container")}>
        <div className={getClassName("tablist")} role={RoleTypes.TABLIST}>
          {features.map(({ heading }, index) => (
            <button
              aria-controls={getTabpanelId(index)}
              aria-selected={index === activeItemIndex}
              className={getClassName({
                descendantName: "tab",
                modifiers: index === activeItemIndex && "active",
              })}
              id={getButtonId(index)}
              key={getButtonId(index)}
              onClick={() => handleClick(index)}
              onFocus={() => onActivateItem(index)}
              onKeyDown={onKeyDown}
              ref={(element) => (tabRefs.current[index] = element)}
              role={RoleTypes.TAB}
              tabIndex={index === activeItemIndex ? 0 : -1}
              type={ButtonTypes.BUTTON}
            >
              {translate(heading)}
            </button>
          ))}
        </div>
      </div>
      {features.map(({ description, heading, image }, index) => (
        <div
          aria-labelledby={getButtonId(index)}
          className={getClassName({
            descendantName: "tabpanel",
            modifiers: index === activeItemIndex && "active",
          })}
          id={getTabpanelId(index)}
          key={getTabpanelId(index)}
          role={RoleTypes.TABPANEL}
          tabIndex={index === activeItemIndex ? 0 : -1}
        >
          <div className={getClassName("imageWrapper")}>{image}</div>
          <div className={getClassName("contentWrapper")}>
            <h3 className={getClassName("contentHeading")}>
              {translate(heading)}
            </h3>
            <p className={getClassName("contentDescription")}>
              {translate(description)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

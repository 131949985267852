export enum DatadogConstants {
  DEFAULT_PRIVACY_LEVEL = "mask-user-input",
  HOSTED_ON = "S3",
  INVALID_EMAIL = "Invalid email",
  LOCAL_STORAGE_DATADOG_KEY = "DD_USER",
  MASKED_CHARACTER = "*",
  SERVICE_NAME = "a206750-ciam-profile-rum-analytics",
  SITE_NAME = "datadoghq.com",
}

export const NUMBER_OF_EMAIL_CHARACTERS_TO_SHOW = 3;
export const NUMBER_OF_NAME_CHARACTERS_TO_SHOW = 2;
export const SAMPLE_RATE_PERCENTAGE = 100;

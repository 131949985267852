// utilities
import { sendVerificationEmail } from "../utilities/sendVerificationEmail";
import { useConfiguration } from "../providers/ConfigurationProvider";
import { useGetAccessToken, useUser } from "../hooks";

// constants
import { EmailVerificationStorageKeys } from "../common/constants";

/**
 * @description Sends verification email to the user.
 * It retrieves the access token, client ID, EUID and locale from the configuration and URL parameters.
 * @returns A function that dispatches an action to send a verification email.
 */

export const useSendVerificationEmail = () => {
  const { getAccessToken } = useGetAccessToken();
  const {
    auth0: { clientId },
    ciam: { basePath: apiBasePath },
  } = useConfiguration();
  const { euId, locale } = useUser();

  return async () => {
    const accessToken = await getAccessToken();

    const clientIdFromUrl = sessionStorage.getItem(
      EmailVerificationStorageKeys.CLIENT_ID
    );

    const sendVerificationEmailArguments = {
      accessToken,
      apiBasePath,
      clientId: clientIdFromUrl ?? clientId,
      euId,
      locale,
    };

    await sendVerificationEmail(sendVerificationEmailArguments);
  };
};

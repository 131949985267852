export const GooglePlayIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <linearGradient
      gradientTransform="matrix(1 0 0 -1 0 551.597)"
      gradientUnits="userSpaceOnUse"
      id="a"
      x1="13.641"
      x2="-3.141"
      y1="549.889"
      y2="533.106"
    >
      <stop offset="0" stopColor="#00A0FF" />
      <stop offset=".007" stopColor="#00A1FF" />
      <stop offset=".26" stopColor="#00BEFF" />
      <stop offset=".512" stopColor="#00D2FF" />
      <stop offset=".76" stopColor="#00DFFF" />
      <stop offset="1" stopColor="#00E3FF" />
    </linearGradient>
    <path
      d="M2.3.5c-.3.3-.5.8-.5 1.4V24c0 .6.2 1.1.5 1.4l.1.1 12.4-12.4v-.2L2.3.5z"
      fill="url(#a)"
    />
    <linearGradient
      gradientTransform="matrix(1 0 0 -1 0 551.597)"
      gradientUnits="userSpaceOnUse"
      id="b"
      x1="25.676"
      x2="1.479"
      y1="538.597"
      y2="538.597"
    >
      <stop offset="0" stopColor="#FFE000" />
      <stop offset=".409" stopColor="#FFBD00" />
      <stop offset=".775" stopColor="orange" />
      <stop offset="1" stopColor="#FF9C00" />
    </linearGradient>
    <path
      d="M18.9 17.3l-4.1-4.1V12.9l4.1-4.1h.1l4.9 2.8c1.4.8 1.4 2.1 0 2.9l-5 2.8z"
      fill="url(#b)"
    />
    <linearGradient
      gradientTransform="matrix(1 0 0 -1 0 551.597)"
      gradientUnits="userSpaceOnUse"
      id="c"
      x1="16.668"
      x2="-6.09"
      y1="536.302"
      y2="513.544"
    >
      <stop offset="0" stopColor="#FF3A44" />
      <stop offset="1" stopColor="#C31162" />
    </linearGradient>
    <path
      d="M19 17.2L14.7 13 2.3 25.5c.4.5 1.2.5 2.1 0L19 17.2"
      fill="url(#c)"
    />
    <linearGradient
      gradientTransform="matrix(1 0 0 -1 0 551.597)"
      gradientUnits="userSpaceOnUse"
      id="d"
      x1="-.861"
      x2="9.301"
      y1="558.422"
      y2="548.26"
    >
      <stop offset="0" stopColor="#32A071" />
      <stop offset=".069" stopColor="#2DA771" />
      <stop offset=".476" stopColor="#15CF74" />
      <stop offset=".801" stopColor="#06E775" />
      <stop offset="1" stopColor="#00F076" />
    </linearGradient>
    <path d="M19 8.8L4.4.5C3.5 0 2.7 0 2.3.5L14.7 13 19 8.8z" fill="url(#d)" />
  </svg>
);

// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { getSecurityQuestionsList } from "./securityQuestionsListThunk";

// types
import { initialState } from "./securityQuestionsListConstants";

export const securityQuestionsListSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getSecurityQuestionsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSecurityQuestionsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.locale = action.payload.locale;
      state.securityQuestionsList = action.payload.securityQuestionsList;
    });
    builder.addCase(getSecurityQuestionsList.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
        state.isLoading = false;
      }
    });
  },
  initialState,
  name: "securityQuestionsList",
  reducers: {},
});

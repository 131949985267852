// libraries
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import {
  getCurrentPasswordErrorMessage,
  getNewPasswordErrorMessage,
  getPasswordConfirmationErrorMessage,
} from "./validationHooksUtilities";
import { getRequirements } from "../../components/PasswordRequirements";
import { trim } from "../../utilities";

// types
import { ErrorMessage, Passwords } from "./validationHooksTypes";

/**
 * @description Provides validation for password input field.
 * @returns An object containing `currentPassword`, `newPassword`, and `passwordConfirmation` values and their associated functions (`change`, `errorMessage`, `validate`),
 * as well as the requirements object which says if the requirements are met and provides dictionary key for requirements.
 */
export const usePasswordsValidation = () => {
  const translate = useTranslate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] =
    useState<React.ReactNode>(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] =
    useState<React.ReactNode>(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [
    passwordConfirmationErrorMessage,
    setPasswordConfirmationErrorMessage,
  ] = useState<React.ReactNode>(null);
  const [requirements, setRequirements] = useState(() =>
    getRequirements(newPassword)
  );

  const changeCurrentPassword = (inputValue: string) => {
    setCurrentPassword(inputValue);

    if (currentPasswordErrorMessage) {
      setCurrentPasswordErrorMessage(null);
    }
  };

  const changeNewPassword = (inputValue: string) => {
    setNewPassword(inputValue);
    setRequirements(getRequirements(inputValue));

    if (newPasswordErrorMessage) {
      setNewPasswordErrorMessage(null);
    }
  };

  const changePasswordConfirmation = (inputValue: string) => {
    setPasswordConfirmation(inputValue);

    if (passwordConfirmationErrorMessage) {
      setPasswordConfirmationErrorMessage(null);
    }
  };

  const displayPasswordError = (errorMessage: ErrorMessage) => {
    setNewPasswordErrorMessage(translateErrorMessage(errorMessage));
    setPasswordConfirmation("");
  };

  const getPasswords = (): Passwords => ({
    currentPassword: trim(currentPassword),
    newPassword: trim(newPassword),
    passwordConfirmation: trim(passwordConfirmation),
  });

  const translateErrorMessage = (errorMessage: ErrorMessage | null) =>
    errorMessage && translate(errorMessage.key, [errorMessage.replacements]);

  const validateCurrentPassword = () => {
    const passwords = getPasswords();
    const rawErrorMessage = getCurrentPasswordErrorMessage(passwords);
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setCurrentPassword(passwords.currentPassword);
    setCurrentPasswordErrorMessage(translatedErrorMessage);

    if (passwords.newPassword) {
      validateNewPassword();
    }

    if (passwords.passwordConfirmation) {
      validatePasswordConfirmation();
    }

    return rawErrorMessage;
  };

  const validateNewPassword = () => {
    const passwords = getPasswords();
    const rawErrorMessage = getNewPasswordErrorMessage(passwords);
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setRequirements(getRequirements(passwords.newPassword));
    setNewPassword(passwords.newPassword);
    setNewPasswordErrorMessage(translatedErrorMessage);

    if (passwords.passwordConfirmation) {
      validatePasswordConfirmation();
    }

    return rawErrorMessage;
  };

  const validatePasswordConfirmation = () => {
    const passwords = getPasswords();
    const rawErrorMessage = getPasswordConfirmationErrorMessage(passwords);
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setPasswordConfirmation(passwords.passwordConfirmation);
    setPasswordConfirmationErrorMessage(translatedErrorMessage);

    return rawErrorMessage;
  };

  return {
    currentPassword: {
      change: changeCurrentPassword,
      errorMessage: currentPasswordErrorMessage,
      validate: () => validateCurrentPassword(),
      value: currentPassword,
    },
    displayPasswordError,
    newPassword: {
      change: changeNewPassword,
      errorMessage: newPasswordErrorMessage,
      validate: () => validateNewPassword(),
      value: newPassword,
    },
    passwordConfirmation: {
      change: changePasswordConfirmation,
      errorMessage: passwordConfirmationErrorMessage,
      validate: () => validatePasswordConfirmation(),
      value: passwordConfirmation,
    },
    requirements,
  };
};

// libraries
import { TranslationProvider as Provider } from "@dcl/tools";
import { useAuth0 } from "@auth0/auth0-react";

// components
import { Loading } from "../../components";

// utilities
import { getDictionary } from "./TranslationProviderUtilities";
import { useLocale } from "./TranslationProviderHooks";

/**
 * @description Provides the translations for the user.
 * Every functionality that requires this configuration value should be wrapped in the provider.
 */

export const TranslationProvider: React.FC = ({ children }) => {
  const locale = useLocale();
  const { isLoading: isAuth0Loading } = useAuth0();

  const isLoading = isAuth0Loading || !locale;

  return isLoading ? (
    <Loading />
  ) : (
    <Provider getDictionarySource={getDictionary} locale={locale}>
      {children}
    </Provider>
  );
};

// libraries
import classNames from "classnames";

// components
import { CheckCircleIcon as CheckCircle } from "../../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { DISPLAY_NAME } from "./CheckboxInputConstants";
import { InputTypes } from "../../../../common/constants";

// types
import { CheckboxInputProps } from "./CheckboxInputTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  isDisabled = false,
  label,
  id,
  isError,
  ...inputProps
}) => (
  <div className={getClassName()}>
    <input
      className={getClassName({
        descendantName: "input",
        utilities: "hiddenVisually",
      })}
      disabled={isDisabled}
      id={id}
      type={InputTypes.CHECKBOX}
      {...inputProps}
    />
    <label className={getClassName({ descendantName: "label" })} htmlFor={id}>
      <div
        className={getClassName({
          descendantName: "checkbox",
          modifiers: classNames(isError && "error"),
        })}
      >
        <CheckCircle />
      </div>
      {label}
    </label>
  </div>
);

CheckboxInput.displayName = DISPLAY_NAME;

// components
import { Button } from "./Button";

// constants
import { ButtonColors } from "../ButtonConstants";
import { ButtonTypes } from "../../../../common/constants";

// types
import { ButtonProps } from "../ButtonTypes";

export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  type = ButtonTypes.BUTTON,
  ...props
}) => (
  <Button color={ButtonColors.SECONDARY} type={type} {...props}>
    {children}
  </Button>
);

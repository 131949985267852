// components
import {
  AboutMain,
  Benefits,
  Epilog,
  Features,
  RelatedProducts,
} from "./components";

// utilities
import { getClassNameFactory } from "../../utilities";
import { useMetaTags } from "./AboutPageHooks";

// constants
import { DISPLAY_NAME } from "./AboutPageConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const AboutPage: React.FC = () => {
  useMetaTags();

  return (
    <div className={getClassName()}>
      <AboutMain />
      <Benefits />
      <div
        className={getClassName({
          descendantName: "overview",
          modifiers: "withHeadingBorder",
        })}
      >
        <Features />
        <RelatedProducts />
      </div>
      <Epilog />
    </div>
  );
};

AboutPage.displayName = DISPLAY_NAME;

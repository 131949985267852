// libraries
import { createContext } from "react";

// types
import { RecoveryCodeDialogControls } from "./RecoveryCodeDialogTypes";

const RecoveryCodeDialogContext = createContext(
  {} as RecoveryCodeDialogControls
);

export default RecoveryCodeDialogContext;

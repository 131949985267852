// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { updateUser as updateUserInformation } from "../../utilities";

// constants
import { ErrorMessages } from "../../common/constants";
import { ProfileManagementError } from "../../utilities";

// types
import { Auth0User } from "../../common/types";
import { ThunkConfiguration, UpdateUserThunkArguments } from "./types";

export const updateUser = createAsyncThunk<
  Auth0User,
  UpdateUserThunkArguments,
  ThunkConfiguration
>("user/updateUser", async (updateUserArguments, { rejectWithValue }) => {
  try {
    return await updateUserInformation(updateUserArguments);
  } catch (error) {
    if (error instanceof ProfileManagementError) {
      return rejectWithValue({
        errorCode: error.errorCode,
        message: error.message,
      });
    }

    return rejectWithValue({
      message: ErrorMessages.UPDATE_USER,
    });
  }
});

// libraries
import { createSlice, isAnyOf } from "@reduxjs/toolkit";

// utilities
import {
  confirmAuthGuardianEnrollment,
  getAuthGuardianQrCode,
  getOtpAppQrCode,
  regenerateRecoveryCode,
  sendCode,
  verifyOtpAppCode,
  verifyPhoneCallCode,
  verifyTextMessageCode,
} from "../..";

// constants
import { actionToFactorNameMap } from "./completeFactorEnrollmentConstants";

// types
import { ReducerState } from "./completeFactorEnrollmentTypes";

const initialState: ReducerState = {
  enrolledMfaFactor: "",
  enrolledRecoveryCode: "",
};

export const completeFactorEnrollmentSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(
        regenerateRecoveryCode.fulfilled,
        (state, { payload: recoveryCode }) => {
          state.enrolledRecoveryCode = recoveryCode;
        }
      )
      .addMatcher(
        isAnyOf(
          getAuthGuardianQrCode.fulfilled,
          getOtpAppQrCode.fulfilled,
          sendCode.fulfilled
        ),
        (state, { payload: { recovery_codes: recoveryCodes } }) => {
          if (recoveryCodes) {
            [state.enrolledRecoveryCode] = recoveryCodes;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          confirmAuthGuardianEnrollment.fulfilled,
          regenerateRecoveryCode.fulfilled,
          verifyPhoneCallCode.fulfilled,
          verifyOtpAppCode.fulfilled,
          verifyTextMessageCode.fulfilled
        ),
        (state, action) => {
          state.enrolledMfaFactor = actionToFactorNameMap[action.type];
        }
      );
  },
  initialState,
  name: "completeEnrollment",
  reducers: {
    resetRecoveryCode(state) {
      state.enrolledRecoveryCode = "";
    },
    resetState() {
      return initialState;
    },
  },
});

// libraries
import { useTranslate } from "@dcl/tools";

// components
import { TextButton } from "../../../../..";

// utilities
import { getClassNameFactory } from "../../../../../../utilities";

// constants
import { AccessibilityClassnames } from "../../../../../../common/constants";
import { DISPLAY_NAME } from "./DefaultCodeLabelConstants";

// types
import { CodeLabelProps } from "../../CodeLabelTypes";
import { TextButtonWeightTypes } from "../../../../../Buttons";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const DefaultCodeLabel: React.FC<CodeLabelProps> = ({
  codeLength,
  id,
  secondaryLabelText,
  onResendCode,
  resendCodeButtonText,
}) => {
  const translate = useTranslate();

  const secondaryLabel =
    secondaryLabelText ?? translate("VerificationCode.SecondaryLabel");

  return (
    <>
      <span className={getClassName("primaryLabel")} id={id}>
        {translate("VerificationCode.Label", [codeLength])}
        <span className={AccessibilityClassnames.VISUALLY_HIDDEN}>
          {secondaryLabel}
        </span>
      </span>
      {onResendCode && (
        <TextButton
          onClick={onResendCode}
          weight={TextButtonWeightTypes.NORMAL}
        >
          {resendCodeButtonText ??
            translate("VerificationCode.ResendCodeButton")}
        </TextButton>
      )}
      <span aria-hidden={true} className={getClassName("secondaryLabel")}>
        {secondaryLabel}
      </span>
    </>
  );
};

DefaultCodeLabel.displayName = DISPLAY_NAME;

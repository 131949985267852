// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { getFactors } from "./getFactorsThunk";

// types
import { ReducerState } from "./getFactorsTypes";
import {
  getEnrolledMfaFactors,
  getInactiveMfaFactors,
  removeRedundantFactors,
} from "../../../../utilities";

const initialState: ReducerState = {
  enrolledFactors: [],
  error: null,
  inactiveFactors: [],
  isLoading: false,
};

export const getFactorsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getFactors.fulfilled, (state, action) => {
      const factors = removeRedundantFactors(action.payload.rawFactors);

      state.error = null;
      state.enrolledFactors = getEnrolledMfaFactors(factors);
      state.inactiveFactors = getInactiveMfaFactors(factors);
      state.isLoading = false;
    });
    builder.addCase(getFactors.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
        state.isLoading = false;
      }
    });
  },
  initialState,
  name: "getFactors",
  reducers: {
    resetInactiveFactors(state: ReducerState) {
      state.inactiveFactors = [];
    },
    resetState() {
      return initialState;
    },
    setLoading(state: ReducerState) {
      state.isLoading = true;
    },
  },
});

// libraries
import { ITranslationTable } from "@dcl/tools";
import { datadogRum } from "@datadog/browser-rum";

// constants
import { ErrorMessage } from "../../hooks";

// types
import { AnalyticsEvent } from "../types";

const getDefaultDictionary = async () => {
  const { default: dictionary } = await import(
    /*  webpackChunkName: "dictionary.json" */
    "../../providers/TranslationProvider/dictionaries/en-us.json"
  );

  return dictionary;
};

export const getExitLink = async (exitLink: string) => {
  const dictionary: ITranslationTable = await getDefaultDictionary();

  return dictionary[exitLink] || exitLink;
};

export const getFormValidationErrorMessage = async (
  errorMessages: ErrorMessage[]
) => {
  const dictionary: ITranslationTable = await getDefaultDictionary();

  return errorMessages
    .map(({ key, replacements }) =>
      dictionary[key].replace(
        /\{(\d+)\}/g,
        // @ts-ignore
        (match: string, index: number) => replacements?.[index]
      )
    )
    .join(", ");
};

export const sendRUMCustomActions = ({
  context: datadogAttributes,
  type: datadogActionName,
}: AnalyticsEvent) =>
  datadogRum.addAction(datadogActionName, datadogAttributes);

export const sendRUMCustomError = ({
  context: datadogAttributes,
  type: datadogErrorName,
}: AnalyticsEvent) => datadogRum.addError(datadogErrorName, datadogAttributes);

export const printEvent = ({ context, type }: AnalyticsEvent) => {
  console.groupCollapsed("%c[Analytics]:", "color: tomato", `${type} event`);
  console.log("Event context", context);
  console.groupEnd();
};

// constants
import { CopyCodeButtonText, DELAY_TIME } from "./CopyCodeButtonConstants";

// types
import {
  Action,
  ActionTypes,
  CopyButtonConfiguration,
} from "./CopyCodeButtonTypes";

export const reducer = (
  configuration: CopyButtonConfiguration,
  { type }: Action
): CopyButtonConfiguration => {
  switch (type) {
    case ActionTypes.COPY_CODE:
      return {
        ...configuration,
        iconName: "copy",
        isLoading: false,
        text: CopyCodeButtonText.COPY_CODE,
      };
    case ActionTypes.LOADING:
      return {
        ...configuration,
        iconName: "",
        isLoading: true,
        text: CopyCodeButtonText.LOADING,
      };
    case ActionTypes.COPIED_CODE:
      return {
        ...configuration,
        iconName: "check",
        isLoading: false,
        text: CopyCodeButtonText.COPIED_CODE,
      };
  }
};

export const changeStateWithDelay = (onChangeState: () => void) =>
  new Promise((resolve) =>
    setTimeout(() => {
      onChangeState();
      resolve("change");
    }, DELAY_TIME)
  );

// libraries
import classNames from "classnames";
import {
  SafBreadcrumb,
  SafBreadcrumbItem,
} from "@saffron/core-components/react";

// components
import { Link } from "../.";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./BreadcrumbsConstants";
import { DatadogPrivacyTypes } from "../../common/constants";

// types
import { BreadcrumbsProps } from "./BreadcrumbsTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  className,
  ddPrivacyType = DatadogPrivacyTypes.ALLOW,
}) => {
  return (
    <SafBreadcrumb className={classNames(getClassName(), className)}>
      {breadcrumbs.map(({ href, text }) => {
        return href ? (
          <Link
            className={getClassName("link")}
            key={text}
            tabIndex={-1}
            to={href}
          >
            <SafBreadcrumbItem href={href}>{text}</SafBreadcrumbItem>
          </Link>
        ) : (
          <SafBreadcrumbItem data-dd-privacy={ddPrivacyType} key={text}>
            {text}
          </SafBreadcrumbItem>
        );
      })}
    </SafBreadcrumb>
  );
};

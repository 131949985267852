// components
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";
import { SkipToMainContent } from "../../SkipToMainContent";

// utilities
import { getClassNameFactory } from "../../../../../utilities";

// constants
import { ENTERPRISE_DISPLAY_NAME } from "../HeaderConstants";
import { RoleTypes } from "../../../../../common/constants";

const getClassName = getClassNameFactory(ENTERPRISE_DISPLAY_NAME);

export const HeaderEnterprise: React.FC = () => (
  <header className={getClassName()} role={RoleTypes.BANNER}>
    <SkipToMainContent />
    <DesktopHeader />
    <MobileHeader />
  </header>
);

HeaderEnterprise.displayName = ENTERPRISE_DISPLAY_NAME;

// libraries
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getErrorMessageFactory } from ".";
import { trim } from "../../utilities";

// types
import { ErrorMessage, ValidationRule } from "./validationHooksTypes";

/**
 * @description Provides validation for form text input field.
 * @param Object - An object containing the default input value and validation rules which can be found in `validationHooksConstants`.
 * @returns An object containing the following methods and properties:
 * - `change`: A function that sets the value of the input.
 * - `errorMessage`: Translated error message.
 * - `setErrorMessage`: A function that sets an error message for the input.
 * - `validate`: A function that validates the input against a set of validation rules and returns an error message, if any.
 * - `value`: The current value of the input.
 */
export const useInputValidation = <T>({
  defaultInputValue = "",
  validationRules,
}: {
  validationRules: ValidationRule<T>[];
  defaultInputValue?: string;
}) => {
  const translate = useTranslate();
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [value, setValue] = useState(defaultInputValue);
  const getErrorMessage = getErrorMessageFactory(validationRules);

  const translateErrorMessage = (message: ErrorMessage | null) =>
    message && translate(message.key, [message.replacements]);

  const change = (inputValue: string) => {
    setValue(inputValue);

    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const validate = (formData?: T) => {
    const trimmedInputValue = trim(value);
    const rawErrorMessage = getErrorMessage(
      (formData ?? trimmedInputValue) as T
    );
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setValue(trimmedInputValue);
    setErrorMessage(translatedErrorMessage);

    return rawErrorMessage;
  };

  return {
    change,
    clearErrorMessage: () => setErrorMessage(null),
    errorMessage,
    setErrorMessage: (message: ErrorMessage) =>
      setErrorMessage(translateErrorMessage(message)),
    validate,
    value,
  };
};

// utilities
import {
  printEvent,
  sendRUMCustomActions,
  sendRUMCustomError,
} from "../utilities";

// constants
import { DatadogTrackLevel } from "../constants";
import { IS_DEV } from "../../env";

// types
import { AnalyticsEvent } from "../types";

export const getDataDogTracker = ([event]: AnalyticsEvent[]) => {
  switch (event.context.trackLevel) {
    case DatadogTrackLevel.ACTION:
    case DatadogTrackLevel.WARNING:
      sendRUMCustomActions(event);
      break;
    case DatadogTrackLevel.ERROR:
      sendRUMCustomError(event);
      break;
  }

  if (IS_DEV) {
    printEvent(event);
  }
};

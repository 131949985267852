const DEFAULT_CONFIGURATION_VALUE = "";

export const DEFAULT_CONFIGURATION = {
  auth0: {
    audience: DEFAULT_CONFIGURATION_VALUE,
    clientId: DEFAULT_CONFIGURATION_VALUE,
    domain: DEFAULT_CONFIGURATION_VALUE,
    scope: DEFAULT_CONFIGURATION_VALUE,
  },
  ciam: {
    basePath: DEFAULT_CONFIGURATION_VALUE,
  },
  connections: {
    federated: DEFAULT_CONFIGURATION_VALUE,
    local: DEFAULT_CONFIGURATION_VALUE,
  },
  datadog: {
    applicationId: "application-id",
    clientToken: "client-token",
    environment: "environment-name",
  },
  mfa: {
    audience: DEFAULT_CONFIGURATION_VALUE,
    scope: DEFAULT_CONFIGURATION_VALUE,
    securityQuestionsPath: DEFAULT_CONFIGURATION_VALUE,
  },
  reCaptcha: {
    siteKey: DEFAULT_CONFIGURATION_VALUE,
  },
  split: {
    authorizationKey: DEFAULT_CONFIGURATION_VALUE,
  },
};

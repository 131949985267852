// libraries
import { DefaultInputComponentProps } from "react-phone-number-input";
import { useCallback, useMemo, useState } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { CountryFilter } from "./CountryFilter";
import { CountrySelectorHeader } from "./CountrySelectorHeader";
import { Dropdown } from "../../";

// utilities
import { getClassNameFactory } from "../../../utilities";
import { getCountryOptionLabel } from "../PhoneNumberInputUtilities";

// constants
import {
  COUNTRY_SELECTOR_DISPLAY_NAME,
  MAX_DROPDOWN_VISIBLE_ITEMS,
} from "./constants";

const getClassName = getClassNameFactory(COUNTRY_SELECTOR_DISPLAY_NAME);

export const CountrySelector = ({
  iconComponent: CountryIcon,
  onChange,
  options: rawOptions,
  value: selectedCountry,
}: DefaultInputComponentProps) => {
  const translate = useTranslate();
  const [filterValue, setFilterValue] = useState("");

  const filteredOptions = useMemo(
    () =>
      rawOptions!
        .map(({ label, value }) => ({
          label: getCountryOptionLabel(label, value),
          value,
        }))
        .filter(({ label }) =>
          label.toLowerCase().startsWith(filterValue.toLowerCase())
        ),
    [filterValue, rawOptions]
  );

  const isEmptyOptionsList = filteredOptions.length === 0;

  const handleChange = useCallback(
    (value?: React.ReactNode) => onChange!(value as string),
    [onChange]
  );

  const dropdownCollapseHandler = (handler: () => void) => {
    setFilterValue("");
    handler();
  };

  const renderFilter: (
    dropdownCollapse: () => void,
    containerRef: React.RefObject<HTMLInputElement>,
    inputId: string,
    isExpanded?: boolean
  ) => JSX.Element = useCallback(
    (dropdownCollapse, containerRef, inputId, isExpanded) => (
      <CountryFilter
        inputId={inputId}
        isExpanded={isExpanded}
        onClose={dropdownCollapse}
        ref={containerRef}
        setValue={setFilterValue}
        shouldShowNothingFoundWarning={isEmptyOptionsList}
        value={filterValue}
      />
    ),
    [filterValue, isEmptyOptionsList]
  );

  const renderHeader: (
    text: string,
    isExpanded: boolean,
    toggleExpand: () => void,
    expandButtonId: string
  ) => JSX.Element = useCallback(
    (text, isExpanded, toggleExpand, expandButtonId) => (
      <CountrySelectorHeader
        expandButtonId={expandButtonId}
        icon={
          <CountryIcon
            country={selectedCountry}
            label={translate("CountryIcon.Label")}
          />
        }
        isExpanded={isExpanded}
        toggleExpand={() => dropdownCollapseHandler(toggleExpand)}
      />
    ),
    [CountryIcon, selectedCountry, translate]
  );

  return (
    <Dropdown
      className={getClassName({
        modifiers: isEmptyOptionsList && "nothingFound",
      })}
      items={filteredOptions}
      key={selectedCountry}
      maxVisibleItems={MAX_DROPDOWN_VISIBLE_ITEMS}
      onValueChanged={handleChange}
      placeholder={translate("CountrySelector.DropdownHeaderPlaceholder")}
      renderFilter={renderFilter}
      renderHeader={renderHeader}
      value={selectedCountry}
    />
  );
};

CountrySelector.displayName = COUNTRY_SELECTOR_DISPLAY_NAME;

// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// constants
import { ErrorMessages, HttpMethods, endpoints } from "../../common/constants";

// types
import {
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./securityQuestionsListTypes";
import { SecurityQuestion } from "../../common/types";

export const getSecurityQuestionsList = createAsyncThunk<
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration
>(
  "securityQuestions/securityQuestionsList",
  async ({ apiBasePath, locale }, { rejectWithValue }) => {
    const url = endpoints.getSecurityQuestionsListEndpoint(apiBasePath, locale);

    const options = {
      headers: {
        Accept: "application/json",
      },
      method: HttpMethods.GET,
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw await response.text();
      }

      const securityQuestionsList: SecurityQuestion[] = await response.json();

      return {
        locale,
        securityQuestionsList,
      };
    } catch {
      return rejectWithValue({
        message: ErrorMessages.GET_SECURITY_QUESTIONS_LIST,
      });
    }
  }
);

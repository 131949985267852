import en from "react-phone-number-input/locale/en.json";
import es from "react-phone-number-input/locale/es.json";
import fr from "react-phone-number-input/locale/fr.json";
import ja from "react-phone-number-input/locale/ja.json";
import pt from "react-phone-number-input/locale/pt-BR.json";
import zh from "react-phone-number-input/locale/zh.json";

export const DEFAULT_COUNTRY = "US";
export const DISPLAY_NAME = "CiamPhoneNumberInput";
export const PHONE_NUMBER_INPUT_ID = "phone";

export const bannedCountries = ["CN", "LB", "RU"];

export const countries = {
  ar: "AR",
  au: "AU",
  br: "BR",
  ca: "CA",
  cn: "CN",
  gb: "GB",
  jp: "JP",
  mx: "MX",
  nz: "NZ",
  us: "US",
};

export const dictionaries = { en, es, fr, ja, pt, zh };

export const phoneNumberErrors = {
  invalidPhoneNumber: {
    key: "Validation.InvalidPhoneNumberError",
  },
  phoneNumberMissing: {
    key: "Validation.PhoneNumberMissingError",
  },
};

// libraries
import { datadogRum } from "@datadog/browser-rum";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

// utilities
import {
  FeatureNames,
  useFeatureToggle,
} from "../../providers/FeatureToggleProvider";
import { redactUserObject } from "./utilities";
import { useConfiguration } from "../../providers/ConfigurationProvider";
import { useUser } from "../../hooks";

// constants
import packageJson from "../../../package.json";
import { DatadogConstants, SAMPLE_RATE_PERCENTAGE } from "./constants";

// types
import { CustomRumAttributes, DatadogUser } from "./types";

/**
 * @description A custom React hook that initializes and uses the Datadog RUM (Real User Monitoring) and
 * sets user information for tracking.
 */
export const useDatadogRum = () => {
  const isDatadogRumEnabled = useFeatureToggle(FeatureNames.DATADOG_RUM);
  const isSessionReplayEnabled = useFeatureToggle(
    FeatureNames.DATADOG_RUM_SESSION_REPLAY
  );
  const user = useUser();
  const { datadog } = useConfiguration();
  const { isAuthenticated } = useAuth0();
  const storedDatadogUser = localStorage.getItem(
    DatadogConstants.LOCAL_STORAGE_DATADOG_KEY
  );
  const [storedUser, setStoredUser] = useState(storedDatadogUser);

  useEffect(() => {
    // Initialize Datadog RUM if it is not already initialized.
    if (isDatadogRumEnabled && !datadogRum.getInitConfiguration()) {
      datadogRum.init({
        applicationId: datadog.applicationId,
        beforeSend: (event) => {
          event.view.url = event.view.url.replace(
            /(family_name=|email=|given_name=|session_token=|state=)([^&]*)/g,
            (_, prefix) => `${prefix}***`
          );

          if (event.type === "resource") {
            event.resource.url = event.resource.url.replace(
              /(family_name=|email=|given_name=|session_token=|state=)([^&]*)/g,
              (_, prefix) => `${prefix}***`
            );
          }
        },
        clientToken: datadog.clientToken,
        defaultPrivacyLevel: DatadogConstants.DEFAULT_PRIVACY_LEVEL,
        env: datadog.environment,
        sampleRate: SAMPLE_RATE_PERCENTAGE,
        service: DatadogConstants.SERVICE_NAME,
        site: DatadogConstants.SITE_NAME,
        trackFrustrations: true,
        trackUserInteractions: true,
        version: packageJson.version,
      });

      if (isSessionReplayEnabled) datadogRum.startSessionReplayRecording();

      const addtionalRumInfo: CustomRumAttributes = {
        hostedOn: DatadogConstants.HOSTED_ON,
      };

      datadogRum.setGlobalContext(addtionalRumInfo);
    }
  }, [datadog, isDatadogRumEnabled, isSessionReplayEnabled]);

  useEffect(() => {
    // Set the user information once the user is authenticated.
    if (isAuthenticated && user) {
      const datadogUser: DatadogUser = redactUserObject({
        email: user.email,
        firstName: user.firstName,
        id: user.euId,
        lastName: user.lastName,
        locale: user.locale,
      });

      // Set user for Datadog RUM.
      datadogRum.setUser(datadogUser);

      // Set the stored user state and local storage.
      setStoredUser(JSON.stringify(datadogUser));
      localStorage.setItem(
        DatadogConstants.LOCAL_STORAGE_DATADOG_KEY,
        JSON.stringify(datadogUser)
      );
    }

    // If user is not authenticated and user can be obtained from local storage.
    // This is done to track the user even when the user has signed out.
    else if (storedUser) {
      datadogRum.setUser(JSON.parse(storedUser));
    }
  }, [isAuthenticated, storedUser, user]);
};

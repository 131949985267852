// libraries
import { useTranslate } from "@dcl/tools";

//components
import {
  AlertCircle_1_Icon as AlertCircle,
  CheckCircleIcon as CheckCircle,
  CrossIcon as CloseIcon,
} from "../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { TOAST_DISPLAY_NAME, ToastTypes } from "../ToastConstants";

// types
import { DefaultToastProps, ToastIconProps } from "../ToastTypes";

const getClassName = getClassNameFactory(TOAST_DISPLAY_NAME);

const Icon: React.FC<ToastIconProps> = ({ type }) => {
  switch (type) {
    case ToastTypes.SUCCESS:
      return <CheckCircle />;
    case ToastTypes.ERROR:
      return <AlertCircle />;
    default:
      return null;
  }
};

export const DefaultToast: React.FC<DefaultToastProps> = ({
  children,
  onClose,
  type,
}) => {
  const translate = useTranslate();

  return (
    <div className={getClassName({ modifiers: type })}>
      <div className={getClassName({ descendantName: "icon" })}>
        <Icon type={type} />
      </div>
      <div className={getClassName({ descendantName: "text" })}>{children}</div>
      <button
        aria-label={translate("AccessibilityHint.Close")}
        className={getClassName({ descendantName: "closeIcon" })}
        onClick={onClose}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export const AppStoreIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 42 48" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M37.77 16.92c-2.74-4.01-7.01-4.56-8.53-4.62-3.63-.37-7.09 2.14-8.94 2.14-1.83 0-4.68-2.09-7.69-2.04-3.97.06-7.62 2.31-9.66 5.86-4.11 7.15-1.05 17.73 2.96 23.53 1.96 2.84 4.3 6.03 7.36 5.91 2.96-.12 4.08-1.91 7.65-1.91 3.57 0 4.58 1.91 7.71 1.85 3.17-.06 5.19-2.89 7.13-5.73 2.26-3.3 3.18-6.49 3.24-6.65-.07-.03-6.2-2.38-6.26-9.44-.06-5.92 4.82-8.75 5.03-8.9zM29.29 1c-2.35.1-5.19 1.56-6.88 3.54-1.51 1.75-2.83 4.54-2.47 7.23 2.62.2 5.29-1.34 6.92-3.31 1.63-1.98 2.73-4.72 2.43-7.46z"
        id="appleLogo"
      />
    </defs>
    <use xlinkHref="#appleLogo" />
    <use
      fillOpacity="0"
      stroke="#000"
      strokeOpacity="0"
      xlinkHref="#appleLogo"
    />
  </svg>
);

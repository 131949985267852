export const ShieldIcon: React.FC = () => (
  <svg
    className="shieldIcon"
    focusable="false"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path
        className="accentSvg"
        d="M11 1v20M19.904 11H2.096"
        stroke="#FA6400"
      />
      <path
        d="M11 1C8 3 4 4 1 4c0 7 3 14 10 17 7-3 10-10 10-17-3 0-7-1-10-3z"
        stroke="#404040"
      />
    </g>
  </svg>
);

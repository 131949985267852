// libraries
import { SafAlert } from "@saffron/core-components/react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { TOAST_DISPLAY_NAME } from "../ToastConstants";

// types
import { EnterpriseToastProps } from "../ToastTypes";

const getClassName = getClassNameFactory(TOAST_DISPLAY_NAME);

export const EnterpriseToast: React.FC<EnterpriseToastProps> = ({
  children,
  onClose,
}) => {
  const translate = useTranslate();

  return (
    <SafAlert
      alertType="toast"
      appearance="success"
      className={getClassName({ descendantName: "enterprise" })}
      close-aria-label={translate("AccessibilityHint.Close")}
      onClose={onClose}
    >
      {children}
    </SafAlert>
  );
};

export enum AriaGlobalTypes {
  GLOBAL = "global",
}

export enum AriaLiveTypes {
  ASSERTIVE = "assertive",
  POLITE = "polite",
}

export enum AutoCompleteTypes {
  NEW_PASSWORD = "new-password",
  OFF = "off",
}

export enum ButtonTypes {
  BUTTON = "button",
  SUBMIT = "submit",
}

export enum DatadogPrivacyTypes {
  ALLOW = "allow",
  HIDDEN = "hidden",
  MASK = "mask",
  MASK_USER_INPUT = "mask_user_input",
}

export enum InputTypes {
  CHECKBOX = "checkbox",
  FILE = "file",
  NUMBER = "number",
  PASSWORD = "password",
  TEL = "tel",
  TEXT = "text",
}

export enum RoleTypes {
  ALERT = "alert",
  BANNER = "banner",
  BUTTON = "button",
  COMBOBOX = "combobox",
  CONTENT_INFO = "contentinfo",
  DIALOG = "dialog",
  GROUP = "group",
  HEADING = "heading",
  IMAGE = "img",
  LINK = "link",
  LIST_BOX = "listbox",
  NAVIGATION = "navigation",
  OPTION = "option",
  PRESENTATION = "presentation",
  PROGRESS_BAR = "progressbar",
  TAB = "tab",
  TABLIST = "tablist",
  TABPANEL = "tabpanel",
}

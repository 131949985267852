export enum RequiredFields {
  VERIFICATION_CODE = "code",
}

export const DEFAULT_CODE_LENGTH = 6;
export const DISPLAY_NAME = "VerificationCode";
export const CODE_LABEL_ID = "CodeLabelId";

export const verificationCodeRequirements = {
  errors: {
    getCodeLengthMismatchError(requiredCodeLength: number) {
      return {
        key: "Validation.VerificationCodeMismatchError",
        replacements: [requiredCodeLength],
      };
    },
    getExpiredOtpCodeError() {
      return {
        key: "Validation.ExpiredOtpCodeError",
      };
    },
    getExpiredPhoneOtpCodeError() {
      return {
        key: "Validation.ExpiredPhoneOtpCodeError",
      };
    },
    getInvalidOtpCodeError() {
      return {
        key: "Validation.InvalidOtpCodeError",
      };
    },
    getValueMissingError() {
      return {
        key: "Validation.VerificationCodeMissingError",
      };
    },
  },
};

export enum AuthenticatorTypes {
  OOB = "oob",
  OTP = "otp",
  RECOVERY_CODE = "recovery-code",
}

export enum GrantTypes {
  OOB = "http://auth0.com/oauth/grant-type/mfa-oob",
  OTP = "http://auth0.com/oauth/grant-type/mfa-otp",
}

export enum MfaFactorNames {
  AUTH_GUARDIAN = "MfaFactorName.AuthGuardian",
  BIOMETRICS = "MfaFactorName.Biometrics",
  EMAIL = "MfaFactorName.Email",
  OTP_APP = "MfaFactorName.OtpApp",
  PHONE = "MfaFactorName.Phone",
  PHONE_CALL = "MfaFactorName.PhoneCall",
  RECOVERY_CODE = "MfaFactorName.RecoveryCode",
  SECURITY_KEY = "MfaFactorName.SecurityKey",
  TEXT_MESSAGE = "MfaFactorName.TextMessage",
}

export enum MfaFactorTypes {
  AUTH_GUARDIAN = "auth0",
  BIOMETRICS = "biometrics",
  EMAIL = "email",
  OTP_APP = "otpApp",
  PHONE = "phone",
  RECOVERY_CODE = "recoveryCode",
  SECURITY_KEY = "securityKey",
  SMS = "sms",
  VOICE = "voice",
}

export enum OobChannels {
  AUTH0 = "auth0",
  BIOMETRICS = "webauthn-platform",
  EMAIL = "email",
  SECURITY_KEY = "webauthn-roaming",
  SMS = "sms",
  VOICE = "voice",
}

export const availableFactorsForEnrollment = [
  {
    enrollmentsLimit: 1,
    type: MfaFactorTypes.AUTH_GUARDIAN,
  },
  { enrollmentsLimit: 1, type: MfaFactorTypes.OTP_APP },
  { enrollmentsLimit: 2, type: MfaFactorTypes.PHONE },
];

export const mfaFactorEnrollmentLimitMap = {
  [MfaFactorTypes.AUTH_GUARDIAN]: 1,
  [MfaFactorTypes.OTP_APP]: 1,
  [MfaFactorTypes.PHONE]: 2,
} as const;

export const rawToEnrolledOobFactorsMap = {
  [OobChannels.AUTH0]: MfaFactorTypes.AUTH_GUARDIAN,
  [OobChannels.BIOMETRICS]: MfaFactorTypes.BIOMETRICS,
  [OobChannels.EMAIL]: MfaFactorTypes.EMAIL,
  [OobChannels.SECURITY_KEY]: MfaFactorTypes.SECURITY_KEY,
  [OobChannels.SMS]: MfaFactorTypes.PHONE,
} as const;

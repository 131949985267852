// utilities
import { getLocaleFromUrl } from ".";

// types
import { To } from "../common/types";

/**
 * @description Gets locale value from the url. Creates basePath (locale + /profile/).
 * Combines basePath with the page path provided in the arguments.
 *
 * @param to - Relative path to the page (e.g. /error).
 *
 * @returns If country and language values exist in url, returns absolute path to the page (e.g. /en-us/profile/test).
 * If country or language values are absent, returns values passed in the arguments.
 */

export const getAbsolutePath = (to: string) => {
  const { country, language } = getLocaleFromUrl();
  const baseName = getBasePath(`${language}-${country}`);

  return country && language ? formatPath(baseName, to) : to;
};

/**
 *
 * @description Appends a relative path to an existing path, effectively extending the current path.
 *
 * @param currentPath - The current path where the additional relative path will be appended (e.g., /en-us/profile/products/pingpass).
 * @param to - The relative path to be appended to the current path (e.g., /manage-key).
 *
 * @returns A new path that combines the current path with the relative path provided (e.g., /en-us/profile/products/pingpass/manage-key).
 */

export const getAppendedPath = (currentPath: string, to: string) => {
  return formatPath(currentPath, to);
};

/**
 *
 * @description Trims a specified number of levels from the end of the current path, effectively shortening the path.
 *
 * @param currentPath - The current path that needs to be shortened (e.g., /en-us/profile/products/pingpass/manage-key).
 * @param levels - The number of levels to remove from the end of the current path.
 *
 * @returns The modified path after cutting the specified number of levels from the end (e.g., cutting 1 level
 * from /en-us/profile/products/pingpass/manage-key results in /en-us/profile/products/pingpass).
 */

export const getCutPath = (currentPath: string, levels: number) => {
  const path = formatPath(currentPath);
  const pathnames = path.split("/");

  levels = Math.max(0, Math.min(pathnames.length, levels));

  const cutPathnames = pathnames.slice(0, -levels);

  return cutPathnames.join("/");
};

/**
 * Prepares relative base path by provided locale
 * @param locale - language and country value
 * @returns Relative base path
 */

export const getBasePath = (locale: string) => `/${locale}/profile`;

/**
 * @description Based on the type of provided path creates either absolute path or returns original value.
 *
 * @param to - Relative path to the page (e.g. /error).
 *
 * @returns Absolute path to the page (e.g. /en-us/profile/test) or original value provided in arguments.
 */

export const getPath = (to: To) =>
  typeof to === "string" ? getAbsolutePath(to) : to;

/**
 * @description Combines all paths passed in arguments into one path.
 * Removes all redundant slashes and last trailing slash from the new path.
 *
 * @param paths - List of path to pages (e.g. ["/profile", "/error"]).
 *
 * @returns New path without redundant slashes and without last trailing slash (e.g. /profile//page/ turns to /profile/page).
 */

export const formatPath = (...paths: string[]) =>
  paths.join("/").replace(/\/\/+/g, "/").replace(/\/$/, "");

/**
 * @description Combines domain value with the path to the page.
 *
 * @param pathname - Path to the page including basePath (locale + /profile).
 *
 * @returns Full path to the page (e.g. http://test.com/en-us/profile/test).
 */

export const getFullPath = (pathname: string) =>
  `${window.location.origin}${getAbsolutePath(pathname)}`;

// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { getOtpAppQrCode } from "./getOtpAppQrCodeThunk";

// types
import { ReducerState } from "./getOtpAppQrCodeTypes";

const initialState: ReducerState = {
  error: null,
  qrCode: "",
  totpCode: "",
};

export const getOtpAppQrCodeSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getOtpAppQrCode.fulfilled, (state, action) => {
      state.qrCode = action.payload.barcode_uri;
      state.totpCode = action.payload.secret;
    });
    builder.addCase(getOtpAppQrCode.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
  initialState,
  name: "getQrCode",
  reducers: {},
});

// constants
import { Locales } from "../../common/constants";

/**
 * @description Formats locale by transforming to uppercase and replacing hyphen to underscore.
 *
 * @returns New formatted locale.
 */

export const getFormattedLocale = (locale: string): string =>
  locale.toLocaleUpperCase().replace("-", "_");

/**
 * @description Checks if locale is supported by application.
 *
 * @returns True if locale is supported, false - if not.
 */

export const isSupportedLocale = (locale: string) =>
  Boolean(Locales[getFormattedLocale(locale)]);

export const ChevronDown_1_Icon: React.FC = () => (
  <svg
    aria-hidden="false"
    focusable="false"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 10l-4 4-4-4"
      fill="none"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
    />
  </svg>
);

// utilities
import { getSecurityQuestionsList as getSecurityQuestions } from "../../redux/securityQuestions/securityQuestionsListThunk";

// types
import { Dispatch } from "../../redux";

export const getSecurityQuestionsList = async ({
  apiBasePath,
  dispatch,
  locale,
  onHandleError,
}: {
  apiBasePath: string;
  dispatch: Dispatch;
  locale: string;
  onHandleError: () => void;
}) => {
  try {
    await dispatch(getSecurityQuestions({ apiBasePath, locale })).unwrap();
  } catch {
    onHandleError();
  }
};

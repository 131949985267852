// constants
import { MfaSessionStorageKeys } from "../../common/constants";

/**
 * @description Gets MFA Phone call number from session storage.
 *
 * @returns Phone call number.
 */

export const getPhoneCallPhoneNumber = () =>
  sessionStorage.getItem(MfaSessionStorageKeys.PHONE_CALL_PHONE_NUMBER) ?? "";

/**
 * @description Gets MFA Text message number from session storage.
 *
 * @returns Text message number.
 */

export const getTextMessagePhoneNumber = () =>
  sessionStorage.getItem(MfaSessionStorageKeys.TEXT_MESSAGE_PHONE_NUMBER) ?? "";

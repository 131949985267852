export const SaffronUsersIcon: React.FC = () => (
  <svg
    fill="none"
    height="87"
    viewBox="0 0 87 87"
    width="87"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#E5F4FF" height="86" rx="43" width="86" x="0.5" y="0.5" />
    <path
      d="M36.423 49.5V38.38H32.643V36.42H42.523V38.38H38.743V49.5H36.423ZM44.701 49.5V36.42H49.121C50.0277 36.42 50.841 36.54 51.561 36.78C52.281 37.02 52.8543 37.4267 53.281 38C53.7077 38.56 53.921 39.3267 53.921 40.3C53.921 41.2333 53.7077 42 53.281 42.6C52.8543 43.2 52.281 43.6467 51.561 43.94C50.841 44.2333 50.0277 44.38 49.121 44.38H47.021V49.5H44.701ZM47.021 42.54H48.881C49.7743 42.54 50.4543 42.3533 50.921 41.98C51.401 41.6067 51.641 41.0467 51.641 40.3C51.641 39.5533 51.401 39.0333 50.921 38.74C50.4543 38.4333 49.7743 38.28 48.881 38.28H47.021V42.54ZM51.841 49.5L48.681 43.76L50.361 42.38L54.441 49.5H51.841Z"
      fill="#005DA2"
    />
    <rect height="86" rx="43" stroke="#005DA2" width="86" x="0.5" y="0.5" />
  </svg>
);

// libraries
import { createSlice } from "@reduxjs/toolkit";

// constants
import { initialState } from "./productsConstants";

export const getProductsSlice = createSlice({
  initialState,
  name: "product",
  reducers: {
    setProducts: (state, action) => {
      state.productArray = action.payload;
    },
  },
});

export const LockVpnIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66 13.75h13.5v10.5H1.66zM4.66 13.75V8.875a3.75 3.75 0 0 1 3.75-3.75v0a3.75 3.75 0 0 1 3.75 3.75v4.875"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="8.41"
      cy="18.25"
      r="1.5"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M15.16 1.75A8.25 8.25 0 0 1 23.41 10M15.16 5.5a4.5 4.5 0 0 1 4.5 4.5"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M8.41 19.75v1.5"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

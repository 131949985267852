// libraries
import { useEffect } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { isTranslateReady } from "./utilities";
import { useFeatureToggle } from "../../providers/FeatureToggleProvider";

// constants
import { FeatureNames } from "../../providers/FeatureToggleProvider";

export const useDocumentTitle = (documentTitleKey: string) => {
  const translate = useTranslate();
  const isNewFlowEnabled = useFeatureToggle(FeatureNames.PROFILE_PAGE_NEW_FLOW);

  useEffect(() => {
    if (isTranslateReady(translate)) {
      let title = translate(documentTitleKey);

      if (isNewFlowEnabled) {
        const enterpriseTitle = `Enterprise${documentTitleKey}`;
        const translatedTitle = translate(enterpriseTitle);

        title = translatedTitle || title;
      }

      const companyName = translate("Page.CompanyName");

      document.title = `${title} | ${companyName}`;
    }
  }, [documentTitleKey, translate, isNewFlowEnabled]);
};

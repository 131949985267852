// libraries
import { useLocation, useParams } from "react-router";
import { useTranslate } from "@dcl/tools";

// types
import { Breadcrumb, PathToBreadcrumbText } from "./BreadcrumbsTypes";
import { Paths } from "../../common/types";

const pathToTranslation = {
  "personal-info": "Breadcrumbs.PersonalInfo",
  products: "Breadcrumbs.Products",
  profile: "Breadcrumbs.Home",
  "register-product": "Breadcrumbs.RegisterProduct",
  security: "Breadcrumbs.Security",
};

export const useBreadcrumbs = (pathToBreadcrumbText?: PathToBreadcrumbText) => {
  const translate = useTranslate();
  const location = useLocation();
  const { language, country } = useParams();

  const pathnames = location.pathname.split("/").filter((path) => {
    // filter "" and locale
    return path && path !== `${language}-${country}`;
  });

  return pathnames.map((path, index, arr) => {
    const text = pathToBreadcrumbText?.hasOwnProperty(path)
      ? pathToBreadcrumbText[path]
      : translate(pathToTranslation[path]);

    const oneBreadcrumb: Breadcrumb = {
      text,
    };

    // the current page (i.e. the last pathname) doesn't have a link to itself
    if (pathnames[pathnames.length - 1] !== path) {
      // e.g. "/", "/products", "/products/pingpass"
      const url = `/${arr.slice(0, index + 1).join("/")}`.replace(
        index === 0 ? "profile" : "/profile",
        ""
      ) as Paths;

      oneBreadcrumb.href = url;
    }

    return oneBreadcrumb;
  });
};

// constants
import { AccessibilityClassnames, RoleTypes } from "../../common/constants";
import { NOTIFICATION_ELEMENT_ID } from "./LoadingConstants";

const createNotificationElement = (): HTMLElement => {
  const element = document.createElement("div");

  element.id = NOTIFICATION_ELEMENT_ID;
  element.setAttribute("role", RoleTypes.ALERT);
  element.className = AccessibilityClassnames.VISUALLY_HIDDEN;
  element.style.top = "0px";
  document.body.appendChild(element);

  return element;
};

export const getNotificationElement = (): HTMLElement =>
  document.getElementById(NOTIFICATION_ELEMENT_ID) ||
  createNotificationElement();

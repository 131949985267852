// components.
import { AccordionItem } from "./AccordionItem";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { ACCORDION_LABEL, DISPLAY_NAME, features } from "./constants";

// types
import { AccordionProps } from "./AccordionTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Accordion: React.FC<AccordionProps> = ({
  activeItemIndex,
  onActivateItem,
}) => {
  const handleToggle = (index: number) => {
    if (activeItemIndex === index) {
      return onActivateItem(null);
    }

    onActivateItem(index);
  };

  return (
    <ul aria-label={ACCORDION_LABEL} className={getClassName()}>
      {features.map((feature, index) => (
        <AccordionItem
          data={feature}
          isActive={activeItemIndex === index}
          itemIndex={index}
          key={index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </ul>
  );
};

Accordion.displayName = DISPLAY_NAME;

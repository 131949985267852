// libraries
import { useDispatch } from "react-redux";
import { useEffect } from "react";

// utilities
import { dialogOpened } from "../../analytics";
import { useAuth0User } from "../../hooks";

// constants
import { DialogsIds } from "../../common/constants";

// types
import { Dispatch } from "../../redux";

export const useDialogAnalytics = (isOpen: boolean, dialogId?: DialogsIds) => {
  const dispatch = useDispatch<Dispatch>();
  const { euId } = useAuth0User();

  useEffect(() => {
    if (dialogId && isOpen) {
      dispatch(dialogOpened(euId, dialogId));
    }
  }, [dialogId, dispatch, euId, isOpen]);
};

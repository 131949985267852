// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { isInvalidOtpCodeError } from "../../../../utilities";
import { verifyFactor } from "../../factors/verifyFactor";

// constants
import { ErrorMessages } from "../../../../common/constants";

// types
import {
  RequestPayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./verifyOtpAppCodeTypes";

export const verifyOtpAppCode = createAsyncThunk<
  void,
  ThunkArguments,
  ThunkConfiguration
>(
  "otpApp/verifyCode",
  async ({ apiBasePath, requestPayload }, { rejectWithValue }) => {
    try {
      return await verifyFactor<RequestPayload>({
        apiBasePath,
        requestPayload,
      });
    } catch (error) {
      const errorMessage = isInvalidOtpCodeError(error)
        ? ErrorMessages.INVALID_OTP_CODE_ERROR
        : ErrorMessages.VERIFY_FACTOR;

      return rejectWithValue({
        message: errorMessage,
      });
    }
  }
);

// libraries
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

// utilities
import { getCustomErrorCode } from "./AppUtilities";
import { getFullPath } from "../../utilities";
import { useNavigation } from "../../hooks";

// constants
import { Auth0Errors, redirectConfiguration } from "./AppConstants";

export const useAuth0ErrorHandling = () => {
  const { error, logout, loginWithRedirect } = useAuth0();
  const { paths, redirect } = useNavigation();

  const isGenericErrorPage = window.location.pathname.includes(
    paths.genericError
  );

  const shouldHandleError = Boolean(error) && !isGenericErrorPage;

  useEffect(() => {
    if (shouldHandleError) {
      switch (error?.message) {
        case Auth0Errors.LOGIN_TO_LOCAL_ACCOUNT:
          logout({ returnTo: getFullPath(paths.profile) });
          break;
        case Auth0Errors.INVALID_STATE:
          loginWithRedirect({ appState: paths.profile });
          break;
        default:
          const errorCode = getCustomErrorCode(error);

          redirect(paths.customError, {
            errorCode,
          });
      }
    }
  }, [
    error,
    shouldHandleError,
    loginWithRedirect,
    logout,
    paths.customError,
    paths.profile,
    redirect,
  ]);

  return { shouldHandleError };
};

export const useRedirect = () => {
  const { replace } = useNavigation();

  useEffect(() => {
    redirectConfiguration.forEach(({ newUrl, oldUrl }) => {
      if (oldUrl.test(window.location.pathname)) {
        const urlParameters = window.location.search;
        const pathname = window.location.pathname.replace(oldUrl, newUrl);

        replace({ pathname, search: urlParameters });
      }
    });
  }, [replace]);
};

export const useTransformPath = () => {
  const { pathname, search } = window.location;
  const pathnameInLowercase = pathname.toLowerCase();

  const { replace } = useNavigation();

  useEffect(() => {
    if (pathname !== pathnameInLowercase) {
      replace({
        pathname: pathnameInLowercase,
        search,
      });
    }
  }, [pathname, pathnameInLowercase, replace, search]);
};

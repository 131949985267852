// libraries
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "@dcl/tools";

// utilities
import { completeFactorEnrollmentSlice } from "../../redux";
import { useNavigation, useUpdateMfaData } from "../";
import { useRecoveryCodeDialog } from "../../pages/SecurityPage/components/RecoveryCodeDialog";
import { useToast } from "../../components/Toast";

// constants
import { MfaFactorTypes } from "../../common/constants";
import { ToastTypes } from "../../components/Toast";
import { mapFactorTypeToToastName } from "./constants";

// types
import { Dispatch, State } from "../../redux";

/**
 * @description Completes factor enrollment for MFA.
 * @param factorType - The type of factor being enrolled.
 * @returns As async function that completes the factor enrollment process. If the user has a recovery code,
 * they will be prompted to copy and save recovery code before the process is complete.
 */
export const useCompleteFactorEnrollment = (factorType: MfaFactorTypes) => {
  const dispatch = useDispatch<Dispatch>();
  const hasRecoveryCode = !!useSelector<State, string>(
    (state) => state.completeFactorEnrollment.enrolledRecoveryCode
  );
  const recoveryCodeDialog = useRecoveryCodeDialog();
  const toast = useToast();
  const translate = useTranslate();
  const updateMfaData = useUpdateMfaData();
  const { paths, redirect } = useNavigation();

  const showToast = useCallback(
    () =>
      toast.add({
        text: translate("SecurityPageMfaConfiguration.FactorEnrollmentToast", [
          translate(mapFactorTypeToToastName[factorType]),
        ]),
        type: ToastTypes.ENTERPRISE,
      }),
    [factorType, toast, translate]
  );

  const handleComplete = useCallback(
    async (preCompletionPromise?: Promise<void>) => {
      if (preCompletionPromise) {
        await preCompletionPromise;
      }

      await updateMfaData();

      redirect({ hash: paths.hash.mfa.configuration });
      showToast();
      dispatch(completeFactorEnrollmentSlice.actions.resetState());
    },
    [dispatch, showToast, redirect, updateMfaData, paths.hash.mfa.configuration]
  );

  return async (preCompletionPromise?: Promise<void>) => {
    if (hasRecoveryCode) {
      recoveryCodeDialog.addActionOnClose(() =>
        handleComplete(preCompletionPromise)
      );
      recoveryCodeDialog.addActionOnSubmit(() =>
        handleComplete(preCompletionPromise)
      );
      recoveryCodeDialog.open();
    } else {
      await handleComplete(preCompletionPromise);
    }
  };
};

// types
import { FetchAuthorizedOptions } from "./ciamFetchTypes";

/**
 * @description Constructs a request with preset values of headers, mode, etc.
 *
 * @param url - Full url to the endpoint.
 *
 * @param token - Auth0's access token.
 *
 * @param options - Extends or rewrites preset headers, mode or body of the request.
 *
 * @returns Function call that makes a request to backend.
 */

export async function ciamFetch<T>(
  url: string,
  token: string,
  options: FetchAuthorizedOptions<T>
) {
  const body = options?.body ? JSON.stringify(options.body) : null;

  const headers = {
    Accept: "application/json, text/plain, */*",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    ...options?.headers,
  };

  const mergedOptions: RequestInit = {
    body,
    headers,
    method: options.method,
    mode: "cors" as RequestMode,
  };

  return fetch(url, mergedOptions);
}

export const PlusIcon: React.FC = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="currentColor" strokeLinecap="square" strokeWidth="2">
      <path d="M12 7v10M17 12H7" />
    </g>
  </svg>
);

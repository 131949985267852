// utilities
import { getCustomProfileManagementError } from "./getError";
import { toUpdatedProfileUser } from "../../utilities";

// constants
import { HttpMethods, endpoints } from "../../common/constants";
import { ProfileManagementError } from "./constants";

// types
import { Auth0User, RawAuth0User } from "../../common/types";
import { UpdateUserParameters } from "./types";

/**
 * @description Makes request to CIS to update user information.
 * @param accessToken - Auth0's access token.
 * @param apiBasePath - CIS or Auth0 API domain url.
 * @param euId - User's euId from Auth0's token.
 * @param payload - User's information that should be updated.
 * @returns Promise which resolves into prepared object with user data ready to be used in the app.
 * @throws Error in case of unsuccessful request.
 */

export const updateUser = async <T>({
  accessToken,
  apiBasePath,
  euId,
  payload,
}: UpdateUserParameters<T>) => {
  const url = endpoints.getUpdateUserEndpoint(apiBasePath, euId);

  const options = {
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    method: HttpMethods.PATCH,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const error = await response.json();
    const customError = getCustomProfileManagementError(error);

    if (customError) {
      throw new ProfileManagementError(customError);
    } else {
      throw new Error(error.message);
    }
  }

  const rawUpdatedUser: RawAuth0User = await response.json();

  return toUpdatedProfileUser<RawAuth0User, Auth0User>(rawUpdatedUser);
};

// libraries
import { useTranslate } from "@dcl/tools";

// components
import { Button, Section } from "../../../../components";

// utilities
import { getClassNameFactory } from "../../../../utilities";
import { useNavigation } from "../../../../hooks";

// constants
import { DISPLAY_NAME, epilogItems } from "./EpilogConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Epilog: React.FC = () => {
  const translate = useTranslate();
  const { redirect } = useNavigation();

  return (
    <div className={getClassName()}>
      <Section>
        <div className={getClassName("row")}>
          {epilogItems.map(({ buttonColor, buttonText, description, path }) => (
            <div className={getClassName("item")} key={description}>
              <p className={getClassName("description")}>
                {translate(description)}
              </p>
              <Button color={buttonColor} onClick={() => redirect(path)}>
                {translate(buttonText)}
              </Button>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

Epilog.displayName = DISPLAY_NAME;

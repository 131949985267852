/**
 * @description Parses url query string and transforms it into object containing all query parameters.
 *
 * @param queryString - Query string from the url.
 *
 * @param parameters - Enum with parameters that should be taken from the query string.
 *
 * @returns Object with query parameters from url.
 */

export const getUrlParameters = <T extends { [key: string]: string }>(
  queryString: string,
  parameters: T
) => {
  const urlParameters = new URLSearchParams(queryString);

  return Object.values(parameters).reduce(
    (result, key) =>
      urlParameters.get(key)
        ? { ...result, [key]: urlParameters.get(key) }
        : result,

    {} as { [key in T[keyof T]]?: string }
  );
};

// constants
import { ErrorMessages } from "../../common/constants";

// types
import { ApplicationConfiguration } from "./ConfigurationProviderTypes";

export const validateConfiguration = (
  configuration: ApplicationConfiguration
) => {
  const { auth0, ciam, connections, datadog, mfa, reCaptcha, split } =
    configuration || {};

  const isAuth0Unavailable = !(
    auth0?.audience &&
    auth0?.clientId &&
    auth0?.domain &&
    auth0?.scope
  );
  const isCiamUnavailable = !ciam?.basePath;
  const isConnectionsUnavailable = !(
    connections?.federated && connections?.local
  );
  const isDatadogUnavailable = !(
    datadog?.applicationId &&
    datadog?.clientToken &&
    datadog?.environment
  );
  const isMfaUnavailable = !(mfa?.audience && mfa?.scope);
  const isReCaptchaUnavailable = !reCaptcha?.siteKey;
  const isSplitUnavailable = !split?.authorizationKey;

  if (
    isAuth0Unavailable ||
    isCiamUnavailable ||
    isConnectionsUnavailable ||
    isDatadogUnavailable ||
    isMfaUnavailable ||
    isReCaptchaUnavailable ||
    isSplitUnavailable
  ) {
    throw new Error(ErrorMessages.CONFIGURATION_VALIDATION);
  }
};

// constants
import { AuthenticationBypasses, paths } from "../../common/constants";

export const AUTH0_MAX_AGE = 28800; // 8 hours

export const authenticationHintsConfiguration = [
  {
    hint: AuthenticationBypasses.EMAIL_VERIFICATION,
    path: paths.verifyNewEmail,
  },
  {
    hint: AuthenticationBypasses.PASSWORD_EXPIRED,
    path: paths.passwordExpired,
  },
];

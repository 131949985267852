export const ArrowLeftIcon: React.FC = (): JSX.Element => (
  <svg
    focusable="false"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M22 12H2" />
      <path d="M9 19l-7-7 7-7" strokeLinecap="square" />
    </g>
  </svg>
);

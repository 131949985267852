// libraries
import { useTranslate } from "@dcl/tools";

// components
import { HeadingSection, Section } from "../../../../components";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { DISPLAY_NAME, securityMethods } from "./RelatedProductsConstants";
import { HeadingRank } from "../../../../components/Heading";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const RelatedProducts: React.FC = () => {
  const translate = useTranslate();

  return (
    <div className={getClassName()}>
      <Section>
        <HeadingSection
          className={getClassName("heading")}
          heading={translate("RelatedProducts.Heading")}
          headingRank={HeadingRank.Second}
          subheading={translate("RelatedProducts.Subheading")}
        />
        <div className={getClassName("list")}>
          {securityMethods.map(
            ({ description, heading, linkText, linkUrl }) => (
              <div className={getClassName("item")} key={heading}>
                <h3 className={getClassName("itemHeading")}>
                  {translate(heading)}
                </h3>
                <p className={getClassName("itemDescription")}>
                  {translate(description)}
                </p>
                {linkText && linkUrl && (
                  <a className={getClassName("itemLink")} href={linkUrl}>
                    {translate(linkText)}
                  </a>
                )}
              </div>
            )
          )}
        </div>
      </Section>
    </div>
  );
};

RelatedProducts.displayName = DISPLAY_NAME;

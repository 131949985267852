// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { enrollFactor } from "../../factors/enrollFactor";

// constants
import { ErrorMessages } from "../../../../common/constants";

// types
import {
  RequestPayload,
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./getOtpAppQrCodeTypes";

export const getOtpAppQrCode = createAsyncThunk<
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration
>(
  "getQrCode/getQrCode",
  async ({ apiBasePath, mfaToken, requestPayload }, { rejectWithValue }) => {
    try {
      return await enrollFactor<RequestPayload, ResponsePayload>({
        apiBasePath,
        mfaToken,
        requestPayload,
      });
    } catch (error) {
      return rejectWithValue({
        message: ErrorMessages.GET_QR_CODE,
      });
    }
  }
);

export const AlertCircleIcon: React.FC = () => (
  <svg focusable="false" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" transform="translate(1 1)">
      <circle cx="30" cy="30" r="29" stroke="#404040" strokeWidth="3" />
      <path d="M29.589 10.833v26.198" stroke="#fa6400" strokeWidth="3" />
      <circle
        cx="29.583"
        cy="46.25"
        fill="#fa6400"
        r="1.25"
        stroke="#fa6400"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export const DeleteIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    stroke="currentColor"
    strokeLinecap="round"
    strokeWidth="4"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M46 32H18" />
  </svg>
);

// utilities
import { confirmAuthGuardianEnrollment } from "../../authGuardian";
import { regenerateRecoveryCode } from "../../recoveryCode";
import { verifyOtpAppCode } from "../../otpApp";
import { verifyPhoneCallCode } from "../../phoneCall";
import { verifyTextMessageCode } from "../../textMessage";

// constants
import { MfaFactorTypes } from "../../../../common/constants";

export const actionToFactorNameMap = {
  [confirmAuthGuardianEnrollment.fulfilled.type]: MfaFactorTypes.AUTH_GUARDIAN,
  [regenerateRecoveryCode.fulfilled.type]: MfaFactorTypes.RECOVERY_CODE,
  [verifyPhoneCallCode.fulfilled.type]: MfaFactorTypes.VOICE,
  [verifyOtpAppCode.fulfilled.type]: MfaFactorTypes.OTP_APP,
  [verifyTextMessageCode.fulfilled.type]: MfaFactorTypes.SMS,
};

export const SmallLockIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 10.802h14.625v11.323H4.5zM7.75 10.802V5.544c0-2.233 1.819-4.044 4.063-4.044v0c2.243 0 4.062 1.81 4.062 4.044v5.258"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <ellipse
      cx="11.813"
      cy="15.655"
      rx="1.625"
      ry="1.618"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M11.813 17.272v1.618"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

// libraries
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { Accordion, Tabs } from "../";
import { HeadingSection, Section } from "../../../../components";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { DEFAULT_ACTIVE_INDEX, DISPLAY_NAME } from "./FeaturesConstants";
import { HeadingRank } from "../../../../components/Heading";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Features: React.FC = () => {
  const translate = useTranslate();
  const [activeItemIndex, setActiveItemIndex] = useState<null | number>(
    DEFAULT_ACTIVE_INDEX
  );

  return (
    <div className={getClassName()}>
      <Section>
        <HeadingSection
          className={getClassName("heading")}
          heading={translate("Features.Heading")}
          headingRank={HeadingRank.Second}
          subheading={translate("Features.Subheading")}
        />
        <Accordion
          activeItemIndex={activeItemIndex}
          onActivateItem={setActiveItemIndex}
        />
        <Tabs
          activeItemIndex={activeItemIndex || DEFAULT_ACTIVE_INDEX}
          onActivateItem={setActiveItemIndex}
        />
      </Section>
    </div>
  );
};

Features.displayName = DISPLAY_NAME;

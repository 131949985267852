// components
import { AlertCircleIcon } from "../icons";

export enum DialogsIds {
  ADD_OTP_APP = "ciam:profile:2sv:add-app:error:cannot-verify-code",
  ADD_PHONE_CALL = "ciam:profile:2sv:add-phone:error-cannot-call-phone",
  ADD_TEXT_MESSAGE = "ciam:profile:2sv:add-phone:error-cannot-text-code",
  DELETE_ALL_FACTORS = "ciam:profile:2sv:turnoff-2sv",
  DELETE_FACTOR = "ciam:profile:2sv:delete-factor-2sv",
  DELETE_REG_KEY = "ciam:profile:2sv:delete-reg-key",
  EDIT_REG_KEY = "ciam:profile:2sv:edit-reg-key",
  GENERIC_ERROR = "ciam:profile:error-modal",
  GUARDIAN_ACCESS_DENIED = "ciam:profile:2sv:guardian-access-denied",
  GUARDIAN_AUTHORIZATION_PENDING = "ciam:profile:2sv:guardian-authorization-pending",
  GUARDIAN_INVALID_GRANT = "ciam:profile:2sv:guardian-invalid-grant",
  GUARDIAN_MALFORMED_MFA = "ciam:profile:2sv:guardian-malformed-mfa",
  GUARDIAN_MALFORMED_OOB = "ciam:profile:2sv:guardian-malformed-oob",
  RECAPTCHA_THRESHOLD_ERROR = "ciam:profile:recaptcha-threshold-error-modal",
  RECOVERY_CODE = "ciam:profile:2sv:recovery-code",
  RESET_MFA = "ciam:profile:2sv:turnoff-2sv",
  SECURITY_QUESTION = "ciam:profile:2sv:security-question",
  SIGN_OUT = "ciam:profile:sign-out-confirmation-modal",
  TIME_OUT = "ciam:profile:time-out",
  UPDATE_EMAIL = "ciam:profile:edit-email-confirmation-modal",
  UPDATE_PASSWORD = "ciam:profile:edit-password-confirmation-modal",
  VERIFY_PHONE_CALL = "ciam:profile:2sv:add-phone:verify:error-cannot-verify-code",
  VERIFY_TEXT_MESSAGE = "ciam:profile:2sv:add-text:verify:error-cannot-verify-code",
  VERIFY_TEXT_MESSAGE_RESEND_CODE = "ciam:profile:2sv:add-text:verify:error-cannot-resend-code",
}

export const genericErrorDialogConfiguration = {
  body: "GenericErrorDialog.Description",
  closeLabel: "AccessibilityHint.Close",
  headerIcon: <AlertCircleIcon />,
  headerText: "GenericErrorDialog.Heading",
  id: DialogsIds.GENERIC_ERROR,
  isOpen: false,
};

export const emailVerificationSendingErrorDialogConfiguration = {
  ...genericErrorDialogConfiguration,
  body: "EmailVerificationSendingErrorDialog.Description",
};

export const reCaptchaThresholdErrorDialogConfiguration = {
  body: "ReCaptchaThresholdErrorDialog.Description",
  headerText: "ReCaptchaThresholdErrorDialog.Heading",
  id: DialogsIds.RECAPTCHA_THRESHOLD_ERROR,
  isAlert: true,
  isOpen: false,
  primaryButtonText: "Button.Close",
};

export const enterpriseGenericErrorDialogConfiguration = {
  body: "EnterpriseGenericErrorDialog.Description",
  headerText: "EnterpriseGenericErrorDialog.Heading",
  id: DialogsIds.GENERIC_ERROR,
  isAlert: true,
  isOpen: false,
  primaryButtonText: "Button.Close",
};

export const enterpriseEmailVerificationErrorDialogConfiguration = {
  ...enterpriseGenericErrorDialogConfiguration,
  body: "EmailVerificationSendingErrorDialog.Description",
};

export const enum RegistrationKeysErrorActionTypes {
  CLOSE_ERROR_DIALOG = "close error dialog",
  OPEN_CUSTOM_ERROR_DIALOG = "open invalid identifier dialog",
  OPEN_ERROR_DIALOG = "open error dialog",
}

export const enum UpdatePasswordErrorActionTypes {
  CLOSE_ERROR_DIALOG = "close error dialog",
  OPEN_ERROR_DIALOG = "open error dialog",
  OPEN_INVALID_PASSWORD_DIALOG = "open invalid password dialog",
}

export interface RegistrationKeysErrorAction {
  type: RegistrationKeysErrorActionTypes;
  payload?: any;
}

export interface UpdatePasswordErrorAction {
  type: UpdatePasswordErrorActionTypes;
}

export const ConnectionIcon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    height="24"
    viewBox="0 0 26 24"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m11.012 9.18-3.524 5.64M14.988 9.18l3.524 5.64M9.25 18h7.5"
      stroke="#FA6400"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="5.5"
      cy="18"
      r="3.75"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="20.5"
      cy="18"
      r="3.75"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="13"
      cy="6"
      r="3.75"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

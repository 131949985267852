// libraries
import { SafIcon, SafText } from "@saffron/core-components/react";

// components
import { AlertCircle_1_Icon as AlertIcon } from "../../common/icons";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { DISPLAY_NAME } from "./InlineErrorMessageConstants";
import { RoleTypes, safTextAppearance } from "../../common/constants";

// types
import { InlineErrorMessageProps } from "./InlineErrorMessageTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const InlineErrorMessage: React.FC<InlineErrorMessageProps> = ({
  className,
  errorMessage,
  id,
  isEnterprise,
}) => (
  <span className={getClassName({ className })} id={id} role={RoleTypes.ALERT}>
    {isEnterprise ? (
      <>
        <SafIcon
          appearance="solid"
          className={getClassName("enterpriseIcon")}
          iconName="hexagon-exclamation"
          size={14}
        />
        <SafText
          appearance={safTextAppearance.BODY_STRONG_SM}
          className={getClassName("enterpriseErrorText")}
        >
          {errorMessage}
        </SafText>
      </>
    ) : (
      <>
        <span
          className={getClassName({ descendantName: "icon" })}
          role={RoleTypes.PRESENTATION}
        >
          <AlertIcon />
        </span>
        <span className={getClassName({ descendantName: "message" })}>
          {errorMessage}
        </span>
      </>
    )}
  </span>
);

InlineErrorMessage.displayName = DISPLAY_NAME;

// libraries
import { createSlice } from "@reduxjs/toolkit";

// utilities
import { regenerateRecoveryCode } from "./recoveryCodeThunk";

// types
import { ReducerState } from "./recoveryCodeTypes";

const initialState: ReducerState = {
  error: null,
};

export const regenerateRecoveryCodeSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(regenerateRecoveryCode.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
  initialState,
  name: "regenerateRecoveryCode",
  reducers: {},
});

// libraries
import ReactDOM from "react-dom";
import classNames from "classnames";
import { SafButton, SafDialog, SafIcon } from "@saffron/core-components/react";
import { useEffect, useState } from "react";

// utilities
import { focusOnAndScrollToField, getClassNameFactory } from "../../utilities";
import { useEnterpriseDialogAnalytics } from "./EnterpriseDialogHooks";
import { useTranslate } from "@dcl/tools";

// constants
import { DISPLAY_NAME } from "./EnterpriseDialogConstants";

// types
import { EnterpriseDialogProps } from "./EnterpriseDialogTypes";
import { RoleTypes } from "../../common/constants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const EnterpriseDialog: React.FC<EnterpriseDialogProps> = ({
  children,
  className,
  dialogTitle,
  isAlert,
  id,
  isOpen,
  onClose,
  onPrimaryButtonClick,
  primaryButtonText,
  dialogSubtitle,
  focusId,
  onSecondaryButtonClick,
  secondaryButtonText,
}) => {
  useEnterpriseDialogAnalytics(isOpen, id);

  const hasFooter = !!primaryButtonText && !!onPrimaryButtonClick;
  const translate = useTranslate();

  const [previouslyFocusedElement, setPreviouslyFocusedElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setPreviouslyFocusedElement(() => document.activeElement as HTMLElement);
    }
  }, [isOpen]);

  useEffect(() => {
    const hiddenDialogs = document.querySelectorAll(
      `.EnterpriseDialog-overlay:not([hidden])`
    );

    if (hiddenDialogs.length === 0) {
      document.body.style.overflow = "";
      focusId
        ? focusOnAndScrollToField(focusId)
        : previouslyFocusedElement?.focus();
    }

    if (hiddenDialogs.length > 0) {
      document.body.style.overflow = "hidden";
    }
  }, [focusId, isOpen, previouslyFocusedElement]);

  const dialog = (
    <div
      className={classNames(getClassName("overlay"), className)}
      hidden={!isOpen}
    >
      <SafDialog
        aria-modal="true"
        closeAriaLabel={translate("AccessibilityHint.Close")}
        dialog-subtitle={dialogSubtitle}
        dialog-title={dialogTitle}
        hidden={!isOpen}
        id={id}
        is-alert={isAlert || false}
        isFooter={hasFooter ? "true" : "false"}
        modal={false}
        onClose={onClose}
        role={RoleTypes.DIALOG}
        is-footer
        is-header
      >
        <div
          className={getClassName({
            descendantName: "mainContent",
            modifiers: !hasFooter && "bottomSpace",
            states: isAlert ? "alert" : undefined,
          })}
        >
          {!!isAlert && (
            <SafIcon
              appearance="solid"
              className={getClassName("alertIcon")}
              iconName="hexagon-exclamation"
              size={16}
            />
          )}
          {children}
        </div>
        <div className={getClassName("footer")} slot="footer">
          {secondaryButtonText && onSecondaryButtonClick && (
            <SafButton
              appearance="secondary"
              className={getClassName("secondaryButton")}
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonText}
            </SafButton>
          )}
          <SafButton onClick={onPrimaryButtonClick}>
            {primaryButtonText}
          </SafButton>
        </div>
      </SafDialog>
    </div>
  );

  const modalRoot = document.getElementById("modalRoot");

  return modalRoot ? ReactDOM.createPortal(dialog, modalRoot) : dialog;
};

EnterpriseDialog.displayName = DISPLAY_NAME;

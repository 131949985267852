// libraries
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getErrorMessageFactory } from ".";

// constants
import { phoneNumberValidationRules } from ".";

// types
import { ErrorMessage } from "./validationHooksTypes";

/**
 * @description Provides phone number input validation.
 * @param defaultInputValue - Default input value for phone number
 * @returns An object with the following properties:
 * - `change`: A function that sets the value of the input.
 * - `errorMessage`: Translated error message.
 * - `setErrorMessage`: A function that takes an ErrorMessage as an argument and sets it as the value of the error message.
 * - `validate`: A function that validates the input value against phone number validation rules and returns an error message if validation fails.
 * - `value`: A string containing the current value of the input.
 */
export const usePhoneNumberInputValidation = ({
  defaultInputValue = "",
}: {
  defaultInputValue?: string;
}) => {
  const translate = useTranslate();
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [value, setValue] = useState(defaultInputValue);

  const getErrorMessage = getErrorMessageFactory(phoneNumberValidationRules);

  const translateErrorMessage = (message: ErrorMessage | null) =>
    message && translate(message.key, [message.replacements]);

  const change = (inputValue: string) => {
    setValue(inputValue);

    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const validate = () => {
    const rawErrorMessage = getErrorMessage(value ?? "");
    const translatedErrorMessage = translateErrorMessage(rawErrorMessage);

    setErrorMessage(translatedErrorMessage);

    return rawErrorMessage;
  };

  return {
    change,
    errorMessage,
    setErrorMessage: (message: ErrorMessage) =>
      setErrorMessage(translateErrorMessage(message)),
    validate,
    value,
  };
};

// utilities
import { isPatternMismatched } from "../../hooks";

// constants
import {
  DatadogConstants,
  NUMBER_OF_EMAIL_CHARACTERS_TO_SHOW,
  NUMBER_OF_NAME_CHARACTERS_TO_SHOW,
} from "./constants";
import { EMAIL_PATTERN } from "../../common/constants";

// types
import { DatadogUser } from "./types";

/**
 * @description Redacts sensitive information from a user object and returns the updated object.
 * @param user - `DatadogUser` object to be redacted
 * @returns - `DatadogUser` object with `email`, `firstName`, `lastName`, and `id` redacted
 */
export const redactUserObject = (user: DatadogUser): DatadogUser => {
  return {
    ...user,
    email: redactEmail(user?.email, NUMBER_OF_EMAIL_CHARACTERS_TO_SHOW),
    firstName: redactCharactersInWord(
      user?.firstName,
      NUMBER_OF_NAME_CHARACTERS_TO_SHOW
    ),
    id: redactUserId(user?.id),
    lastName: redactCharactersInWord(
      user?.lastName,
      NUMBER_OF_NAME_CHARACTERS_TO_SHOW
    ),
  };
};

/**
 * @description This function takes an email and a number of characters to show, and redacts the email by replacing the characters in the username and domain with a "masked character".
 * @param email - the email to redact
 * @param  numberOfCharactersToShow - the number of characters to show in the email before redacting
 * @returns `string` - the redacted email
 */
const redactEmail = (email: string, numberOfCharactersToShow: number) => {
  if (email === "") {
    return "";
  }

  if (isPatternMismatched(email, EMAIL_PATTERN))
    return DatadogConstants.INVALID_EMAIL;

  const [username, domain] = email.split("@");
  const redactedDomain =
    domain.slice(0, numberOfCharactersToShow) +
    DatadogConstants.MASKED_CHARACTER.repeat(
      domain.length - numberOfCharactersToShow
    );

  if (numberOfCharactersToShow >= username.length) {
    const editedUsername = DatadogConstants.MASKED_CHARACTER.repeat(
      username.length
    );

    return `${editedUsername}@${redactedDomain}`;
  }

  const redactedUsername =
    username.slice(0, numberOfCharactersToShow) +
    DatadogConstants.MASKED_CHARACTER.repeat(
      username.length - numberOfCharactersToShow
    );

  return `${redactedUsername}@${redactedDomain}`;
};

/**
 * @description Returns a redacted string by replacing first n characters with a "masked character"
 * @param word - the string to redact
 * @param numberOfCharactersToShow - the number of characters to show in the string before redacting
 * @returns `string` - the redacted string
 */
const redactCharactersInWord = (
  word: string,
  numberOfCharactersToShow: number
) => {
  if (numberOfCharactersToShow >= word.length) {
    return DatadogConstants.MASKED_CHARACTER.repeat(word.length);
  }

  const prefix = word.slice(0, numberOfCharactersToShow);
  const suffix = DatadogConstants.MASKED_CHARACTER.repeat(
    word.length - numberOfCharactersToShow
  );

  return `${prefix}${suffix}`;
};

/**
 * @description Returns a redacted user ID by replacing certain characters with
 * masked characters, leaving only the first and last third of the characters visible.
 * @param userId - The ID to redact
 * @returns `string` - The redacted user ID
 */
const redactUserId = (userId: string) => {
  const length = userId.length;
  const start = userId.slice(0, Math.max(length / 3, 1));
  const end = userId.slice(Math.min((length * 2) / 3, length), length);
  const middle = DatadogConstants.MASKED_CHARACTER.repeat(
    Math.max(length - start.length - end.length, 0)
  );

  return start + middle + end;
};

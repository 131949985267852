// library
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslate } from "@dcl/tools";

// components
import { Menu } from "../../Menu";
import { Navigation } from "../../Navigation";

// utilities
import { getClassNameFactory } from "../../../../../utilities";
import { isEnterprisePage } from "../../../AppContainerUtilities";

// constants
import { ENTERPRISE_DISPLAY_NAME } from "../HeaderConstants";

const getClassName = getClassNameFactory(ENTERPRISE_DISPLAY_NAME);

export const MobileHeader: React.FC = () => {
  const translate = useTranslate();
  const { isAuthenticated } = useAuth0();

  const isMenuVisible = isAuthenticated && isEnterprisePage();

  return (
    <div className={getClassName({ modifiers: "mobile" })} id="mobileHeader">
      {isMenuVisible && <Menu />}
      <div className={getClassName("productName")}>
        {translate("Header.ProductName")}
      </div>
      <Navigation />
    </div>
  );
};

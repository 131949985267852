// libraries
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// components
import {
  ChevronDown_1_Icon as ChevronDownIcon,
  ChevronUpIcon,
  FooterLogoIcon,
} from "../../../../common/icons";
import { Section } from "../../..";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import {
  AccessibilityClassnames,
  AriaGlobalTypes,
  RoleTypes,
} from "../../../../common/constants";
import { DISPLAY_NAME, footerLinks } from "./FooterConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Footer: React.FC = () => {
  const translate = useTranslate();

  const [isExpanded, setExpanded] = useState(false);

  const onClick = () => setExpanded(!isExpanded);

  return (
    <footer className={getClassName()} role={RoleTypes.CONTENT_INFO}>
      <Section className={getClassName("section")}>
        <div className={getClassName("top")}>
          <a
            className={getClassName("logo")}
            href={translate("Link.HomePage")}
            rel="noreferrer"
            target="_blank"
          >
            <FooterLogoIcon />
            <span className={AccessibilityClassnames.VISUALLY_HIDDEN}>
              {translate("AccessibilityHint.CompanyName")}
            </span>
          </a>
          <button
            aria-expanded={isExpanded}
            aria-label={
              isExpanded
                ? translate("AccessibilityHint.CollapseMenu")
                : translate("AccessibilityHint.ExpandMenu")
            }
            className={getClassName("button")}
            onClick={onClick}
            title={
              isExpanded
                ? translate("AccessibilityHint.CollapseMenu")
                : translate("AccessibilityHint.ExpandMenu")
            }
            aria-haspopup
          >
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </button>
        </div>
        <nav
          aria-label={AriaGlobalTypes.GLOBAL}
          className={getClassName({
            descendantName: "navigation",
            modifiers: isExpanded && "expanded",
          })}
          role={RoleTypes.NAVIGATION}
        >
          <ul className={getClassName("list")}>
            {footerLinks.map(({ link, text }) => (
              <li className={getClassName("item")} key={link}>
                <a
                  className={getClassName("link")}
                  href={translate(link)}
                  rel="noreferrer"
                  target="_blank"
                >
                  {translate(text)}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </Section>
    </footer>
  );
};

Footer.displayName = DISPLAY_NAME;

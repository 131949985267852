// libraries
import { createAsyncThunk } from "@reduxjs/toolkit";

// utilities
import { enrollFactor } from "../../factors/enrollFactor";

// constants
import { ConfirmationErrors } from "../confirmEnrollment/confirmAuthGuardianEnrollmentConstants";
import { ErrorMessages } from "../../../../common/constants";

// types
import {
  RequestPayload,
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration,
} from "./getAuthGuardianQrCodeTypes";

export const getAuthGuardianQrCode = createAsyncThunk<
  ResponsePayload,
  ThunkArguments,
  ThunkConfiguration
>(
  "authGuardian/getQrCode",
  async ({ apiBasePath, mfaToken, requestPayload }, { rejectWithValue }) => {
    try {
      return await enrollFactor<RequestPayload, ResponsePayload>({
        apiBasePath,
        mfaToken,
        requestPayload,
      });
    } catch (err) {
      let rejectMessage: ErrorMessages | ConfirmationErrors =
        ErrorMessages.GET_QR_CODE;

      if (err instanceof Error) {
        const msg = err.message;

        if (msg === ConfirmationErrors.INVALID_GRANT) {
          rejectMessage = ConfirmationErrors.INVALID_GRANT;
        } else if (msg === ConfirmationErrors.ACCESS_DENIED) {
          rejectMessage = ConfirmationErrors.ACCESS_DENIED;
        } else if (msg === ConfirmationErrors.INVALID_REQUEST) {
          rejectMessage = ConfirmationErrors.INVALID_REQUEST;
        }
      }

      return rejectWithValue({
        message: rejectMessage,
      });
    }
  }
);

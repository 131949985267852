export enum AccessibilityClassnames {
  VISUALLY_HIDDEN = "u-hiddenVisually",
}

export enum KeyboardKeys {
  BACKSPACE = "Backspace",
  DOWN_ARROW = "ArrowDown",
  END = "End",
  ENTER = "Enter",
  ESCAPE = "Escape",
  HOME = "Home",
  LEFT_ARROW = "ArrowLeft",
  RIGHT_ARROW = "ArrowRight",
  SPACE = " ",
  TAB = "Tab",
  UP_ARROW = "ArrowUp",
}

export enum DirectionTypes {
  DOWN = "down",
  UP = "up",
}

export enum EnvironmentTypes {
  LOCAL = "local",
  NONPROD = "nonprod",
  PPE = "PPE",
  PRODUCTION = "production",
  QA = "QA",
  SANDBOX = "sandbox",
  UAT = "UAT",
}

export const QR_CODE_SIZE = 185;

// libraries
import { useTranslate } from "@dcl/tools";

// components
import { ClosedEyeIcon, OpenedEyeIcon } from "../../../../common/icons";

// constants
import { InputTypes } from "../../../../common/constants";

export const useDefaultPasswordInputConfiguration = (
  isPasswordVisible: boolean
) => {
  const translate = useTranslate();

  const hiddenPasswordConfiguration = {
    icon: <ClosedEyeIcon />,
    inputType: InputTypes.PASSWORD,
    title: translate("AccessibilityHint.ShowPassword"),
  };

  const visiblePasswordConfiguration = {
    icon: <OpenedEyeIcon />,
    inputType: InputTypes.TEXT,
    title: translate("AccessibilityHint.HidePassword"),
  };

  return isPasswordVisible
    ? visiblePasswordConfiguration
    : hiddenPasswordConfiguration;
};

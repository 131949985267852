// utilities
import { useGetMfaFactors, useGetMfaStatus } from "..";

/**
 * @description Updates MFA data after modifying any MFA data.
 * @returns An async function that updates the MFA status and gets the MFA factors.
 */
export const useUpdateMfaData = () => {
  const getMfaStatus = useGetMfaStatus();
  const getMfaFactors = useGetMfaFactors();

  return async () => {
    await getMfaStatus();
    await getMfaFactors();
  };
};

// libraries
import { combineReducers } from "redux";

// utilities
import {
  completeFactorEnrollmentSlice,
  confirmAuthGuardianEnrollmentSlice,
  deleteFactorSlice,
  getAuthGuardianQrCodeSlice,
  getFactorsSlice,
  getOtpAppQrCodeSlice,
  getStatusSlice,
  regenerateRecoveryCodeSlice,
  sendCodeSlice,
} from "./mfa";
import { getProductsSlice } from "./products";
import { securityQuestionsListSlice } from "./securityQuestions";
import { userSlice } from "./user";

export const reducers = {
  completeFactorEnrollment: completeFactorEnrollmentSlice.reducer,
  confirmAuthGuardianEnrollment: confirmAuthGuardianEnrollmentSlice.reducer,
  deleteFactor: deleteFactorSlice.reducer,
  getAuthGuardianQrCode: getAuthGuardianQrCodeSlice.reducer,
  getFactors: getFactorsSlice.reducer,
  getOtpAppQrCode: getOtpAppQrCodeSlice.reducer,
  getProducts: getProductsSlice.reducer,
  getStatus: getStatusSlice.reducer,
  getUser: userSlice.reducer,
  regenerateRecoveryCode: regenerateRecoveryCodeSlice.reducer,
  securityQuestions: securityQuestionsListSlice.reducer,
  sendCode: sendCodeSlice.reducer,
};

export const rootReducer = combineReducers(reducers);

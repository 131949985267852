export enum Auth0UserParameters {
  AVATAR = "picture",
  EMAIL = "email",
  EU_ID = "https://tr.com/euid",
  FEDERATED_USER_ID = "https://tr.com/federated_user_id",
  FIRST_NAME = "given_name",
  LAST_NAME = "family_name",
  LOCALE = "locale",
  SUB = "sub",
}

export enum AuthenticationBypasses {
  EMAIL_VERIFICATION = "email-verification-bypass",
  PASSWORD_EXPIRED = "password-reset",
}

export enum Prompts {
  LOGIN = "login",
}

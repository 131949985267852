// libraries
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// constants
import { ErrorMessage } from ".";

/**
 * @description Provides validation for checkbox.
 * @param rawErrorMessage - provides an object with the dictionary key of the error message.
 * @returns An object containing the following properties:
 * - `change`: A function to toggle the checkbox value.
 * - `errorMessage`: Translated error message.
 * - `reset`: A function to reset the checkbox value and remove error.
 * - `validate`: A function to validate the checkbox value.
 * - `value`: The current boolean value of the checkbox.
 */
export const useCheckboxValidation = (rawErrorMessage: ErrorMessage) => {
  const translate = useTranslate();
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [isChecked, setIsChecked] = useState(false);

  const change = () => {
    setIsChecked((isCheckboxChecked) => !isCheckboxChecked);
    setErrorMessage(null);
  };

  const reset = () => {
    setIsChecked(false);
    setErrorMessage(null);
  };

  const validate = () => {
    if (!isChecked) {
      const translatedErrorMessage = translate(rawErrorMessage.key);

      setErrorMessage(translatedErrorMessage);

      return rawErrorMessage;
    }

    return null;
  };

  return {
    change,
    errorMessage,
    reset,
    validate,
    value: isChecked,
  };
};

// components
import { DefaultHeadingSection, EnterpriseHeadingSection } from "./components";

// constants
import { DISPLAY_NAME } from "./HeadingSectionConstants";

// types
import { HeadingSectionWrapperProps } from "./HeadingSectionTypes";

export const HeadingSection: React.FC<HeadingSectionWrapperProps> = ({
  isEnterprise,
  ...props
}) => {
  return isEnterprise ? (
    <EnterpriseHeadingSection {...props} />
  ) : (
    <DefaultHeadingSection {...props} />
  );
};

HeadingSection.displayName = DISPLAY_NAME;

// libraries
import { Fragment, useState } from "react";
import { useTranslate } from "@dcl/tools";

// utilities
import { getClassNameFactory } from "../../../../utilities";
import { useComponentWithHashLink } from "./SkipToMainContentUtilities";

// constants
import { DISPLAY_NAME } from "./SkipToMainContentConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const SkipToMainContent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const translate = useTranslate();
  const component = useComponentWithHashLink(
    translate("AccessibilityHint.SkipToMainContent"),
    isVisible
  );

  const handleVisibility = () => {
    setIsVisible((state) => !state);
  };

  return (
    <Fragment>
      {component && (
        <div
          id="skiptocontent"
          onBlur={handleVisibility}
          onFocus={handleVisibility}
        >
          <p className={getClassName()}>{component}</p>
        </div>
      )}
    </Fragment>
  );
};

SkipToMainContent.displayName = DISPLAY_NAME;

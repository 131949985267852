// libraries
import { useAuth0 } from "@auth0/auth0-react";

// constants
import { Auth0UserParameters, Locales } from "../common/constants";

// types
import { Auth0User, RawAuth0User } from "../common/types";

/**
 * @description Provides with all the user information and utilizes the Auth0 hook to get the user's data
 * @returns An object containing user's `avatar`, `email`, `euId`, `federatedUserId`, `firstName`, `lastName`, `locale`, and `sub`.
 */
export const useAuth0User = (): Auth0User => {
  const {
    user: {
      [Auth0UserParameters.AVATAR]: avatar = "",
      [Auth0UserParameters.EMAIL]: email = "",
      [Auth0UserParameters.EU_ID]: euId = "",
      [Auth0UserParameters.FEDERATED_USER_ID]: federatedUserId,
      [Auth0UserParameters.FIRST_NAME]: firstName = "",
      [Auth0UserParameters.LAST_NAME]: lastName = "",
      [Auth0UserParameters.LOCALE]: locale = Locales.EN_US,
      [Auth0UserParameters.SUB]: sub = "",
    } = {},
  }: { user?: RawAuth0User } = useAuth0();

  return {
    avatar,
    email,
    euId,
    federatedUserId,
    firstName,
    lastName,
    locale,
    sub,
  };
};

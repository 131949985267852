export const REGISTRATION_DATA_STORAGE_KEY = "registrationData";
export const UPDATED_EMAIL_STORAGE_KEY = "updatedEmail";

export enum EmailVerificationStorageKeys {
  CLIENT_ID = "emailVerificationClientId",
  STATE = "emailVerificationState",
}

export enum MfaSessionStorageKeys {
  AUTH_GUARDIAN_OOB_CODE = "authGuardianFactor/oobCode",
  EMAIL = "emailFactor/email",
  EMAIL_OOB_CODE = "emailFactor/oobCode",
  PHONE_CALL_OOB_CODE = "phoneCallFactor/oobCode",
  PHONE_CALL_PHONE_NUMBER = "phoneCallFactor/phoneNumber",
  PHONE_DATA = "phoneData",
  TEXT_MESSAGE_OOB_CODE = "textMessageFactor/oobCode",
  TEXT_MESSAGE_PHONE_NUMBER = "textMessageFactor/phoneNumber",
}

export enum PasswordExpiredStorageKeys {
  REDIRECT_URL = "passwordExpiredRedirectUrl",
}

export enum PersonaSelectionStorageKeys {
  SESSION_TOKEN = "personaSelectionSessionToken",
  STATE = "personaSelectionState",
}

export enum ProductStorageKeys {
  PRODUCT_URL = "productUrl",
  REG_KEY_TO_EDIT = "regKeyToEdit",
  REGISTER_PRODUCT_PARAMS = "registerProductParams",
  TOAST_TEXT = "productsToastText",
}

export enum StorageTypes {
  LOCAL = "localStorage",
  SESSION = "sessionStorage",
}

export type UrlParametersStorageKeys =
  | EmailVerificationStorageKeys
  | PasswordExpiredStorageKeys
  | PersonaSelectionStorageKeys;

// libraries
import { useState } from "react";

// components
import { InlineErrorMessage } from "..";
import { Input } from "./components/Input";

// utilities
import { getClassNameFactory, getErrorId, trim } from "../../utilities";
import { shouldErrorBeShown } from "./FormInputUtils";

// constants
import { DEFAULT_ERROR_MESSAGE, DISPLAY_NAME } from "./FormInputConstants";
import { InputTypes } from "../../common/constants";

// types
import { FormInputProps } from "./FormInputTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const FormInput: React.FC<FormInputProps> = ({
  defaultInputValue = "",
  description,
  errorMessage = DEFAULT_ERROR_MESSAGE,
  id,
  inputModifier = "",
  isCustomValidationError = false,
  label,
  labelModifier = "",
  onBlur,
  onChange,
  type = InputTypes.TEXT,
  ...inputProps
}) => {
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [value, setValue] = useState(defaultInputValue);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const isValid = event.target.validity.valid;
    const isRequired = event.target.required;

    if (shouldErrorBeShown(isValid, isRequired, value)) {
      setIsValidationError(true);
    }

    if (onBlur) {
      onBlur(event);
      setValue(trim(value));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue(inputValue);

    // To hide error when user start changing input
    if (isValidationError) {
      setIsValidationError(!isValidationError);
    }

    if (onChange) {
      onChange(event);
    }
  };

  const isError = isCustomValidationError || isValidationError;

  return (
    <div className={getClassName()}>
      <label
        className={getClassName({
          className: labelModifier,
          descendantName: "label",
        })}
        htmlFor={id}
      >
        {label}
      </label>
      {description && (
        <span className={getClassName({ descendantName: "description" })}>
          {description}
        </span>
      )}
      <div className={getClassName({ descendantName: "inputWrapper" })}>
        <Input
          id={id}
          inputModifier={inputModifier}
          isErrorVisible={isError}
          onBlur={handleBlur}
          onChange={handleChange}
          type={type}
          value={value}
          {...inputProps}
        />
        {isError && (
          <InlineErrorMessage
            className={getClassName({ descendantName: "error" })}
            errorMessage={errorMessage}
            id={getErrorId(id)}
          />
        )}
      </div>
    </div>
  );
};

FormInput.displayName = DISPLAY_NAME;

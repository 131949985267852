// components
import { AlertCircle_2_Icon as AlertCircleIcon } from "../../../common/icons";
import { HeadingSection, Section } from "../../";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import { DISPLAY_NAME } from "./FallbackUIConstants";
import { HEADING_TEXT, SUBHEADING_TEXT } from "../constants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const FallbackUI: React.FC = () => {
  return (
    <Section>
      <HeadingSection
        className={getClassName("heading")}
        heading={HEADING_TEXT}
        icon={<AlertCircleIcon />}
        subheading={SUBHEADING_TEXT}
      />
    </Section>
  );
};

FallbackUI.displayName = DISPLAY_NAME;

export const AlertCircle_2_Icon: React.FC = () => (
  <svg
    focusable="false"
    height="65"
    viewBox="0 0 65 65"
    width="65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" strokeLinecap="round" strokeWidth="2">
      <path d="M32 2a30 30 0 1 0 0 60 30 30 0 1 0 0-60z" stroke="#404040" />
      <path d="M32 45a3 3 0 1 0 0 6 3 3 0 1 0 0-6zM32 12v24" stroke="#FF6400" />
    </g>
  </svg>
);

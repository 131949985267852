export const CheckCircle_2_Icon: React.FC = () => (
  <svg
    fill="none"
    focusable="false"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      d="M8 .973c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm-1 11.4-3.4-3.4 1.4-1.4 2 2 4-4 1.4 1.4-5.4 5.4Z"
      fill="#387C2B"
    />
  </svg>
);
